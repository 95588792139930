<p-sidebar [(visible)]="isSideBarVisible" [attr.data-testid]="'dclist' + dataTestIdComponentName" position="right" appendTo="body">
	<ng-template pTemplate="header">
		<div class="custom-col title mt-4 pt-1 mb-5 pl-1">Customise Columns</div>
	</ng-template>
	<div class="flex align-items-center gap-2 custom-col-sidebar">
		<div class="button-with-tooltip-holder flex-center custom-col-btn" pTooltip="Reset to defaults" tooltipPosition="bottom">
			<p-button class="full-btn" data-testid="dclistReset" (onClick)="onResetClick()" label="Reset to Defaults" icon="pi pi-undo"></p-button>
		</div>
	</div>
	<hr />
	<div class="mt-5 pt-2 mb-3 ml-2 custom-col description">Add/Remove Columns</div>
	<div class="pb-2 m-1">
		<p-listbox
			#ms
			inputId="float-label"
			[attr.data-testid]="'dclist' + dataTestIdComponentName"
			[options]="getColumnsSortedByHeader()"
			[metaKeySelection]="false"
			[checkbox]="true"
			[filter]="true"
			[multiple]="true"
			styleClass="custom-sidebar-multiselect"
			[(ngModel)]="selectedColumns"
			[disabled]="false"
			[filter]="true"
			optionLabel="header"
			(onChange)="onSelectionChange($event)">
		</p-listbox>
	</div>
</p-sidebar>

<div class="button-with-tooltip-holder" pTooltip="Select List Columns" tooltipPosition="bottom">
	<p-button [attr.data-testid]="'dclisttoggle' + dataTestIdComponentName" (onClick)="isSideBarVisible = true" label="" icon="pi pi-table"></p-button>
</div>
