<p-toolbar *ngIf="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)">
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div
			class="button-with-tooltip-holder"
			*ngIf="showDelete() && [formModes.View].includes(formMode)"
			[pTooltip]="getDeleteTooltip()"
			tooltipPosition="bottom">
			<p-button
				icon="pi pi-trash"
				[disabled]="!allowDelete()"
				(onClick)="deleteAttachment(attachment)"
				label="Delete"
				data-testid="attachmentDetailsDeleteButton"></p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Edit, formModes.Create].includes(formMode)" pTooltip="Save Attachment" tooltipPosition="bottom">
			<p-button label="Save" (onClick)="saveAttachment()" icon="pi pi-save" data-testid="attachmentDetailsSaveButton"></p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="showEdit() && [formModes.View].includes(formMode)" [pTooltip]="getEditTooltip()" tooltipPosition="bottom">
			<p-button label="Edit" [disabled]="!allowEdit()" (onClick)="editAttachment()" icon="pi pi-pencil" data-testid="attachmentDetailsEditButton"></p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Create, formModes.Edit].includes(formMode)" pTooltip="Cancel Edit" tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="cancelAttachmentEdit()" icon="pi pi-ban"></p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.View].includes(formMode)" pTooltip="Back to Attachment list" tooltipPosition="bottom">
			<p-button label="Back" (onClick)="showList()" icon="pi pi-arrow-left" data-testid="attachmentDetailsBackToListButton"></p-button>
		</div>
	</ng-template>
</p-toolbar>
<form [formGroup]="attachmentForm">
	<div class="p-grid p-formgrid p-m-0 p-p-0 attachment-holder">
		<!-- row -->

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[control]="attachmentForm.get('attachmentType')"
				[lookupValues]="attachmentTypes"
				label="Type">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="attachmentForm.get('attachmentTitle')"
				label="Title"
				data-testid="attachmentDetailsTitleField">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<!-- row -->

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="attachmentForm.get('referenceName')"
				label="Reference">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="attachmentForm.get('externalReferenceNumber')"
				label="External Ref #">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<!-- row -->

		<div class="p-col-12 p-md-0 form-element" *ngIf="[formModes.Edit, formModes.Create].includes(formMode)">
			<p-fieldset legend="Upload Files" *ngIf="[formModes.Edit, formModes.Create].includes(formMode)">
				<p-fileUpload
					#fileUpload
					name="fileUpload"
					customUpload="true"
					multiple="multiple"
					fileLimit="10"
					fileLimitMessage="Only 10 files are allowed to upload"
					invalidFileSizeMessageDetail="Maximum upload size is 29 MB"
					(uploadHandler)="onUpload($event)"
					(onClear)="onClear()"
					(onProgress)="progressReport($event)"
					[disabled]="isUploadInProgress"
					[maxFileSize]="1048575.25 * 29.0">
					<ng-template pTemplate="file"></ng-template>
					<ng-template pTemplate="content" let-files>
						<div class="p-fileupload-files ng-star-inserted" *ngIf="files.length">
							<div class="p-fileupload-row" *ngFor="let file of files; index as i">
								<div class="p-fileupload-filename">{{ file.name }}</div>
								<div>{{ (file.size / 1048575.25).toFixed(2) }} MB</div>
								<div>
									<button
										[disabled]="isUploadInProgress"
										pButton
										type="button"
										icon="pi pi-times"
										class="p-button"
										(click)="removeSelectedFile(i, $event)"></button>
								</div>
							</div>
						</div>
					</ng-template>
				</p-fileUpload>
			</p-fieldset>
		</div>
		<div class="p-col-12 p-md-0 form-element" *ngIf="attachment.Files && attachment.Files.length > 0">
			<p-fieldset legend="" *ngIf="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)">
				<p-table
					#attachmentFilesUI
					name="attachmentFilesUI"
					dataKey="AttachmentFileId"
					[rowHover]="true"
					responsiveLayout="scroll"
					[value]="attachment.Files"
					[rows]="50"
					[lazy]="true"
					editMode="row"
					[totalRecords]="attachment.Files.length">
					<ng-template pTemplate="header">
						<th pSortableColumn="attachmentFile">
							<div class="p-d-flex p-jc-start p-ai-center"></div>
						</th>
						<th pSortableColumn="attachmentFile.AttachmentFileName">
							<div class="table-header-label">File Name</div>
						</th>
						<th pSortableColumn="attachmentFile.DateAdded">
							<div class="table-header-label">Created</div>
						</th>
						<th pSortableColumn="attachmentFile.AddedBy">
							<div class="table-header-label">Created by</div>
						</th>
						<th>
							<div class="p-d-flex p-jc-start p-ai-center">
								<div (click)="downloadAllFiles(attachment)" pTooltip="Download All" tooltipPosition="bottom" style="cursor: pointer">
									<i class="pi pi-download" style="font-size: 1.4rem"></i>
								</div>
							</div>
						</th>
						<th></th>
					</ng-template>
					<ng-template pTemplate="body" let-attachmentFile let-ri="rowIndex">
						<tr>
							<td style="text-align: center">
								<img
									*ngIf="attachmentFile.ImagePreview && isValidImage(attachmentFile)"
									src="{{ attachmentFile.ImagePreview }}"
									style="cursor: pointer"
									width="80"
									(click)="thumbnailClick(attachmentFile)" />

								<img
									*ngIf="!attachmentFile.ImagePreview && attachmentFile.FileData && isValidImage(attachmentFile)"
									src="{{ 'data:image/png;base64,' + attachmentFile.FileData }}"
									style="cursor: pointer"
									(click)="thumbnailClick(attachmentFile)" />

								<i
									*ngIf="!isValidImage(attachmentFile) && isExistingItem(attachmentFile)"
									class="{{ util.getFileIcon(attachmentFile.AttachmentFileName) }}"
									style="font-size: 3rem; cursor: pointer"
									(click)="clickEvent(attachmentFile)"></i>

								<i
									*ngIf="!isValidImage(attachmentFile) && !isExistingItem(attachmentFile)"
									class="{{ util.getFileIcon(attachmentFile.AttachmentFileName) }}"
									style="font-size: 3rem"></i>
							</td>
							<td>
								{{ attachmentFile.AttachmentFileName }}
							</td>
							<td>
								<span [innerHTML]="attachmentFile.DateAdded | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
							</td>
							<td>
								{{ attachmentFile.AddedBy }}
							</td>
							<td>
								<button
									*ngIf="attachmentFile.FileLength > 0"
									pButton
									type="button"
									pTooltip="Download"
									tooltipPosition="bottom"
									icon="pi pi-download"
									class="p-button"
									(click)="downloadFile(attachmentFile)"></button>
							</td>
							<td *ngIf="showDeleteItem(attachmentFile)">
								<button
									*ngIf="allowDeleteItem(attachmentFile)"
									pButton
									type="button"
									icon="pi pi-trash"
									class="p-button"
									(click)="deleteAttachmentFile(attachmentFile)"></button>
							</td>
							<td *ngIf="!showDeleteItem(attachmentFile)"></td>
						</tr>
					</ng-template>
				</p-table>

				<p-galleria
					[(value)]="images"
					[(visible)]="displayPreview"
					[(activeIndex)]="activeIndex"
					[containerStyle]="{ 'max-width': 'auto', 'max-height': '900px' }"
					[numVisible]="7"
					[circular]="true"
					[fullScreen]="true"
					[showItemNavigators]="true"
					[showThumbnails]="false"
					thumbnailsPosition="left"
					[baseZIndex]="100000">
					<ng-template pTemplate="item" let-item>
						<img [src]="item.PreviewImage" style="max-width: auto; max-height: 900px; display: block" />
					</ng-template>
				</p-galleria>
			</p-fieldset>
		</div>

		<!-- row -->

		<div class="p-col-12 p-md-0 form-element"></div>

		<!-- row -->

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="attachmentForm.get('addedBy')"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(attachment?.Details?.AddedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(attachment?.Details?.AddedByExternalUserRelationTypeCode)"
				label="Created by">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="attachmentForm.get('dateAdded')"
				label="Created">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="attachmentForm.get('updatedBy')"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(attachment?.Details?.UpdatedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(attachment?.Details?.UpdatedByExternalUserRelationTypeCode)"
				label="Last Updated by">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="attachmentForm.get('dateUpdated')"
				label="Last Updated">
			</app-form-item>
		</div>

		<!-- row -->

		<div class="p-col-3 p-md-0 form-element" *ngIf="attachmentForm.get('dateRemoved').value"></div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="attachmentForm.get('dateRemoved').value"></div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="attachmentForm.get('dateRemoved').value"></div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="attachmentForm.get('dateRemoved').value">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="attachmentForm.get('dateRemoved')"
				label="Deleted">
			</app-form-item>
		</div>
	</div>
</form>
