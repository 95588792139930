<p-table
	#invoiceItemListTable
	[class]="'app-' + invoice.InvoiceTypeCode.toLowerCase() + '-item-list'"
	dataKey="invoiceItemId"
	[rowHover]="true"
	responsiveLayout="scroll"
	[value]="items"
	[rows]="50"
	[totalRecords]="items.length">
	<ng-template pTemplate="header">
		<tr>
			<th *ngIf="isInvoiceSubTypeItems()" pSortableColumn="SorCode">
				<div class="table-header-label">
					Item
					<p-sortIcon field="SorCode"></p-sortIcon>
					<p-columnFilter type="text" field="SorCode" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="isInvoiceSubTypeItems()" pSortableColumn="Keywords">
				<div class="table-header-label">
					Keywords
					<p-sortIcon field="Keywords"></p-sortIcon>
					<p-columnFilter type="text" field="Keywords" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="isInvoiceSubTypeItems()" pSortableColumn="LocationName">
				<div class="table-header-label">
					Location

					<p-sortIcon field="LocationName"></p-sortIcon>
					<p-columnFilter type="text" field="LocationName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="isInvoiceSubTypeItems()" pSortableColumn="Quantity">
				<div class="table-header-label">
					Qty

					<p-sortIcon field="Quantity"></p-sortIcon>
					<p-columnFilter type="numeric" field="Quantity" display="menu">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								(onBlur)="util.pTableNumericFilter(number.value, 'Quantity', invoiceItemListTable)"
								[maxFractionDigits]="2"
								autocomplete="off"></p-inputNumber>
						</ng-template>
					</p-columnFilter>
				</div>
			</th>
			<th *ngIf="isInvoiceSubTypeItems()" pSortableColumn="UnitOfMeasureName">
				<div class="table-header-label">
					UOM

					<p-sortIcon field="UnitOfMeasureName"></p-sortIcon>
					<p-columnFilter type="text" field="UnitOfMeasureName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Comments">
				<div class="table-header-label">
					Comments

					<p-sortIcon field="Comments"></p-sortIcon>
					<p-columnFilter type="text" field="Comments" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="isStandardInvoice()" pSortableColumn="InvoiceAmountInclTax" [style]="isInvoiceSubTypeItems() ? '' : 'width:10%'">
				<div class="table-header-label">
					{{ getInvoiceAmountColHeaderText() }} {{ region.CurrencySymbol }}

					<p-sortIcon field="InvoiceAmountInclTax"></p-sortIcon>
					<p-columnFilter type="numeric" field="InvoiceAmountInclTax" display="menu">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								(onBlur)="util.pTableCurrencyFilter(number.value, 'InvoiceAmountInclTax', invoiceItemListTable)"
								[locale]="region.RegionCode"
								[minFractionDigits]="region.CurrencyPrecision"
								[maxFractionDigits]="region.CurrencyPrecision"
								autocomplete="off"></p-inputNumber>
						</ng-template>
					</p-columnFilter>
				</div>
				<div class="title-label-small">(incl. tax)</div>
			</th>
			<th *ngIf="isCreditNote()" pSortableColumn="CreditAmountInclTax" [style]="isInvoiceSubTypeItems() ? '' : 'width:10%'">
				<div class="table-header-label">
					Credit {{ region.CurrencySymbol }}

					<p-sortIcon field="CreditAmountInclTax"></p-sortIcon>
					<p-columnFilter type="numeric" field="CreditAmountInclTax" display="menu">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								(onBlur)="util.pTableCurrencyFilter(number.value, 'CreditAmountInclTax', invoiceItemListTable)"
								[locale]="region.RegionCode"
								[minFractionDigits]="region.CurrencyPrecision"
								[maxFractionDigits]="region.CurrencyPrecision"
								autocomplete="off"></p-inputNumber>
						</ng-template>
					</p-columnFilter>
				</div>
				<div class="title-label-small">(incl. tax)</div>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-invoiceItem let-ri="rowIndex">
		<tr class="p-selectable-row">
			<td *ngIf="isInvoiceSubTypeItems()">
				{{ invoiceItem.SorCode }}
			</td>
			<td *ngIf="isInvoiceSubTypeItems()">
				{{ invoiceItem.Keywords }}
			</td>
			<td *ngIf="isInvoiceSubTypeItems()">
				{{ invoiceItem.LocationName }}
			</td>
			<td *ngIf="isInvoiceSubTypeItems()">
				{{ invoiceItem.Quantity | formatNumber : '' : region.RegionCode }}
			</td>
			<td *ngIf="isInvoiceSubTypeItems()">
				{{ invoiceItem.UnitOfMeasureName }}
			</td>
			<td>
				<div pTooltip="{{ invoiceItem.Comments }}" tooltipPosition="bottom">
					{{ invoiceItem.Comments | truncate }}
				</div>
			</td>
			<td *ngIf="isStandardInvoice()" style="text-align: right">
				{{
					invoiceItem.InvoiceAmountInclTax != null
						? (invoiceItem.InvoiceAmountInclTax | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode)
						: '-'
				}}
			</td>
			<td *ngIf="isCreditNote()" style="text-align: right">
				{{
					invoiceItem.CreditAmountInclTax != null
						? (invoiceItem.CreditAmountInclTax | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode)
						: '-'
				}}
			</td>
		</tr>
	</ng-template>
</p-table>

<div class="custom-pagination" data-testid="invoiceItemsCustomPagination">
	<span *ngIf="items && items.length; then resultFoundPaginationText; else noResultFoundPaginationText"> </span>
	<ng-template #resultFoundPaginationText>Showing {{ items.length }} {{ items.length > 1 ? 'entries' : 'entry' }}</ng-template>
	<ng-template #noResultFoundPaginationText>No entries found</ng-template>
</div>
