import { AfterViewInit, Component } from '@angular/core';
import * as _ from 'lodash';
import { FilterMatchMode, FilterMetadata, FilterOperator, LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { LazyLoadArgs, PagedResponse, PropertyFilter } from 'src/app/shared/models/api';
import { SearchFilter } from 'src/app/shared/models/search-filters';
import { Globals } from '../../globals';
import { Guid } from '../models';
import { AppContextService } from '../services';
import { BaseComponent } from './base.component';

@Component({
	template: '',
})
export abstract class DataTableBaseComponent<T> extends BaseComponent implements AfterViewInit {
	componentGuid: string = Guid.newGuid();
	data: T[] = [];
	totalRecords = 0;
	lastLazyLoadEvent: any;
	extraFilters: PropertyFilter[] = [];
	loading = false;
	loadCompleted = new BehaviorSubject<boolean>(false);
	dataTable: any = null;
	lastLazyLoadArgs: LazyLoadArgs;
	searchFilter: SearchFilter;
	stateKey: string;

	private pauseLoadData = 1; // pause by default
	pendingLoadDataRequest: LazyLoadEvent = null;
	first = 0;

	constructor(public globals: Globals, public appContext: AppContextService, public config: PrimeNGConfig) {
		super(appContext);
		this.lastLazyLoadArgs = this.globals.getListViewFilter(this.componentGuid);
		this.stateKey = this.getListStorageName ? this.appContext.getStorageKey(this.getListStorageName()) : null;
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.loadFiltersAndSort();

			if (this.getInitialiseDataOnInit()) {
				this.resumeDataLoading();
			} else {
				this.initialiseWithoutDataLoad();
			}
		}, 0);
	}

	//#region remembering search list details

	getTable(): Table {
		return null;
	}

	getListStorageName(): string {
		return null;
	}

	getTableGlobalFilterField(): HTMLInputElement {
		return null;
	}

	getInitialiseDataOnInit(): boolean {
		return true;
	}

	private getGlobalFilter(): string {
		const name = this.getListStorageName();
		if (name == null) return null;

		return this.appContext.getSavedValue(`${name}-Global`);
	}

	private saveGlobalFilter(globalFilter: string) {
		const name = this.getListStorageName();
		if (name != null) {
			this.appContext.setSavedValue(`${name}-Global`, globalFilter);
		}
	}

	getAdditionalFilterFields(): string[] {
		return null;
	}

	private createAdditionalFilter(fieldName: string): PropertyFilter {
		const type = fieldName.replace('Id', '');
		const selIds = this.util.getViewByFilterList(type.toUpperCase());
		if (selIds && selIds.length > 0) {
			const filter = new PropertyFilter();
			(filter.MatchMode = FilterMatchMode.IN), (filter.Operator = FilterOperator.AND), (filter.FieldName = fieldName), (filter.Values = selIds);
			return filter;
		}
		return null;
	}

	private loadFiltersAndSort() {
		const table = this.getTable();
		if (table == null) return;

		const gs = this.getTableGlobalFilterField();
		if (gs == null) return;

		const gf = this.getGlobalFilter();
		if (gf == null || gf == '') return;

		gs.value = gf;
		table.filterGlobal(gf, 'contains');
	}

	saveAndApplyGlobalFilter(ev) {
		const table = this.getTable();
		if (table != null) {
			table.filterGlobal(ev.target.value, 'contains');
			this.saveGlobalFilter(ev.target.value);
		}
	}

	// needed to handle issues with changing the dropdown and around pages
	// scenario1 - set page to max, apply filter, change page and change back
	// scenario2 - set page to max, change page, apply filter, change back
	resetPagination(alwaysReset: boolean) {
		const storageName = this.getListStorageName();
		if (storageName == null) return;

		if (alwaysReset == false && storageName) {
			const name = storageName + '_DropdownFilter';

			let previousSelection = this.appContext.previousClientCompanySelection;
			if (previousSelection == 'undefined') previousSelection = null;

			let previousFilter = this.appContext.getSavedValue(name);
			if (previousFilter == 'undefined') previousFilter = null;

			if (previousSelection == previousFilter) return;
		}

		if (this.getTable()) {
			const storage = this.appContext.getSavedValueFromJson(this.getTable().stateKey);
			if (storage) {
				storage.first = 0;
				this.appContext.setSavedValueAsJson(this.getTable().stateKey, storage);
			} else {
				this.getTable().saveState();
			}
		}

		if (this.lastLazyLoadEvent != null) this.lastLazyLoadEvent.first = 0;

		this.first = 0;

		if (storageName) {
			const name = storageName + '_DropdownFilter';
			this.appContext.setSavedValue(name, this.appContext.previousClientCompanySelection);
		}
	}

	//#endregion

	abstract lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<T>>;

	getDateFilterFields(): string[] {
		return [];
	}

	getDateFilterTimezoneCode(): string {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	pauseDataLoading() {
		this.pauseLoadData++;
	}

	initialiseWithoutDataLoad() {
		this.pauseLoadData = 0;
	}

	resumeDataLoading() {
		this.pauseLoadData--;
		if (this.pauseLoadData < 0) this.pauseLoadData = 0;

		if (this.pauseLoadData == 0 && this.pendingLoadDataRequest != null) this.loadData(this.pendingLoadDataRequest);
	}

	eventMatchesLastLoadEvent(event?: LazyLoadEvent): boolean {
		// do comparison without the forceUpdate method being checked
		const source = this.lastLazyLoadEvent ? this.util.deepClone(this.lastLazyLoadEvent) : null;
		if (source != null) source.forceUpdate = null;

		const dest = event ? this.util.deepClone(event) : null;
		if (dest != null) dest.forceUpdate = null;

		return _.isEqual(source, dest);
	}

	//this method will conver the primeng lazyload event to the spydertech LazyLoadArgs so that data can be load from the backend service
	protected loadData(event?: any) {
		if (this.pauseLoadData) {
			// if the request is the same as currently running, then don't re-queue it
			if (this.eventMatchesLastLoadEvent(event) == false) this.pendingLoadDataRequest = event;

			return;
		}

		this.pauseDataLoading();

		this.pendingLoadDataRequest = null;

		if (event) {
			this.lastLazyLoadEvent = event;
		} else {
			event = this.lastLazyLoadEvent;
		}

		this.resetPagination(false);

		const lazyLoadArgs = this.getLazyLoadArgs(event);
		this.lastLazyLoadArgs = lazyLoadArgs;

		try {
			this.globals.saveListViewFilter(this.componentGuid, this.lastLazyLoadArgs);
			this.loading = true;
			this.lazyLoad(lazyLoadArgs).subscribe(
				resp => {
					this.loading = false;

					// only update the value if different - avoids collection changed error
					if (resp.TotalRecords != 0 || this.totalRecords != 0) {
						this.data = resp.Data;
						this.totalRecords = resp.TotalRecords;
					}

					this.loadCompleted.next(true);
					this.resumeDataLoading();
				},
				error => {
					console.error(error);
					this.appContext.util.showError('Load data failed.');
				}
			);
		} catch (e) {
			this.util.hideWait();
			console.log(e);
		}
	}

	getCustomFilters(primeNgFilters?: FilterMetadata[]): PropertyFilter[] {
		let filters: PropertyFilter[] = [];
		if (primeNgFilters) {
			const keys = Object.keys(primeNgFilters);
			keys.forEach(element => {
				const filterObj = primeNgFilters[element];
				if (Array.isArray(filterObj)) {
					filterObj.forEach(filter => {
						const colFilter = this.createFilter(filter, element);
						if (colFilter) filters.push(colFilter);
					});
				} else {
					const colFilter = this.createFilter(filterObj, element);
					if (colFilter) filters.push(colFilter);
				}
			});
		}

		filters = filters.concat(this.extraFilters);
		return filters;
	}

	private createFilter(filter: any, name: string) {
		let colFilter;
		if (filter?.value != null) {
			colFilter = new PropertyFilter();
			colFilter.FieldName = name;
			colFilter.Values = null;
			colFilter.MatchMode = filter.matchMode;
			colFilter.Operator = filter.operator;

			if (filter.value === true) colFilter.Value = 'true';
			else if (filter.value === false) colFilter.Value = 'false';
			else if (this.getDateFilterFields().includes(name)) colFilter.Value = this.util.formatAsDate(filter.value) ?? filter.value;
			else colFilter.Value = filter.value;
		}
		return colFilter;
	}

	getLazyLoadArgs(event?: any): LazyLoadArgs {
		const result = new LazyLoadArgs();
		result.globalFilter = event?.globalFilter;
		result.timezoneCode = this.getDateFilterTimezoneCode();

		if (event?.first) {
			result.first = event.first;
		}
		if (event?.rows) {
			result.rows = event.rows;
		}

		result.filters = this.getCustomFilters(event?.filters);

		const fieldNames = this.getAdditionalFilterFields();
		if (fieldNames && fieldNames != null && fieldNames.length > 0) {
			fieldNames.forEach(x => {
				const filter = this.createAdditionalFilter(x);
				if (filter != null) {
					result.filters.push(filter);
				}
			});
		}

		if (event) {
			if (event.sortOrder === -1) {
				result.sortDesc = true;
			}
			if (event.sortOrder === 1) {
				result.sortDesc = false;
			}
			result.sortField = event.sortField;
		}

		result.searchFilter = this.searchFilter;

		return result;
	}
	clearAll() {
		//clear global filter
		const gf = this.getGlobalFilter();
		if (gf != null || gf != '') {
			const gfField = this.getTableGlobalFilterField();
			gfField.value = '';
			const name = this.getListStorageName();
			if (name != null) {
				this.appContext.clearSavedValue(`${name}-Global`);
			}
		}
		//clear table filters and sort
		const dt = this.getTable();
		if (dt != null) {
			dt.clear();
			this.appContext.clearSavedValueUsingOriginalKey(dt.stateKey);
		}
	}
}
