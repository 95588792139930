import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-summary-rate-group',
	templateUrl: './summary-rate-group.component.html',
	styleUrls: ['./summary-rate-group.component.scss'],
})
export class SummaryRateGroupComponent {
	@Input() rateGroupName: string;
}
