import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { BaseComponent } from 'src/app/core/components/base.component';
import { NavigationConstants } from 'src/app/core/constants/navigation.constants';
import { AppContextService, NavigationService } from 'src/app/core/services';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../main/app.main.component';
import { AppMenuConstants } from './Constants/app.menu.constants';

@Component({
	selector: 'app-menu',
	templateUrl: './app.menu.component.html',
})
export class AppMenuComponent extends BaseComponent implements OnInit {
	model: any[];

	constructor(public app: AppComponent, public appMain: AppMainComponent, appContext: AppContextService, private navigationService: NavigationService) {
		super(appContext);
		this.appContext.userChanged.pipe(takeUntil(this.destroyed$)).subscribe(x => this.refreshPage());
	}

	ngOnInit() {
		this.refreshPage();
	}

	onMenuClick() {
		this.appMain.menuClick = true;
	}

	refreshPage() {
		if (this.appContext.user?.SubscriberId) {
			this.model = this.getModel();
		} else {
			this.model = [];
		}
	}

	getModel(): any {
		const baseModel = {
			items: [
				{
					label: AppMenuConstants.MenuLinks.DashBoard,
					icon: NavigationConstants.Icon_Dashboard,
					permissionCode: Constants.UserPermission.DashboardView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.DashBoard)],
				},
				{
					label: AppMenuConstants.MenuLinks.ServiceRequests,
					icon: NavigationConstants.Icon_ServiceRequest,
					permissionCode: Constants.UserPermission.ServiceRequestView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.ServiceRequests)],
					styleClass: 'app-sidebar-nav-servicerequest-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.Scopes,
					icon: NavigationConstants.Icon_Scope,
					permissionCode: Constants.UserPermission.ScopeView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Scopes)],
					styleClass: 'app-sidebar-nav-scope-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.Quotes,
					icon: NavigationConstants.Icon_QuoteRequest,
					permissionCode: Constants.UserPermission.QuoteView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Quotes)],
					styleClass: 'app-sidebar-nav-quote-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.WorkOrders,
					icon: NavigationConstants.Icon_WorkOrder,
					permissionCode: Constants.UserPermission.WorkOrderView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.WorkOrders)],
					styleClass: 'app-sidebar-nav-workorder-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.PurchaseOrders,
					icon: NavigationConstants.Icon_PurchaseOrder,
					permissionCode: Constants.UserPermission.PurchaseOrderView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.PurchaseOrders)],
					styleClass: 'app-sidebar-nav-purchaseorder-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.Schedules,
					icon: NavigationConstants.Icon_Schedules,
					permissionCode: Constants.UserPermission.OrderScheduleView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.OrderSchedules)],
					styleClass: 'app-sidebar-nav-schedule-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.Invoices,
					icon: NavigationConstants.Icon_Invoice,
					styleClass: 'app-sidebar-nav-receivables-holder',
					items: [
						{
							label: AppMenuConstants.MenuLinks.Receivables,
							icon: NavigationConstants.Icon_ReceivableInvoice,
							permissionCode: Constants.UserPermission.InvoiceView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Receivables)],
							styleClass: 'app-sidebar-nav-receivables-holder',
						},
						{
							label: AppMenuConstants.MenuLinks.Payables,
							icon: NavigationConstants.Icon_PayableInvoice,
							permissionCode: Constants.UserPermission.InvoiceView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Payables)],
							styleClass: 'app-sidebar-nav-payables-holder',
						},
					],
				},
				{
					label: AppMenuConstants.MenuLinks.Assets,
					icon: NavigationConstants.Icon_Asset,
					permissionCode: Constants.UserPermission.AssetView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Assets)],
					styleClass: 'app-sidebar-nav-asset-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.Properties,
					icon: NavigationConstants.Icon_Property,
					permissionCode: Constants.UserPermission.AssetView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Properties)],
					styleClass: 'app-sidebar-nav-property-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.Reports,
					icon: NavigationConstants.Icon_Report,
					permissionCode: Constants.UserPermission.ReportView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Reports)],
				},
				{
					label: AppMenuConstants.MenuLinks.Calendar,
					icon: NavigationConstants.Icon_Calendar,
					permissionCode: Constants.UserPermission.CalendarView,
					routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Calendar)],
					styleClass: 'app-sidebar-nav-calendar-holder',
				},
				{
					label: AppMenuConstants.MenuLinks.Contacts,
					icon: NavigationConstants.Icon_Contact,
					styleClass: 'app-sidebar-nav-contact-holder',
					items: [
						{
							label: AppMenuConstants.MenuLinks.Clients,
							icon: NavigationConstants.Icon_Client,
							permissionCode: Constants.UserPermission.ClientView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Clients)],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
						{
							label: AppMenuConstants.MenuLinks.Contractors,
							icon: NavigationConstants.Icon_Contractor,
							permissionCode: Constants.UserPermission.BusinessEntityView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Contractors)],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
						{
							label: AppMenuConstants.MenuLinks.Employees,
							icon: NavigationConstants.Icon_Employee,
							permissionCode: Constants.UserPermission.BusinessEntityView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Employees)],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
						{
							label: AppMenuConstants.MenuLinks.Suppliers,
							icon: NavigationConstants.Icon_Supplier,
							permissionCode: Constants.UserPermission.BusinessEntityView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Suppliers)],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
					],
				},
				{
					label: AppMenuConstants.MenuLinks.Rates,
					icon: NavigationConstants.Icon_Rates,
					styleClass: 'app-sidebar-nav-sor-holder',
					permissionCode: Constants.UserPermission.SorView,
					items: [
						{
							label: AppMenuConstants.MenuLinks.RateGroups,
							icon: NavigationConstants.Icon_RateGroup,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.RateGroups)],
							styleClass: 'app-sidebar-nav-sor-holder',
						},
						{
							label: AppMenuConstants.MenuLinks.Items,
							icon: NavigationConstants.Icon_Item,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Items)],
							styleClass: 'app-sidebar-nav-sor-holder',
						},
						{
							label: AppMenuConstants.MenuLinks.Problems,
							icon: NavigationConstants.Icon_Problem,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Problems)],
							styleClass: 'app-sidebar-nav-sor-holder',
						},
					],
				},
				{
					label: AppMenuConstants.MenuLinks.Settings,
					icon: NavigationConstants.Icon_Settings,
					items: [
						{
							label: AppMenuConstants.MenuLinks.Companies,
							icon: NavigationConstants.Icon_Company,
							permissionCode: Constants.UserPermission.CompanyView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Companies)],
						},
						{
							label: AppMenuConstants.MenuLinks.Areas,
							icon: NavigationConstants.Icon_ServiceArea,
							permissionCode: Constants.UserPermission.AreaView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Areas)],
						},
						{
							label: AppMenuConstants.MenuLinks.Projects,
							icon: NavigationConstants.Icon_ServiceProject,
							permissionCode: Constants.UserPermission.ProjectView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Projects)],
						},
						{
							label: AppMenuConstants.MenuLinks.Fields,
							icon: NavigationConstants.Icon_Field,
							permissionCode: Constants.UserPermission.DomainValueView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Fields)],
						},
					],
				},
				{
					label: AppMenuConstants.MenuLinks.SystemInterface,
					icon: NavigationConstants.Icon_SystemInterface,
					items: [
						{
							label: AppMenuConstants.MenuLinks.IncomingEvents,
							icon: NavigationConstants.Icon_IncomingEvent,
							permissionCode: Constants.UserPermission.InterfaceView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.IncomingEvents)],
						},
						{
							label: AppMenuConstants.MenuLinks.OutgoingEvents,
							icon: NavigationConstants.Icon_OutgoingEvent,
							permissionCode: Constants.UserPermission.InterfaceView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.OutgoingEvents)],
						},
						{
							label: AppMenuConstants.MenuLinks.IntegrationModules,
							icon: NavigationConstants.Icon_InterfaceModule,
							permissionCode: Constants.UserPermission.IntegrationModuleView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.IntegrationModules)],
						},
					],
				},
				{
					label: AppMenuConstants.MenuLinks.AccountAdmin,
					icon: NavigationConstants.Icon_AccountAdmin,
					items: [
						{
							label: AppMenuConstants.MenuLinks.SubscriberSettings,
							icon: NavigationConstants.Icon_SubscriberSettings,
							permissionCode: Constants.UserPermission.SubscriberView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.SubscriberSettings)],
						},
						{
							label: AppMenuConstants.MenuLinks.Users,
							icon: NavigationConstants.Icon_User,
							permissionCode: Constants.UserPermission.UserView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.Users)],
						},
						{
							label: AppMenuConstants.MenuLinks.ExternalUsers,
							icon: NavigationConstants.Icon_ExternalUser,
							permissionCode: Constants.UserPermission.UserView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.ExternalUsers)],
						},
						{
							label: AppMenuConstants.MenuLinks.UserRoles,
							icon: NavigationConstants.Icon_UserRole,
							permissionCode: Constants.UserPermission.UserRoleView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.UserRoles)],
						},
						{
							label: AppMenuConstants.MenuLinks.UserTeams,
							icon: NavigationConstants.Icon_UserTeam,
							permissionCode: Constants.UserPermission.UserTeamView,
							routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.UserTeams)],
						},
					],
				},
			],
		};

		if (environment.showDevHelpers) {
			baseModel.items.push({
				label: AppMenuConstants.MenuLinks.DevTools,
				icon: NavigationConstants.Icon_DevTools,
				permissionCode: null,
				routerLink: [this.navigationService.getMenuLinkNavigation(AppMenuConstants.MenuLinks.DevTools)],
			});
		}

		this.applyPermissions(baseModel);

		return baseModel.items;
	}

	applyPermissions(model: any) {
		if (model.items == null) return;

		// top-level filter first
		model.items = model.items.filter(x => this.appContext.hasPermission(x.permissionCode));

		// then check individual items
		model.items.forEach(x => this.applyPermissions(x));

		// then re-filter for empty sub-item list
		model.items = model.items.filter(x => x.items == null || x.items.length > 0);
	}
}
