<p-table
	#relatedRateGroupTable
	*ngIf="showList"
	[value]="data"
	dataKey="data"
	[rowHover]="true"
	[rows]="50"
	[totalRecords]="totalRecords"
	[paginator]="true"
	[lazy]="true"
	responsiveLayout="scroll"
	[filterDelay]="0"
	[rowsPerPageOptions]="[10, 25, 50]"
	[showCurrentPageReport]="true"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[loading]="loading"
	[filterDelay]="0"
	(onLazyLoad)="loadData($event)">
	<ng-template pTemplate="header">
		<tr class="related-rategroup-table-row">
			<th pSortableColumn="Name" data-testid="relatedRateGroupName">
				<div class="table-header-label">
					Name<p-sortIcon field="Name"> </p-sortIcon>
					<p-columnFilter type="text" field="Name" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="StatusName" data-testid="relatedRateGroupStatus">
				<div class="table-header-label">
					Status<p-sortIcon field="StatusName"> </p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Description" data-testid="relatedRateGroupDescription">
				<div class="table-header-label">
					Description<p-sortIcon field="Description"> </p-sortIcon>
					<p-columnFilter type="text" field="Description" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="IsDefault" data-testid="relatedRateGroupIsDefault">
				<div class="table-header-label">
					Is Default<p-sortIcon field="IsDefault"></p-sortIcon>
					<p-columnFilter type="boolean" field="IsDefault" display="menu" class="p-ml-auto">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-checkbox
								#checkbox
								[ngModel]="value"
								[binary]="true"
								(onChange)="util.pTableCheckboxFilter(checkbox.model, 'IsDefault', relatedRateGroupTable)"></p-checkbox
							>{{ value == true ? ' yes' : value == false ? ' no' : ' any' }}
						</ng-template>
					</p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="OrderIndex" data-testid="relatedRateGroupOrderIndex">
				<div class="table-header-label">
					List Position<p-sortIcon field="OrderIndex"></p-sortIcon>
					<p-columnFilter type="numeric" field="OrderIndex" display="menu" class="p-ml-auto">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								(onBlur)="util.pTableNumericFilter(number.value, 'OrderIndex', relatedRateGroupTable)"
								autocomplete="off"
								[locale]="region.RegionCode"></p-inputNumber>
						</ng-template>
					</p-columnFilter>
				</div>
			</th>

			<th *ngIf="showLink()" style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rateGroupDetail>
		<tr class="rate-group-detail-row" data-testid="relatedRateGroupRow">
			<td>
				<span class="is-completed-style"> <i class="fa fa-circle dot-rategroup"></i></span> {{ rateGroupDetail.Name }}
			</td>
			<td>{{ rateGroupDetail.StatusName }}</td>
			<td>{{ rateGroupDetail.Description }}</td>
			<td>{{ rateGroupDetail.IsDefault ? 'Yes' : 'No' }}</td>
			<td>{{ rateGroupDetail.OrderIndex }}</td>

			<td *ngIf="showLink()" style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(rateGroupDetail)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For {{ rateGroupDetail.Name ?? '' }}"
					tooltipPosition="bottom"
					(click)="handleRowClick(rateGroupDetail)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(rateGroupDetail)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
</p-table>
