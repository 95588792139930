import { BulkActionNamesConstants } from './bulk-action-names.constants';

export class BulkActionPermissionConstants {
	static readonly ActionPermissionNames = [
		{ action: BulkActionNamesConstants.ActionName_ChangeParent, permission: 'AllowEdit', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Disable, permission: 'AllowRemove', markMessageName: 'Disabled' },
		{ action: BulkActionNamesConstants.ActionName_Hold, permission: 'AllowHold', markMessageName: 'On Hold' },
		{ action: BulkActionNamesConstants.ActionName_Enable, permission: 'AllowEnable', markMessageName: 'Enabled' },
		{ action: BulkActionNamesConstants.ActionName_Delete, permission: 'AllowRemove', markMessageName: 'Deleted' },
		{ action: BulkActionNamesConstants.ActionName_Active, permission: 'AllowActivate', markMessageName: 'Active' },
		{ action: BulkActionNamesConstants.ActionName_Approve, permission: 'AllowApprove', markMessageName: 'Approved' },
		{ action: BulkActionNamesConstants.ActionName_Cancel, permission: 'AllowRemove', markMessageName: 'Cancelled' },
		{ action: BulkActionNamesConstants.ActionName_Paid, permission: 'AllowPaid', markMessageName: 'Paid' },

		{ action: BulkActionNamesConstants.ActionName_Release, permission: 'AllowRelease', markMessageName: 'Released' },
		{ action: BulkActionNamesConstants.ActionName_Void, permission: 'AllowVoid', markMessageName: 'Void' },
		{ action: BulkActionNamesConstants.ActionName_CreateCreditNote, permission: 'AllowAddCreditNote', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Created, permission: 'AllowCreated', markMessageName: 'Created' },
		{ action: BulkActionNamesConstants.ActionName_Allocated, permission: 'AllowAllocated', markMessageName: 'Allocated' },
		{ action: BulkActionNamesConstants.ActionName_InProgress, permission: 'AllowInProgress', markMessageName: 'In Progress' },
		{ action: BulkActionNamesConstants.ActionName_DataEntryRequired, permission: 'AllowDataEntryRequired', markMessageName: 'Data Entry Required' },
		{ action: BulkActionNamesConstants.ActionName_ReviewRequired, permission: 'AllowReviewRequired', markMessageName: 'Review Required' },
		{ action: BulkActionNamesConstants.ActionName_ReviewApproved, permission: 'AllowReviewApproved', markMessageName: 'Review Approved' },
		{ action: BulkActionNamesConstants.ActionName_Pause, permission: 'AllowPaused', markMessageName: 'Paused' },
		{ action: BulkActionNamesConstants.ActionName_Sent, permission: 'AllowSent', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Received, permission: 'AllowReceived' },

		{ action: BulkActionNamesConstants.ActionName_Unsuccessful, permission: 'AllowUnsuccessful', markMessageName: 'Unsuccessful' },
		{ action: BulkActionNamesConstants.ActionName_SendChanges, permission: 'AllowSend', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Activate, permission: 'AllowActivate', markMessageName: 'Active' },
		{ action: BulkActionNamesConstants.ActionName_Retire, permission: 'AllowRetire', markMessageName: 'Retired' },
		{ action: BulkActionNamesConstants.ActionName_Print, permission: 'AllowPrinting', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_MarkAsDraft, permission: 'AllowDraft', markMessageName: 'Draft' },
		{ action: BulkActionNamesConstants.ActionName_Close, permission: 'AllowClose', markMessageName: 'Closed' },
		{ action: BulkActionNamesConstants.ActionName_Amend, permission: 'AllowAmend', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Accept, permission: 'AllowAccept', markMessageName: 'Accepted' },
		{ action: BulkActionNamesConstants.ActionName_Reject, permission: 'AllowReject', markMessageName: 'Rejected' },
		{ action: BulkActionNamesConstants.ActionName_SubmitRequest, permission: 'AllowSend', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Reprocess, permission: 'AllowReprocess', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Resend, permission: 'AllowResend', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_Recreate, permission: 'AllowRecreate', markMessageName: '' },
		{ action: BulkActionNamesConstants.ActionName_SendInvitation, permission: 'AllowSend', markMessageName: 'Invited' },
	];
}
