import { Component, Input } from '@angular/core';
import { ProgressBarComponent } from '../../progress-bar/progress-bar.component';

@Component({
	selector: 'app-bulk-action-dropdown',
	templateUrl: './bulk-action-dropdown.component.html',
	styleUrls: ['./bulk-action-dropdown.component.scss'],
})
export class BulkActionDropdownComponent {
	@Input() showEdit: boolean;
	@Input() bulkActionService: any;
	@Input() resetValues = false;
	@Input() progressBar: ProgressBarComponent;

	onfilterActionChange(event: any) {
		this.bulkActionService.progressBar = this.progressBar;
		if (this.resetValues) this.bulkActionService.resetValues();
		this.bulkActionService.onfilterActionChange(event);
	}
}
