import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { COLUMNTYPES } from 'src/app/shared/constants/column.constants';
import { RegionSettings } from 'src/app/shared/models/settings';
import { AppContextService, UtilService } from 'src/app/core/services';
import { Constants } from '../../constants/constants.constant';
import { Column } from '../../models/shared/column';
import { DynamicColumnService } from '../../services/dynamic-column.service';

@Component({
	selector: 'app-dynamic-table',
	templateUrl: './dynamic-table.component.html',
	styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements OnInit {
	@Input() dataKey: string;
	@Input() stateKey: string;
	@Input() loading: boolean;
	@Input() totalRecords = 0;
	@Input() first = 0;
	@Input() data: any;
	@Input() dataTestIdTable: string;
	@Input() dataTestIdRow: string;
	@Input() selectedColumns: Column[];
	@Input() columnService: DynamicColumnService;
	@Input() bulkActionService: any;
	@Output() LazyLoadEvent = new EventEmitter();
	@Output() RowClickEvent = new EventEmitter();

	@ViewChild('dynamicTable') dynamicTable: Table | undefined;

	region: RegionSettings;
	appContext: AppContextService;
	ColumnTypesEnum = COLUMNTYPES;

	constructor(appContext: AppContextService, public util: UtilService) {
		this.appContext = appContext;
	}

	ngOnInit() {
		this.region = this.appContext.getRegion();
	}

	getTable(): Table {
		return this.dynamicTable;
	}

	onLazyLoad(event: any): void {
		this.LazyLoadEvent.emit(event);
	}

	onSaveColumnOrderToStorage(event: any) {
		if (!this.columnService) return;
		this.columnService.saveColumnOrderToStorage(event);
	}

	onSelectionChange(event: any) {
		if (!this.bulkActionService) return;

		this.bulkActionService.onSelectionChange(event);
	}

	onPageChange(event: any) {
		if (!this.bulkActionService) return;

		this.bulkActionService.onPageChange(event);
	}

	onRowClick(event: any) {
		this.RowClickEvent.emit(event);
	}

	viewPrices(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonViewPrices);
	}
}
