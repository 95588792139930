import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, of } from 'rxjs';
import { Globals } from 'src/app/globals';
import { RegionSettings } from 'src/app/shared/models/settings';
import { FlowHistory } from 'src/app/shared/models/shared';
import { FlowHistoryService } from 'src/app/shared/services';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService, NavigationService, UtilService } from 'src/app/core/services';
import { LazyLoadArgs, PagedResponse } from '../../../models/api';

@Component({
	selector: 'app-related-flow',
	templateUrl: './related-flow.component.html',
	styleUrls: ['./related-flow.component.scss'],
})
export class RelatedFlowComponent extends DataTableBaseComponent<FlowHistory> implements OnInit {
	@ViewChild('relatedFlowhistoryTable') relatedFlowhistoryTable: Table;
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;
	@Input() includeCurrent: boolean;

	relatedFlowHistory: FlowHistory[] = [];
	totalRecords = 0;

	item: FlowHistory;
	showList = true;
	refreshRequired = false;
	timeZoneCode: string;
	isClientUser: boolean;
	public util: UtilService;
	region: RegionSettings;
	constructor(
		appContext: AppContextService,
		globals: Globals,
		config: PrimeNGConfig,
		private flowHistoryService: FlowHistoryService,
		private navigationService: NavigationService
	) {
		super(globals, appContext, config);
	}

	ngOnInit() {
		this.region = this.appContext.getRegion();
		this.appContext.HasTableBasedData = true;
		this.refreshData();
	}

	lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<FlowHistory>> {
		return of({} as PagedResponse<FlowHistory>);
	}

	public refreshData(): void {
		this.flowHistoryService.getFlowHistory(this.referenceTypeCode, this.referenceId, this.includeCurrent).subscribe(result => {
			this.relatedFlowHistory = result;
			this.showList = true;
		});
	}

	canNavigateToDetails(dto: FlowHistory) {
		return this.getNavigationRoute(dto) != null;
	}

	handleRowClick(dto: FlowHistory) {
		this.navigationService.navigateToRecord(dto.RecordTypeCode, dto.RecordId);
	}

	getNavigationRoute(dto: FlowHistory) {
		if (dto.RecordTypeCode != null && dto.RecordId != null) {
			return true;
		}
		return null;
	}

	showLink(): boolean {
		return !this.isClientUser;
	}
}
