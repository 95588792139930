export class WorkflowSettings {
	DashboardRefresh: DashboardRefreshSettings;
	Scope: ScopeSettings;
	QuoteRequest: QuoteRequestSettings;
	Defect: DefectSettings;
	Invoice: InvoiceSettings;
	Asset: AssetSettings;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class DashboardRefreshSettings {
	ScorecardRefresh: string;
	ReadyForActionRefresh: string;
	TimeKpiRefresh: string;
	SpendTrendRefresh: string;
	LicenceInsuranceRefresh: string;
	ContractorStatisticsRefresh: string;
	PurchaseOrderBreakdownRefresh: string;
	PurchaseOrderVolumeRefresh: string;
	WorkTypeAnalysisRefresh: string;
	UnpaidInvoicesRefresh: string;
	InterfaceEventsRefresh: string;
	OrderSchedulesRefresh: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class ScopeSettings {
	IsTargetDateOptional: boolean;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class QuoteRequestSettings {
	IsTargetDateOptional: boolean;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class DefectSettings {
	RecoverMoneyByDefault: boolean;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class InvoiceSettings {
	AutomateInvoiceCreationForWorkOrders: boolean;
	AutomateInvoiceCreationForPurchaseOrders: boolean;
	BlockCustomPaymentDates: boolean;
	DefaultPaymentTermCode: string;
	DefaultPaymentTermName: string;
	AllowManualPayments: boolean;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class AssetSettings {
	AllowQrBarcodeEditing: boolean;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
