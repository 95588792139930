import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { Contact } from 'src/app/shared/models/shared';
import { ContactService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, LookupService } from 'src/app/core/services';

@Component({
	selector: 'app-contact-person-table',
	templateUrl: './contact-person-table.component.html',
})
export class ContactPersonTableComponent extends BaseComponent implements OnInit {
	@ViewChild('dt') dt: Table;
	@Input() referenceId: string;
	@Input() referenceTypeCode: string;
	@Input() contactTypeLabel = 'Type';
	selectedContact: Contact;
	showList = true;
	totalRecords = 0;
	contacts: Contact[] = [];
	contactTypes: LookupValue[] = [];
	languages: LookupValue[] = [];
	region: RegionSettings;

	@Output() listUpdated = new EventEmitter();

	constructor(appContext: AppContextService, private contactService: ContactService, private lookupService: LookupService) {
		super(appContext);
	}

	ngOnInit() {
		this.region = this.appContext.getRegion();
	}

	refreshData() {
		if (this.referenceId && this.referenceTypeCode) {
			forkJoin({
				contacts: this.contactService.getContactsForReference(this.referenceTypeCode, this.referenceId),
				contactTypes: this.lookupService.getContactTypesForReference(this.referenceTypeCode),
				languages: this.lookupService.getLanguages(),
			}).subscribe(data => {
				this.contacts = data.contacts;
				this.contactTypes = data.contactTypes;
				this.languages = data.languages;
				this.showList = true;
				this.appContext.HasTableBasedData = true;
			});
		}
	}

	expandClick(item: Contact) {
		this.selectedContact = item;
		this.showList = false;
		this.appContext.HasTableBasedData = false;
	}

	editCancelled() {
		this.showList = true;
		this.appContext.HasTableBasedData = true;
	}

	editFinished() {
		this.refreshData();
		this.showList = true;
		this.appContext.HasTableBasedData = true;
		this.listUpdated.emit();
	}

	addClick(event: any) {
		this.showList = false;
		this.appContext.HasTableBasedData = false;
		this.selectedContact = new Contact();
		this.selectedContact.ReferenceId = this.referenceId;
		this.selectedContact.ReferenceTypeCode = this.referenceTypeCode;
	}

	showAddItem(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonContactsUpdate);
	}
}
