import { BulkActionConfirmationIconConstants } from 'src/app/shared/constants/bulk-action-confirmation-icon.constants';
import { BulkActionNamesConstants } from 'src/app/shared/constants/bulk-action-names.constants';
import { COLUMNTYPES } from 'src/app/shared/constants/column.constants';
import { Column } from 'src/app/shared/models/shared/column';

export class InvoiceConstants {
	//export
	static readonly Export_Receivables: string = 'Export Receivable Invoices';
	static readonly Export_Payables: string = 'Export Payable Invoices';

	//New Invoice
	static readonly New_Receivables: string = 'Create New Receivable Invoice';
	static readonly New_Payables: string = 'Create New Payable Invoice';
	static readonly ContactDisabled_Error_Message = (invoiceNumber: string, finanicialEntityName: string) =>
		`A credit note cannot be created for invoice ${invoiceNumber} as the contact ${finanicialEntityName} is currently disabled.`;

	//Back To Invoice List
	static readonly Receivables_BackToList: string = 'Back To Receivables Invoice List';
	static readonly Payables_BackToList: string = 'Back To Payables Invoice List';

	// generic error
	private static readonly Generic_MissingPermission: string = 'You do not have permissions to perform this action.';

	// invoice menu action tooltip messages
	public static readonly Page_Action = (actionName: string) => `${actionName} Invoice`;
	public static readonly Page_Action_CreditNote = (actionName: string) => `${actionName} Credit Note`;
	public static readonly Page_Action_Paid = (actionName: string) => `Mark Invoice as ${actionName}`;

	//invoice action button label text
	public static readonly Button_Label_Cancel_CreditNote = 'Cancel Credit Note';
	public static readonly Button_Label_Cancel_Invoice = 'Cancel Invoice';

	// item list
	static readonly Tab_ItemsList_Send: string = 'Send';
	static readonly Tab_ItemsList_Add: string = 'Add Item';
	static readonly Tab_ItemsList_Edit: string = 'Edit Item';
	static readonly ItemsOtherDotTooltip = (invoiceType: string, referenceTypeName: string) =>
		`This item will be included on a separate ${invoiceType} invoice against this ${referenceTypeName}.`;
	static readonly ItemsDotTooltip = (invoiceType: string) => `This item can only be included on a ${invoiceType} invoice.`;

	//General
	static readonly FeatureComingSoon_Tooltip = (featureName: string) => `${featureName} feature coming soon!`;

	//item detail
	static readonly Item_Detail_Delete: string = 'Delete Item';

	// details
	static readonly Tab_Details_Edit: string = 'Edit Invoice';

	//FilterActions
	static readonly FilterActionsList = [
		BulkActionNamesConstants.ActionName_Approve,
		BulkActionNamesConstants.ActionName_Cancel,
		BulkActionNamesConstants.ActionName_Hold,
		BulkActionNamesConstants.ActionName_Release,
		BulkActionNamesConstants.ActionName_Void,
		BulkActionNamesConstants.ActionName_Paid,
	];

	//BulkAction ActionIcons
	static readonly ActionIconsNames = [
		{ action: BulkActionNamesConstants.ActionName_Approve, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Approve },
		{ action: BulkActionNamesConstants.ActionName_Cancel, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Cancel },
		{ action: BulkActionNamesConstants.ActionName_Paid, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_MarkAsPaid },
		{ action: BulkActionNamesConstants.ActionName_Hold, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Hold },
		{ action: BulkActionNamesConstants.ActionName_Release, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Release },
		{ action: BulkActionNamesConstants.ActionName_Void, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Void },
	];

	static readonly InvoicePaidActions: string = 'Do you want to mark this invoice as Paid?';
	static readonly InvoiceHoldActions: string = 'Do you want to put this invoice on Hold?';
	static readonly InvoiceReleaseActions: string = 'Do you want to release this invoice?';
	static readonly InvoiceVoidActions: string = 'Do you want to void this invoice?';

	static readonly BulkAction_Object_Name = 'Invoice';
	static readonly BulkAction_Object_PropertyName_Number = 'InvoiceNumber';

	//Summary Headers
	static readonly InvoiceDetails: string = 'Invoice Details';
	static readonly CreditNoteDetails: string = 'Credit Note Details';

	static readonly ContactHeader = (financilEntityType: string) => `${financilEntityType} Details`;
	static readonly ContactDetails: string = 'Contact Details';

	//Item Issue Detail
	static readonly Tab_Item_Issue_Edit_Item: string = 'Edit Issue';

	// Item Payment
	static readonly Tab_Item_Payment_Add_Item: string = 'Add Payment';
	static readonly Tab_Item_Payment_Delete_Item: string = 'Delete Payment';

	//Items List Table Column Header Text
	static readonly TableColHeaderText_Receivable: string = 'Receivable';
	static readonly TableColHeaderText_Payable: string = 'Payable';

	//Invoice Base List Columns
	private static readonly COLUMNS_BASE_INV: Column[] = [
		{ order: 1, header: 'Invoice #', field: 'InvoiceNumber', dataTestId: 'invoiceNumber', width: 100 },
		{ order: 2, header: 'Invoice Ref #', field: 'InvoiceReferenceNumber' },
		{ order: 3, header: 'Type', field: 'InvoiceTypeName' },
		{ order: 4, header: 'Contact', field: 'FinancialEntityName' },
		{ order: 5, header: 'Contact Type', field: 'FinancialEntityTypeName' },
		{ order: 6, header: 'Comments', field: 'Comments' },
		{ order: 7, header: 'Category', field: 'InvoiceCategoryName' },
		{ order: 8, header: 'Work Asset Name', field: 'WorkAssetReference', dataTestId: 'invoiceAssetReference' },
		{ order: 9, header: 'Work Address', field: 'WorkStreet' },
		{ order: 10, header: 'Work Suburb', field: 'WorkSuburb' },
		{ order: 11, header: 'Status', field: 'StatusName' },
		{
			order: 12,
			header: 'Invoice',
			field: 'InvoiceAmountInclTax',
			type: COLUMNTYPES.NUMERIC,
			isConditional: true,
			hasHeaderSmallLabel: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.NUMERIC,
			isPrice: true,
		},
		{
			order: 13,
			header: 'Due',
			field: 'TargetDate',
			type: COLUMNTYPES.DATE,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.DATE,
		},
	];

	//Payable Invoice List Columns
	static readonly COLUMNS_PAYINV: Column[] = [...this.COLUMNS_BASE_INV];

	//Receivable Invoice List Columns
	static readonly COLUMNS_RECINV: Column[] = [...this.COLUMNS_BASE_INV];
}
