import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, of } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { RegionSettings } from 'src/app/shared/models/settings';
import { WorkHistory } from 'src/app/shared/models/shared/work-history.model';
import { SearchService } from 'src/app/shared/services';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService, NavigationService, UtilService } from 'src/app/core/services';
import { LazyLoadArgs, PagedResponse } from '../../../models/api';

@Component({
	selector: 'app-related-work-history',
	templateUrl: './related-work-history.component.html',
	styleUrls: ['./related-work-history.component.scss'],
})
export class RelatedWorkHistoryComponent extends DataTableBaseComponent<WorkHistory> implements OnInit {
	@ViewChild('relatedWorkHistoryTable') relatedWorkHistoryTable: Table;
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;
	@Input() assetNumber: string;

	relatedWorkHistoryDetails: WorkHistory[] = [];
	totalRecords = 0;

	item: WorkHistory;
	showList = true;
	refreshRequired = false;
	timeZoneCode: string;
	isClientUser: boolean;
	region: RegionSettings;
	public util: UtilService;

	constructor(
		appContext: AppContextService,
		globals: Globals,
		config: PrimeNGConfig,
		private searchService: SearchService,
		private router: Router,
		private navigationService: NavigationService,
		private changeDetector: ChangeDetectorRef
	) {
		super(globals, appContext, config);
	}

	ngOnInit(): void {
		this.region = this.appContext.getRegion();
	}

	lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<WorkHistory>> {
		this.changeDetector.detectChanges();
		if (this.referenceTypeCode == Constants.ReferenceType.Client) {
			return this.searchService.getPagedWorkHistoryListForClient(event, this.referenceId);
		} else if (this.assetNumber) {
			return this.searchService.getPagedWorkHistoryListForAsset(event, this.assetNumber);
		}

		return of(new PagedResponse<WorkHistory>());
	}

	canNavigateToDetails(dto: WorkHistory) {
		return this.getNavigationRoute(dto) != null;
	}

	public refreshData(): void {
		this.loadData();
	}

	handleRowClick(dto: WorkHistory) {
		this.navigationService.navigateToReference(dto.ReferenceTypeCode, dto.ReferenceId);
	}

	showLink(): boolean {
		return !this.isClientUser;
	}

	getRowTypeName(dto: WorkHistory) {
		return dto.ReferenceTypeCode ? this.getReferenceTypeName(dto.ReferenceTypeCode) : null;
	}

	getNavigationRoute(dto: WorkHistory) {
		if (dto.RecordTypeCode != null && dto.ReferenceId != null) {
			return true;
		}
		return null;
	}

	getReferenceTypeName(referenceTypeCode: string) {
		return Constants.ReferenceType.Values.filter(x => x.CodeValue == referenceTypeCode)?.[0].NameValue;
	}
}
