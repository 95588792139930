import { BulkActionConfirmationIconConstants } from 'src/app/shared/constants/bulk-action-confirmation-icon.constants';
import { BulkActionNamesConstants } from 'src/app/shared/constants/bulk-action-names.constants';
import { COLUMNTYPES } from 'src/app/shared/constants/column.constants';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { Column } from 'src/app/shared/models/shared/column';

export class RateGroupConstants {
	// Detail
	static readonly Tab_detail_EditRateGroup: string = 'Edit Rate Group';
	static readonly Tab_detail_SaveRateGroup: string = 'Save Rate Group';

	//Items
	static readonly Tab_Items_AddItem: string = 'Add Item';
	static readonly Tab_Items_EditItem: string = 'Edit Item';
	static readonly Tab_Items_DeleteItem: string = 'Delete Item';

	//FilterActions
	static readonly FilterActionsList = [
		BulkActionNamesConstants.ActionName_Activate,
		BulkActionNamesConstants.ActionName_Disable,
		BulkActionNamesConstants.ActionName_Retire,
	];

	//BulkAction ActionIcons
	static readonly ActionIconsNames = [
		{ action: BulkActionNamesConstants.ActionName_Activate, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Activate },
		{ action: BulkActionNamesConstants.ActionName_Disable, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Disable },
		{ action: BulkActionNamesConstants.ActionName_Retire, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Retire },
	];

	static readonly BulkAction_Object_Name = 'RateGroup';
	static readonly BulkAction_Object_PropertyName = 'Name';

	//Export List
	static readonly Export_List = [
		{ NameValue: 'Item', CodeValue: Constants.ReferenceType.Sor },
		{ NameValue: 'Item Cost Price', CodeValue: Constants.ReferenceType.SorCostPrice },
		{ NameValue: 'Request', CodeValue: Constants.ReferenceType.Problem },
	];

	//Record Type
	static readonly ListType_RateGroup: string = 'RateGroup';

	//Rate Group List Columns
	static readonly COLUMNS_RATEGROUPS: Column[] = [
		{ order: 1, header: 'Name', field: 'Name', minWidth: 120 },
		{ order: 2, header: 'Status', field: 'StatusName', minWidth: 120, width: 120, maxWidth: 120 },
		{ order: 3, header: 'Description', field: 'Description', minWidth: 100 },
		{
			order: 4,
			header: 'Is Default?',
			field: 'IsDefault',
			type: COLUMNTYPES.BOOLEAN,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.BOOLEAN,
			minWidth: 140,
			width: 140,
			maxWidth: 140,
		},
		{
			order: 5,
			header: 'List Position',
			field: 'OrderIndex',
			type: COLUMNTYPES.NUMERIC,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.NUMERIC,
			minWidth: 140,
			width: 140,
			maxWidth: 140,
		},
	];
}
