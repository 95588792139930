import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, NavigationService } from 'src/app/core/services';
import { Constants } from '../../constants/constants.constant';
import { RelatedTabConstants, RelatedTabEnum } from '../../constants/related-tab.constants';
import { RelatedTabDisplayFilterType } from '../../enums/related-tab-display-filter.enum';
import { FormMode } from '../../models/forms';
import { RelatedFlowComponent } from './related-flow/related-flow.component';
import { RelatedPurchaseOrdersComponent } from './related-purchase-orders/related-purchase-orders.component';
import { RelatedRateGroupComponent } from './related-rategroup/related-rategroup.component';
import { RelatedScheduleHistoryComponent } from './related-schedule-history/related-schedule-history.component';
import { RelatedSchedulesComponent } from './related-schedules/related-schedules.component';
import { RelatedWorkHistoryComponent } from './related-work-history/related-work-history.component';
import { RelatedWorkOrdersComponent } from './related-work-orders/related-work-orders.component';

@Component({
	selector: 'app-related-tab',
	templateUrl: './related-tab.component.html',
	styleUrls: ['./related-tab.component.scss'],
})
export class RelatedTabComponent extends BaseComponent implements OnInit {
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;
	@Input() assetNumber: string;
	@Input() includeCurrent: boolean;
	@Input() isScheduledWorkOrder: boolean;

	@ViewChild('relatedWOTable') relatedWOTable: RelatedWorkOrdersComponent;
	@ViewChild('relatedPOTable') relatedPOTable: RelatedPurchaseOrdersComponent;
	@ViewChild('relatedSchedulesTable') relatedSchedulesTable: RelatedSchedulesComponent;
	@ViewChild('relatedFlowTable') relatedFlowTable: RelatedFlowComponent;
	@ViewChild('relatedRateGroupTable') relatedRateGroupTable: RelatedRateGroupComponent;
	@ViewChild('relatedScheduleHistoryTable') relatedScheduleHistoryTable: RelatedScheduleHistoryComponent;
	@ViewChild('relatedWorkHistoryTable') relatedWorkHistoryTable: RelatedWorkHistoryComponent;

	formMode = FormMode.View;
	formModes = FormMode;
	assetTypeCode: string;
	displayFilterOptions: any;
	displayFilterTypes = RelatedTabDisplayFilterType;
	selectedFilterOption: any;
	constructor(appContext: AppContextService, private navigationService: NavigationService) {
		super(appContext);
	}

	ngOnInit(): void {
		this.formMode = this.formModes.View;
		this.displayFilterOptions = this.getFilterOptions();
	}

	getFilterOptions() {
		let list = [];
		switch (this.referenceTypeCode) {
			case Constants.ReferenceType.Client:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_CLIENT_DISPLAY_FILTER_LIST);
				return list;
			case Constants.ReferenceType.ServiceRequest:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_SRQ_DISPLAY_FILTER_LIST);
				return list;
			case Constants.ReferenceType.Scope:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_SCOPE_DISPLAY_FILTER_LIST);
				return list;
			case Constants.ReferenceType.QuoteRequest:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_QUOTE_DISPLAY_FILTER_LIST);
				return list;
			case Constants.ReferenceType.WorkOrder:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_WORK_ORDER_DISPLAY_FILTER_LIST);
				if (!this.isScheduledWorkOrder) {
					list = list.filter(x => x.value != RelatedTabDisplayFilterType.SCHEDULE_HISTORY);
				}
				return list;
			case Constants.ReferenceType.OrderSchedule:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_WO_SCHEDULE_DISPLAY_FILTER_LIST);
				return list;
			case Constants.ReferenceType.Invoice:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_INVOICE_SCHEDULE_DISPLAY_FILTER_LIST);
				return list;
			case Constants.ReferenceType.Asset:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_ASSET_DISPLAY_FILTER_LIST);
				return list;

			default:
				list = this.getTabsByPermission(RelatedTabConstants.RELATED_TAB_DEFAULT_FILTER_LIST);
				return list;
		}
	}

	changeFilterOption(event: any) {
		this.selectedFilterOption = this.displayFilterOptions.find(x => x.value == event.value)?.value;
	}

	isSelectedSubTab(subTab: string) {
		return this.selectedFilterOption == subTab;
	}

	selectDefaultSubTab() {
		if (this.displayFilterOptions?.length > 0) {
			this.selectedFilterOption = this.displayFilterOptions[0].value;
			this.refreshDefaultSubTabData();
		} else {
			this.selectedFilterOption = RelatedTabConstants.DEFAULT_RELATED_TAB_NONE;
		}
	}

	refreshDefaultSubTabData() {
		switch (this.selectedFilterOption) {
			case RelatedTabEnum.Flow:
				this.relatedFlowTable?.refreshData();
				break;

			case RelatedTabEnum.PurchaseOrders:
				this.relatedPOTable?.refreshData();
				break;

			case RelatedTabEnum.RateGroup:
				this.relatedRateGroupTable?.refreshData();
				break;

			case RelatedTabEnum.ScheduleHistory:
				this.relatedScheduleHistoryTable?.refreshData();
				break;

			case RelatedTabEnum.Schedules:
				this.relatedSchedulesTable?.refreshData();
				break;

			case RelatedTabEnum.WorkHistory:
				this.relatedWorkHistoryTable?.refreshData();
				break;

			case RelatedTabEnum.WorkOrders:
				this.relatedWOTable?.refreshData();
				break;
		}
	}

	getTabsByPermission(list: any): any[] {
		const updatedList = [];
		list.forEach(x => {
			const filterList = RelatedTabConstants.FILTER_LIST;

			const currentFilter = filterList.find(y => y.TabValue == x.value);
			if (currentFilter) {
				if (currentFilter.permission == null || this.appContext.hasPermission(currentFilter.permission)) {
					updatedList.push(x);
				}
			}
		});
		return updatedList;
	}
}
