import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult } from 'src/app/shared/models/api';
import { Note } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class NoteService {
	constructor(private apiService: ApiService) {
		//
	}

	getNotesForReference(referenceTypeCode: string, referenceId: string): Observable<Note[]> {
		return this.apiService.get<Note[]>(`/Note/GetNotesForReference/${referenceTypeCode}/${referenceId}`);
	}

	getNotesForWorkOrder(referenceId: string): Observable<Note[]> {
		return this.apiService.get<Note[]>(`/Note/GetNotesForWorkOrder/${referenceId}`);
	}

	saveNote(note: Note) {
		return this.apiService.post<ActionResult<Note>>('/Note/SaveNote', note);
	}

	disableNote(noteId: string) {
		return this.apiService.get<ActionResult<Note>>(`/Note/DisableNote/${noteId}`);
	}
}
