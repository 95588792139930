import { StatusLookupValue } from './status-lookup-value.model';

export class InvoiceLookupValue extends StatusLookupValue {
	StatusName: string;
	InvoiceTypeCode: string;
	InvoiceTypeName: string;
	InvoiceSubtypeCode: string;
	InvoiceSubtypeName: string;
	ReferenceTypeCode: string;
	ParentInvoiceId: string;

	FinancialEntityId: string;
	FinancialEntityTypeCode: string;
	FinancialEntityName: string;

	TaxCode: string;
	TaxName: string;
	TaxRate: number;

	InvoiceAmountInclTax: number;
	AdjustedInvoiceAmountInclTax: number;

	WorkAssetId: string;
	WorkAssetNumber: string;
	WorkAssetReference: string;
	WorkStreet: string;
	WorkSuburb: string;
	WorkPostcode: string;
	WorkState: string;
	WorkCountry: string;
}
