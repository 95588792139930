import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { RegionSettings } from 'src/app/shared/models/settings';
import { GlobalSearch } from 'src/app/shared/models/shared';
import { ScheduleHistory } from 'src/app/shared/models/shared/schedule-history.model';
import { SearchService } from 'src/app/shared/services';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService, NavigationService, UtilService } from 'src/app/core/services';
import { LazyLoadArgs, PagedResponse } from '../../../models/api';

@Component({
	selector: 'app-related-schedule-history',
	templateUrl: './related-schedule-history.component.html',
	styleUrls: ['./related-schedule-history.component.scss'],
})
export class RelatedScheduleHistoryComponent extends DataTableBaseComponent<ScheduleHistory> implements OnInit {
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;

	totalRecords = 0;

	item: ScheduleHistory;
	showList = true;
	refreshRequired = false;
	timeZoneCode: string;
	isClientUser: boolean;
	region: RegionSettings;
	public util: UtilService;

	constructor(
		appContext: AppContextService,
		globals: Globals,
		config: PrimeNGConfig,
		private searchService: SearchService,
		private navigationService: NavigationService,
		private changeDetector: ChangeDetectorRef
	) {
		super(globals, appContext, config);
	}

	ngOnInit(): void {
		this.region = this.appContext.getRegion();
	}

	lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<ScheduleHistory>> {
		this.changeDetector.detectChanges();
		if (this.referenceTypeCode == Constants.ReferenceType.WorkOrder) return this.searchService.getPagedScheduleHistoryListForWorkOrder(event, this.referenceId);
		else if (this.referenceTypeCode == Constants.ReferenceType.Asset) return this.searchService.getPagedScheduleHistoryListForAsset(event, this.referenceId);

		return of(new PagedResponse<ScheduleHistory>());
	}

	canNavigateToDetails(dto: ScheduleHistory) {
		return this.getNavigationRoute(dto) != null;
	}

	public refreshData(): void {
		this.loadData();
	}

	handleRowClick(dto: GlobalSearch) {
		this.navigationService.navigateToReference(dto.ReferenceTypeCode, dto.ReferenceId);
	}

	showLink(): boolean {
		return !this.isClientUser;
	}

	getRowTypeName(dto: GlobalSearch) {
		return dto.ReferenceTypeCode ? this.getReferenceTypeName(dto.ReferenceTypeCode) : null;
	}

	getNavigationRoute(dto: ScheduleHistory) {
		if (dto.ReferenceTypeCode != null && dto.ReferenceId != null) {
			return true;
		}
		return null;
	}

	getReferenceTypeName(referenceTypeCode: string) {
		return Constants.ReferenceType.Values.filter(x => x.CodeValue == referenceTypeCode)?.[0].NameValue;
	}
}
