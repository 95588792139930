export class WorkHistory {
	ReferenceTypeCode: string;
	ReferenceId: string;
	ReferenceName: string;

	RecordTypeCode: string;
	WorkActivityTypeName: string;

	AssetNumber: string;
	AssetTypeCode: string;
	AssetTypeName: string;
	AssetReference: string;
	AssetCategoryName: string;
	Street: string;
	Suburb: string;

	ParentReferenceName: string;
	WorkOrderPriorityCode: string;

	StatusName: string;

	DateIssued: Date;
	ScheduledStartDate: Date;
	TargetDate: Date;
	DateCompleted: Date;

	SellAmountInclTax: number;

	TimeZoneCode: string;
}
