import { GeolocationDto } from '../shared/geolocation.model';
import { LookupValue } from './lookup-value.model';

export class AssetLookupValue extends LookupValue {
	AssetId: string;
	AssetNumber: string;
	AssetTypeCode: string;
	AssetTypeName: string;
	AssetReference: string;
	AssetCategoryName: string;
	InternalAssetReference: string;
	ExternalAssetReference: string;
	ServiceAreaId: string;
	ServiceAreaName: string;
	ClientId: string;
	ClientName: string;
	Street: string;
	Suburb: string;
	Postcode: string;
	State: string;
	Country: string;
	GeolocationDto: GeolocationDto;
	Barcode: string;
	QrCode: string;
	TimeZoneName: string;
}
