import { FinancialBillingSettings } from '.';

export class FinancialSettings {
	TaxDetails: TaxDetails;
	FinancialBillingSettings: FinancialBillingSettings;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class TaxDetails {
	TaxCode: string;
	TaxName: string;
	TaxRate: number;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
