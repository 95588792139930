<div>
	<div *ngIf="showCreate()" data-testid="summaryFlagsMultiSelect">
		<div
			*ngIf="allowCreate()"
			class="tag-selection-container"
			[ngClass]="{ 'readonly-disable': isCreateDisable() }"
			[pTooltip]="getEditTooltip()"
			tooltipPosition="bottom">
			<p-multiSelect
				[options]="lookupFlags"
				[(ngModel)]="selectedFlags"
				optionLabel="NameValue"
				[showHeader]="false"
				placeholder="Select Flags"
				[displaySelectedLabel]="false"
				class="tag-selection--dropdown"
				appendTo="body"
				[ngClass]="{ 'selection-disable': isCreateDisable() }"
				(onChange)="onTagsChange($event)"></p-multiSelect>
		</div>
	</div>
	<div class="tag-container" [pTooltip]="getEditTooltip()" tooltipPosition="bottom">
		<div class="tag" *ngFor="let selectedFlag of selectedFlags" [style.background-color]="selectedFlag.AdditionalDetails.ColourCode">
			<div class="tag-bell">
				<i class="pi pi-bell"></i>
			</div>
			<div [ngClass]="showDelete() ? 'tag-name tag-name-edit' : 'tag-name'">{{ selectedFlag.NameValue }}</div>
			<a *ngIf="showDelete()" class="tag-close" href="javascript:void(0)" (click)="requestFlagRemoval(selectedFlag.CodeValue)" role="button">
				<i class="tag-close--icon"></i>
			</a>
		</div>
	</div>
</div>
