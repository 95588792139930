export class SharedConstants {
	//Notes
	// tooltip messages
	static readonly Button_Edit_Note: string = 'Edit Note';
	static readonly Button_Delete_Note: string = 'Delete Note';

	//Attachments
	// tooltip messages
	static readonly Button_Edit_Attachment: string = 'Edit Attachment';
	static readonly Button_Delete_Attachment: string = 'Delete Attachment';
}
