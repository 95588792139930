<p-toolbar *ngIf="showList">
	<ng-template pTemplate="left"> </ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showAddItem()" tooltipPosition="bottom" pTooltip="Add Contact">
			<p-button label="Add" icon="pi pi-plus" (onClick)="addClick($event)" data-testid="contactPersonAddButton"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	*ngIf="showList"
	#dt
	[rowHover]="true"
	responsiveLayout="scroll"
	[value]="contacts"
	[showCurrentPageReport]="true"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[rows]="50"
	[paginator]="true"
	[totalRecords]="totalRecords"
	[filterDelay]="0"
	[rowsPerPageOptions]="[10, 25, 50]">
	<ng-template pTemplate="header">
		<tr>
			<!--<th pSortableColumn="ContactId">
                <div class="table-header-label">
                    ID #
                    <p-sortIcon field="ContactId"></p-sortIcon>
                    <p-columnFilter type="text" field="ContactId" display="menu" class="p-ml-auto">
                    </p-columnFilter>
                </div>
            </th>-->
			<th pSortableColumn="Name">
				<div class="table-header-label">
					Name
					<p-sortIcon field="Name"></p-sortIcon>
					<p-columnFilter type="text" field="Name" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="PhoneNumber">
				<div class="table-header-label">
					Phone #
					<p-sortIcon field="PhoneNumber"></p-sortIcon>
					<p-columnFilter type="text" field="PhoneNumber" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="MobileNumber">
				<div class="table-header-label">
					Mobile #
					<p-sortIcon field="MobileNumber"></p-sortIcon>
					<p-columnFilter type="text" field="MobileNumber" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="EmailAddress">
				<div class="table-header-label">
					Email
					<p-sortIcon field="EmailAddress"></p-sortIcon>
					<p-columnFilter type="text" field="EmailAddress" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ContactTypeName">
				<div class="table-header-label">
					{{ contactTypeLabel }}
					<p-sortIcon field="ContactTypeName"></p-sortIcon>
					<p-columnFilter type="text" field="ContactTypeName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateActiveFrom">
				<div class="table-header-label">
					From
					<p-sortIcon field="DateActiveFrom"></p-sortIcon>
					<p-columnFilter type="date" field="DateActiveFrom" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateActiveTo">
				<div class="table-header-label">
					To
					<p-sortIcon field="DateActiveTo"></p-sortIcon>
					<p-columnFilter type="date" field="DateActiveTo" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="IsPrimary">
				<div class="table-header-label">
					Is Primary?
					<p-sortIcon field="IsPrimary"></p-sortIcon>
					<p-columnFilter type="boolean" field="IsPrimary" display="menu" class="p-ml-auto">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-checkbox #checkbox [ngModel]="value" [binary]="true" (onChange)="util.pTableCheckboxFilter(checkbox.model, 'IsPrimary', dt)"></p-checkbox
							>{{ value == true ? ' yes' : value == false ? ' no' : ' any' }}
						</ng-template>
					</p-columnFilter>
				</div>
			</th>
			<th style="width: 3rem" name="expand-edit-button"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item>
		<tr class="p-selectable-row">
			<!--<td>
                <span class="p-column-title">ID #</span>
                {{item.ContactId}}
            </td>-->
			<td>
				<span class="p-column-title">Name</span>
				{{ item.Name }}
			</td>
			<td>
				<span class="p-column-title">Phone #</span>
				{{ item.PhoneNumber }}
			</td>
			<td>
				<span class="p-column-title">Mobile #</span>
				{{ item.MobileNumber }}
			</td>
			<td>
				<span class="p-column-title">Email</span>
				{{ item.EmailAddress }}
			</td>
			<td>
				<span class="p-column-title">{{ contactTypeLabel }}</span>
				{{ item.ContactTypeName }}
			</td>
			<td>
				<span class="p-column-title">From</span>
				<span [innerHTML]="item.DateActiveFrom | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span class="p-column-title">To</span>
				<span [innerHTML]="item.DateActiveTo | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span class="p-column-title">Is Primary?</span>
				{{ item.IsPrimary ? 'yes' : '' }}
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					data-testid="contactPersonExpandButton"
					type="button"
					pButton
					pRipple
					pTooltip="Expand"
					tooltipPosition="bottom"
					(click)="expandClick(item)"
					class="p-button-text p-button-rounded p-button-plain"
					[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
			</td>
		</tr>
	</ng-template>
</p-table>

<app-contact-details
	*ngIf="!showList"
	[item]="selectedContact"
	[contactTypes]="contactTypes"
	[languages]="languages"
	[contactTypeLabel]="contactTypeLabel"
	(editFinished)="editFinished()"
	(editCancelled)="editCancelled()">
</app-contact-details>
