import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { AppContextService, SecurityService } from 'src/app/core/services';

@Component({
	selector: 'app-access-denied',
	templateUrl: './access-denied.component.html',
	styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent implements OnInit {
	constructor(private appContext: AppContextService, private securityService: SecurityService) {
		this.appContext.ViewTitle = 'Access Denied';
		this.appContext.BasePageCSSClass.set(Constants.BasePageCSSClass.Default);
	}
	ngOnInit(): void {
		this.securityService.updateCurrentUser(false).subscribe();
	}
}
