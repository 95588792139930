import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from 'src/app/globals';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { FormMode } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { Attachment, AttachmentDetail, AttachmentFile } from 'src/app/shared/models/shared';
import { AttachmentService } from 'src/app/shared/services';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService, LoaderService } from 'src/app/core/services';
import { nameofFactory } from 'src/app/core/utils';

@Component({
	selector: 'app-attachment-list',
	templateUrl: './attachment-list.component.html',
	styleUrls: ['./attachment-list.component.scss'],
})
export class AttachmentListComponent extends DataTableBaseComponent<Attachment> implements OnInit {
	@Input() referenceId: string;
	@Input() referenceTypeCode: string;
	@Input() includeRelated: boolean;
	@Input() timezoneCode: string;
	@ViewChild('attachmentsTable') attachmentsTable: Table;
	attachmentTypes: LookupValue[] = [];
	currentAttachment: Attachment;
	showList = true;
	refreshList = false;
	selectedFile: AttachmentFile;
	images = [];
	imagesCurrentAttachment = [];
	displayPreview: boolean;
	activeIndex = 0;
	region: RegionSettings;
	listEnabled = false; // this is used to prevent the list from being updated automatically before the parent tab is clicked

	constructor(appContext: AppContextService, private svc: AttachmentService, config: PrimeNGConfig, globals: Globals, private loaderService: LoaderService) {
		super(globals, appContext, config);
	}

	ngOnInit(): void {
		this.showList = true;
		this.region = this.appContext.getRegion();
	}

	readonly nameof = nameofFactory<AttachmentDetail>();

	getDateFilterFields(): string[] {
		return [this.nameof('DateAdded'), this.nameof('DateUpdated'), this.nameof('DateRemoved')];
	}

	getDateFilterTimezoneCode(): string {
		return this.timezoneCode;
	}

	refreshData() {
		this.listEnabled = true;
		this.loadData();
	}

	lazyLoad(args: LazyLoadArgs): Observable<PagedResponse<Attachment>> {
		this.currentAttachment = null;
		this.showList = true;
		this.appContext.HasTableBasedData = true;

		if (this.referenceTypeCode && this.referenceId && this.listEnabled) {
			if (this.referenceTypeCode == Constants.ReferenceType.WorkOrder) {
				return this.svc.getPagedListAttachmentsForWorkOrder(args, this.referenceId).pipe(
					map(data => {
						data.Data.forEach(x =>
							x.Files.forEach(file => {
								if (this.isValidImage(file)) {
									const index = this.images.findIndex(x => x.AttachmentFileId == file.AttachmentFileId);
									if (index === -1) {
										this.images.push({
											AttachmentFileId: file.AttachmentFileId,
											AttachmentId: file.AttachmentId,
											PreviewImage: `data:image/png;base64,${file.FileData}`,
											PreviewThumbnail: `data:image/png;base64,${file.FileData}`,
											IsFullImage: false,
										});
									}
								}
							})
						);
						return data;
					})
				);
			} else {
				return this.svc.getPagedListAttachmentsForParent(args, this.referenceTypeCode, this.referenceId, this.includeRelated).pipe(
					map(data => {
						data.Data.forEach(x =>
							x.Files.forEach(file => {
								if (this.isValidImage(file)) {
									const index = this.images.findIndex(x => x.AttachmentFileId == file.AttachmentFileId);
									if (index === -1) {
										this.images.push({
											AttachmentFileId: file.AttachmentFileId,
											AttachmentId: file.AttachmentId,
											PreviewImage: `data:image/png;base64,${file.FileData}`,
											PreviewThumbnail: `data:image/png;base64,${file.FileData}`,
											IsFullImage: false,
										});
									}
								}
							})
						);
						return data;
					})
				);
			}
		}

		return of(new PagedResponse<Attachment>());
	}

	onRowEditInit(attachment: Attachment) {
		this.currentAttachment = attachment;

		this.showList = false;
		this.appContext.HasTableBasedData = false;
		this.formMode = FormMode.Edit;
	}

	displayList() {
		this.listEnabled = true;
		this.loadData();
		this.refreshList = false;
		this.showList = true;
		this.appContext.HasTableBasedData = true;
	}

	attachmentUpdated() {
		this.refreshList = true;
	}

	addClick(event: any) {
		this.showList = false;
		this.appContext.HasTableBasedData = false;
		this.formMode = FormMode.Create;
	}

	expandClick(attachment: Attachment) {
		this.showList = false;
		this.appContext.HasTableBasedData = false;
		this.formMode = FormMode.View;
		this.currentAttachment = attachment;
		this.imagesCurrentAttachment = this.images.filter(t => t.AttachmentId === attachment.Details.AttachmentId);
	}

	downloadFile(attachmentFile?: AttachmentFile) {
		this.svc.getAttachmentFileData(attachmentFile.AttachmentFileId).subscribe(response => {
			const fileURL = window.URL.createObjectURL(response);
			this.util.downloadFileUrl(fileURL, attachmentFile.AttachmentFileName);
		});
	}

	downloadAllFiles(attachment: Attachment) {
		attachment.Files.forEach(attachmentFile => {
			this.svc.getAttachmentFileData(attachmentFile.AttachmentFileId).subscribe(response => {
				const fileURL = window.URL.createObjectURL(response);
				this.util.downloadFileUrl(fileURL, attachmentFile.AttachmentFileName);
			});
		});
	}

	isValidImage(file: AttachmentFile): boolean {
		return this.util.isValidImage(file.AttachmentFileName);
	}

	clickEvent(attachmentFile: AttachmentFile) {
		if (attachmentFile.AttachmentFileName.indexOf('.pdf') > 0) {
			this.svc.getAttachmentFileData(attachmentFile.AttachmentFileId).subscribe(response => {
				const file = new File([response], attachmentFile.AttachmentFileName, { type: 'application/pdf' });
				const fileURL = window.URL.createObjectURL(file);
				window.open(fileURL);
			});
		} else {
			this.downloadFile(attachmentFile);
		}
	}

	thumbnailClick(file: AttachmentFile) {
		const index = this.images.findIndex(x => x.AttachmentFileId == file.AttachmentFileId);

		if (index !== -1) {
			const isFullImage = this.images[index].IsFullImage;

			if (isFullImage) {
				this.imagesCurrentAttachment = this.images.filter(t => t.AttachmentId === file.AttachmentId);
				this.activeIndex = this.imagesCurrentAttachment.findIndex(x => x.AttachmentFileId == file.AttachmentFileId);
				this.displayPreview = true;
				this.getAllImagesForAttachment(file.AttachmentId);
			} else {
				this.loaderService.block();
				this.svc.getAttachmentFile(file.AttachmentFileId).subscribe(resp => {
					this.images[index].PreviewImage = `data:image/png;base64,${resp.FileData}`;
					this.images[index].IsFullImage = true;
					this.loaderService.unblock();
					this.imagesCurrentAttachment = this.images.filter(t => t.AttachmentId === file.AttachmentId);
					this.activeIndex = this.imagesCurrentAttachment.findIndex(x => x.AttachmentFileId == file.AttachmentFileId);
					this.displayPreview = true;

					// also go through images array for files belonging to the same AttachmentId and whereever isFullImage is false then get the full image
					// just have this running asynchronously in the background. no need to block or toggle displayPreview

					this.getAllImagesForAttachment(file.AttachmentId);
				});
			}
		}
	}

	getAllImagesForAttachment(attachmentId: string) {
		this.imagesCurrentAttachment
			.filter(t => t.IsFullImage === false)
			.every(img => {
				// check if the user is still viewing this attachment and not another attachment
				if (attachmentId === this.imagesCurrentAttachment[0].AttachmentId) {
					this.svc.getAttachmentFile(img.AttachmentFileId).subscribe(resp => {
						const index = this.images.findIndex(x => x.AttachmentFileId == img.AttachmentFileId);
						if (this.images[index].IsFullImage === false) {
							this.images[index].PreviewImage = `data:image/png;base64,${resp.FileData}`;
							this.images[index].IsFullImage = true;

							const index1 = this.imagesCurrentAttachment.findIndex(x => x.AttachmentFileId == img.AttachmentFileId);
							this.imagesCurrentAttachment[index1].PreviewImage = `data:image/png;base64,${resp.FileData}`;
							this.imagesCurrentAttachment[index1].IsFullImage = true;
						}
						return true;
					});
					return true;
				} else {
					return false;
				}
			});
	}

	showAddItem(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonAttachmentsCreate);
	}
}
