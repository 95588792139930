<p-toolbar *ngIf="showList">
	<ng-template pTemplate="left"> </ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showAddItem()" pTooltip="Add Note" tooltipPosition="bottom">
			<p-button label="Add" [disabled]="currentItem != null" icon="pi pi-plus" (onClick)="addClick()" data-testid="notesTableAddButton"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	*ngIf="showList"
	#notesTable
	dataKey="NoteId"
	[rowHover]="true"
	responsiveLayout="scroll"
	[value]="items"
	[rows]="50"
	editMode="row"
	[totalRecords]="items == null ? 0 : items.length">
	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="ReferenceName" *ngIf="showReferenceColumn">
				<div class="table-header-label">
					Reference

					<p-sortIcon field="ReferenceName"></p-sortIcon>

					<p-columnFilter type="text" field="ReferenceName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Comments">
				<div class="table-header-label" style="width: 10%">
					Comment

					<p-sortIcon field="Comments"></p-sortIcon>

					<p-columnFilter type="text" field="Comments" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="NoteCategoryName">
				<div class="table-header-label">
					Category

					<p-sortIcon field="NoteCategoryName"></p-sortIcon>

					<p-columnFilter type="text" field="NoteCategoryName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="NoteAlertTypeName">
				<div class="table-header-label">
					Alert Type

					<p-sortIcon field="NoteAlertTypeName"></p-sortIcon>

					<p-columnFilter type="text" field="NoteAlertTypeName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateAdded">
				<div class="table-header-label">
					Created

					<p-sortIcon field="DateAdded"></p-sortIcon>

					<p-columnFilter type="date" field="DateAdded" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AddedBy">
				<div class="table-header-label">
					Created by

					<p-sortIcon field="AddedBy"></p-sortIcon>

					<p-columnFilter type="text" field="AddedBy" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th style="width: 3rem" name="inline-edit-buttons"></th>
			<th style="width: 3rem" name="expand-edit-button"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item let-ri="rowIndex">
		<tr class="p-selectable-row" [pEditableRow]="item">
			<td style="width: 10%" *ngIf="showReferenceColumn">
				{{ item.ReferenceName }}
			</td>
			<td style="width: 40%">
				<p-cellEditor>
					<ng-template pTemplate="input">
						<textarea
							pInputTextarea
							cols="60"
							name="comments"
							id="comments"
							style="resize: none"
							[(ngModel)]="item.Comments"
							class="ng-invalid ng-dirty"
							autocomplete="off"
							[ngClass]="{ 'ng-invalid ng-dirty': item.Comments == null || item.Comments == '' }"
							required
							data-testid="notesTableComments"></textarea>
					</ng-template>
					<ng-template pTemplate="output">
						<div pTooltip="{{ item.Comments }}" tooltipPosition="bottom">
							{{ item.Comments | truncate }}
						</div>
					</ng-template>
				</p-cellEditor>
			</td>
			<td style="width: 15%">
				<p-cellEditor>
					<ng-template pTemplate="input">
						<p-autoComplete
							appendTo="body"
							name="noteCategory"
							[suggestions]="filteredNoteCategories"
							[(ngModel)]="selectedNoteCategory"
							[dropdown]="true"
							field="NameValue"
							(onSelect)="noteCategorySelected($event)"
							(onClear)="noteCategorySelected()"
							(completeMethod)="searchNoteCategory($event)"
							[forceSelection]="false"
							id="noteCategory"
							class="ng-invalid ng-dirty"
							[ngClass]="{ 'ng-invalid ng-dirty': item.NoteCategoryCode == null }"
							required
							data-testid="notesTableNoteCategory">
						</p-autoComplete>
					</ng-template>
					<ng-template pTemplate="output">
						{{ item.NoteCategoryName }}
					</ng-template>
				</p-cellEditor>
			</td>
			<td style="width: 15%">
				<p-cellEditor>
					<ng-template pTemplate="input">
						<p-autoComplete
							appendTo="body"
							name="noteAlertType"
							[suggestions]="filteredNoteAlertTypes"
							[(ngModel)]="selectedNoteAlertType"
							[dropdown]="true"
							field="NameValue"
							(onSelect)="noteAlertTypeSelected($event)"
							(onClear)="noteAlertTypeSelected()"
							(completeMethod)="searchNoteAlertType($event)"
							id="noteAlertType"
							[forceSelection]="false">
						</p-autoComplete>
					</ng-template>
					<ng-template pTemplate="output">
						{{ item.NoteAlertTypeName }}
					</ng-template>
				</p-cellEditor>
			</td>
			<td style="width: 10%">
				<span [innerHTML]="item.DateAdded | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td style="width: 10%">
				{{ item.AddedBy }}
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<div *ngIf="showEditItem(item)">
					<button
						data-testid="notesTableEditButton"
						*ngIf="allowEditItem(item) && currentItem == null"
						pButton
						pRipple
						type="button"
						[pTooltip]="getEditItemTooltip(item)"
						tooltipPosition="bottom"
						pInitEditableRow
						icon="pi pi-pencil"
						(click)="onRowEditInit(item)"
						class="p-button-rounded p-button-text"></button>
					<button
						*ngIf="!allowEditItem(item) && currentItem == null"
						pButton
						pRipple
						type="button"
						data-testid="notesTableEditPadLock"
						[pTooltip]="getEditItemTooltip(item)"
						tooltipPosition="bottom"
						icon="pi pi-lock"
						class="p-button-rounded p-button-text"></button>
				</div>
				<button
					[disabled]="isValid() == false"
					*ngIf="item == currentItem"
					pButton
					pRipple
					type="button"
					pTooltip="Save"
					tooltipPosition="bottom"
					pSaveEditableRow
					icon="pi pi-save"
					(click)="onRowEditSave(item)"
					class="p-button-rounded p-button-text p-button-success"
					data-testid="notesTableSaveButton"></button>
				<button
					*ngIf="item == currentItem"
					pButton
					pRipple
					type="button"
					pTooltip="Cancel"
					tooltipPosition="bottom"
					pCancelEditableRow
					icon="pi pi-ban"
					(click)="onRowEditCancel()"
					class="p-button-rounded p-button-text p-button-danger"></button>
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					type="button"
					pButton
					pRipple
					pTooltip="Expand"
					tooltipPosition="bottom"
					(click)="expandClick(item)"
					class="p-button-text p-button-rounded p-button-plain"
					[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
					data-testid="notesTableExpandButton"></button>
			</td>
		</tr>
	</ng-template>
</p-table>
<div *ngIf="showList" class="custom-pagination">
	<span>Showing {{ items == null ? 0 : items.length }} entries</span>
</div>
<app-note-detail
	#noteDetail
	[item]="currentItem"
	[showReferenceColumn]="showReferenceColumn"
	[itemBackup]="currentItemBackup"
	(editCancelled)="editCancelled()"
	(editFinished)="editFinished()"
	(detailsClosed)="detailsClosed()"
	[formMode]="formMode"
	*ngIf="!showList">
</app-note-detail>
