import { COLUMNTYPES } from '../../constants/column.constants';

export class Column {
	order: number;
	header: string;
	field: string;
	type?: COLUMNTYPES;
	isConditional?: boolean;
	hasHeaderSmallLabel?: boolean;
	hasHeaderIcon?: boolean;
	isInnerHtmlFormat?: boolean;
	isSortable?: boolean;
	isFilterable?: boolean;
	sortField?: string | null;
	filterField?: string | null;
	dataTestId?: string | null;
	filterdataTestId?: string | null;
	isFilterCallback?: boolean;
	hasFilterHeaderTemplate?: boolean;
	filterType?: string;
	matchModeOptions?: any[] = [];
	isPrice?: boolean;
	width?: number;
	minWidth?: number;
	maxWidth?: number;
	resizeWidth?: number;
	hideByDefault?: boolean;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
