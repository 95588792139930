export class BulkActionNamesConstants {
	static readonly ActionName_Active: string = 'Active';
	static readonly ActionName_Disable: string = 'Disable';
	static readonly ActionName_Hold: string = 'Hold';
	static readonly ActionName_Enable: string = 'Enable';
	static readonly ActionName_Delete: string = 'Delete';
	static readonly ActionName_Activate: string = 'Activate';
	static readonly ActionName_Retire: string = 'Retire';
	static readonly ActionName_ChangeParent: string = 'Change Parent';
	static readonly ActionName_Print: string = 'Print QR Codes';
	static readonly ActionName_NDIABudgetReport: string = 'NDIA - Budget Report';
	static readonly ActionName_NDIAMonthlyStatement: string = 'NDIA Monthly Statement';
	static readonly ActionName_MarkAsDraft: string = 'Mark as Draft';
	static readonly ActionName_Draft: string = 'Draft';
	static readonly ActionName_Close: string = 'Close';
	static readonly ActionName_Close_WorkOrder: string = 'Close Work Order';
	static readonly ActionName_Close_PurchaseOrder: string = 'Close Purchase Order';
	static readonly ActionName_Close_OrderSchedule: string = 'Close';
	static readonly ActionName_Cancel: string = 'Cancel';
	static readonly ActionName_Cancel_OrderSchedule: string = 'Cancel';
	static readonly ActionName_Cancel_WorkOrder: string = 'Cancel Work Order';
	static readonly ActionName_Cancel_PurchaseOrder: string = 'Cancel Purchase Order';
	static readonly ActionName_Approve: string = 'Approve';
	static readonly ActionName_Paid: string = 'Paid';
	static readonly ActionName_Release: string = 'Release';
	static readonly ActionName_Void: string = 'Void';
	static readonly ActionName_CreateCreditNote: string = 'Create Credit Note';
	static readonly ActionName_Send: string = 'Send';
	static readonly ActionName_Created: string = 'Created';
	static readonly ActionName_Allocated: string = 'Allocated';
	static readonly ActionName_InProgress: string = 'In Progress';
	static readonly ActionName_DataEntryRequired: string = 'Data Entry Required';
	static readonly ActionName_ReviewRequired: string = 'Review Required';
	static readonly ActionName_ReviewApproved: string = 'Reviewed & Approved';
	static readonly ActionName_Pause: string = 'Pause';
	static readonly ActionName_SendChanges: string = 'Send Changes';
	static readonly ActionName_Amend: string = 'Amend';
	static readonly ActionName_Accept: string = 'Accept';
	static readonly ActionName_Reject: string = 'Reject';
	static readonly ActionName_SubmitRequest: string = 'Submit Request';
	static readonly ActionName_Sent: string = 'Sent';
	static readonly ActionName_Received: string = 'Received';
	static readonly ActionName_Unsuccessful: string = 'Unsuccessful';
	static readonly ActionName_Resend: string = 'Resend';
	static readonly ActionName_Recreate: string = 'Refresh and Resend';
	static readonly ActionName_Reprocess: string = 'Reprocess';
	static readonly ActionName_SendInvitation: string = 'Send Invitation';
}
