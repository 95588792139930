<p-calendar
	[defaultDate]="defaultDateBasedOnTimeZone"
	[(ngModel)]="value"
	[appendTo]="appendTo"
	[dateFormat]="dateFormat"
	[showTime]="showTime"
	[showIcon]="showIcon"
	[dateFormat]="dateFormat"
	[minDate]="minDate"
	[maxDate]="maxDate"
	[hourFormat]="hourFormat"
	[name]="name"
	(onFocus)="onCalendarFocus($event)"
	(onBlur)="onCalendarBlur($event)"
	(onSelect)="onCalendarSelect($event)"
	(onInput)="onCalendarInput($event)"
	class="ng-invalid ng-dirty"
	[ngClass]="{ 'ng-invalid ng-dirty': required && value == null }"></p-calendar>
