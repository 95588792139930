import { Component } from '@angular/core';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { AppContextService } from 'src/app/core/services';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
	constructor(private appContext: AppContextService) {
		this.appContext.ViewTitle = 'Page Not Found';
		this.appContext.BasePageCSSClass.set(Constants.BasePageCSSClass.Default);
	}
}
