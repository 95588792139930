import { Injectable } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogParams } from '../models';

@Injectable({
	providedIn: 'root',
})
export class DynamicDialogService implements OnDestroy {
	ref: DynamicDialogRef | undefined;
	constructor(private dialogService: DialogService) {}

	open(params: DynamicDialogParams) {
		this.ref = this.dialogService.open(params.component, {
			header: params.header,
			width: params.width || '30vw',
			contentStyle: params.contentStyle || { overflow: 'hidden' },
			styleClass: params.styleClass,
			baseZIndex: 10000,
			maximizable: params.maximizable ?? false,
			height: params.height || 'auto',
			data: params,
			closeOnEscape: params.closeOnEscape ?? true,
			closable: true, // required for close on escape to work
		});

		return this.ref.onClose;
	}

	ngOnDestroy() {
		if (this.ref) {
			this.ref.close();
		}
	}
}
