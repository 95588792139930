import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageDialogComponent } from 'src/app/shared/components/bulk-action/message-dialog/message-dialog.component';
import { ActionResult, ObjectAccessReason } from 'src/app/shared/models/api';
import { BulkActionBaseService } from 'src/app/shared/services/bulk-action.base.service';
import { ttPermissionType } from 'src/app/core/constants/tooltip-messages.constant';
import { AppContextService, DynamicDialogService, TooltipMessageService } from 'src/app/core/services';
import { PurchaseOrderConstants } from '../constants';
import { PurchaseOrderDetails } from '../models';
import { PurchaseOrderService } from './purchase-order.service';

@Injectable({
	providedIn: 'root',
})
export class PurchaseOrderBulkActionService extends BulkActionBaseService {
	constructor(
		public appContext: AppContextService,
		private purchaseOrderService: PurchaseOrderService,
		private tooltipMessageService: TooltipMessageService,
		private dialogService: DynamicDialogService
	) {
		super(appContext);
	}

	//TODO: Check if it should be WorkOrderId or PurchaseOrderId
	getBulkActionObjectPrimaryKeyName(): string {
		return 'PurchaseOrderId';
	}

	setFilterActionOptions() {
		this.filterActions = [];

		PurchaseOrderConstants.FilterActionsList.forEach(option => {
			this.filterActions.push(this.addFilterOption(option, option));
		});
	}

	processApiRequest(actionName: string, sel: any, args: any): Observable<ActionResult<any>> {
		switch (actionName) {
			case PurchaseOrderConstants.ActionName_Created:
				return this.purchaseOrderService.markPurchaseOrderAsCreated(sel.PurchaseOrderId);
			case PurchaseOrderConstants.ActionName_Allocated:
				return this.purchaseOrderService.markPurchaseOrderAsAllocated(sel.PurchaseOrderId);
			case PurchaseOrderConstants.ActionName_InProgress:
				return this.purchaseOrderService.markPurchaseOrderAsInProgress(sel.PurchaseOrderId);
			case PurchaseOrderConstants.ActionName_DataEntryRequired:
				return this.purchaseOrderService.markPurchaseOrderAsDataEntryRequired(sel.PurchaseOrderId);
			case PurchaseOrderConstants.ActionName_ReviewRequired:
				return this.purchaseOrderService.markPurchaseOrderAsReviewRequired(sel.PurchaseOrderId);
			case PurchaseOrderConstants.ActionName_ReviewApproved:
				return this.purchaseOrderService.markPurchaseOrderAsReviewedApproved(sel.PurchaseOrderId);
			case PurchaseOrderConstants.ActionName_Pause:
				return this.purchaseOrderService.pausePurchaseOrder(sel.PurchaseOrderId);
			case PurchaseOrderConstants.ActionName_Cancel:
				return this.purchaseOrderService.cancelPurchaseOrder(sel.PurchaseOrderId);
			default:
				return null;
		}
	}

	refreshUpdatedValues(resp: ActionResult<any>) {
		const updatedDetails: PurchaseOrderDetails = resp.Data;
		const filteredDetails: PurchaseOrderDetails = this.filteredSelectedItems.find(x => x.WorkOrderId == updatedDetails.WorkOrderId);
		const selDetails: PurchaseOrderDetails = this.selectedItems.find(x => x.WorkOrderId == updatedDetails.WorkOrderId);
		if (filteredDetails && selDetails) {
			filteredDetails.StatusCode = updatedDetails.StatusCode;
			filteredDetails.StatusName = updatedDetails.StatusName;
			filteredDetails.ObjectAccess = updatedDetails.ObjectAccess;

			selDetails.StatusCode = updatedDetails.StatusCode;
			selDetails.StatusName = updatedDetails.StatusName;
			selDetails.ObjectAccess = updatedDetails.ObjectAccess;
		}
		return;
	}

	showBulkAction(item: PurchaseOrderDetails): boolean {
		if (!this.filterActionSelected) return false;
		const actionName = this.filterActionSelected?.NameValue ?? (this.filterActionSelected as unknown as string);

		const access = this.getActionAccess(item, actionName);
		return access?.IsAllowed;
	}

	allowBulkAction(item: PurchaseOrderDetails): boolean {
		if (!this.filterActionSelected) return false;
		const actionName = this.filterActionSelected?.NameValue ?? (this.filterActionSelected as unknown as string);

		const access = this.getActionAccess(item, actionName);
		return access?.IsAllowed;
	}

	private getActionAccess(item: PurchaseOrderDetails, actionName: string): ObjectAccessReason {
		const actionAccessName = PurchaseOrderConstants.ActionPermissionNames.find(ap => ap.action == actionName)?.permission;
		if (actionAccessName) return item?.ObjectAccess?.[actionAccessName];

		return null;
	}

	getPadlockTooltip(item: PurchaseOrderDetails): string {
		if (!this.filterActionSelected) return '';
		const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;

		const actionAccessName = PurchaseOrderConstants.ActionPermissionNames.find(ap => ap.action == actionName)?.permission;
		return this.tooltipMessageService.getTooltipMessage('PURCHASE_ORDER', actionAccessName as ttPermissionType, item?.ObjectAccess?.[actionAccessName], item, {
			actionName: actionName,
		} as any);
	}

	showErrorDialog(item: PurchaseOrderDetails) {
		const selItem = this.bulkActionResults.find(x => x.Details.WorkOrderId == item.WorkOrderId);
		if (!selItem) return;

		this.messageObject = selItem;
		this.dialogService.open({
			component: MessageDialogComponent,
			header: PurchaseOrderConstants.MessageDialog_Header_Error,
			styleClass: 'error-message-dialog-custom',
			contentStyle: { overflow: 'auto' },
			data: {
				title: PurchaseOrderConstants.MessageDialog_Title_Error,
				icon: PurchaseOrderConstants.Message_Title_Icon_Error,
				messageObject: this.messageObject,
				ObjectName: PurchaseOrderConstants.BulkAction_Object_Name,
				ObjectPropertyName_Number: PurchaseOrderConstants.BulkAction_Object_PropertyName_Number,
			},
		});
	}

	actionIcons(actionName): string {
		return PurchaseOrderConstants.ActionIconsNames.find(ai => ai.action == actionName)?.icon || '';
	}

	handleApplyClick(event: any) {
		if (this.isBulkActionView) {
			const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;
			let confirmationMessage;

			if (actionName == PurchaseOrderConstants.ActionName_Cancel) {
				confirmationMessage = PurchaseOrderConstants.BulkAction_Confirmation_Message_Generic_PauseOrCancel(
					this.filteredSelectedItems.length.toString(),
					actionName.toString()
				);
			} else if (actionName == PurchaseOrderConstants.ActionName_Pause) {
				confirmationMessage = PurchaseOrderConstants.BulkAction_Confirmation_Message_Generic_PauseOrCancel(
					this.filteredSelectedItems.length.toString(),
					actionName.toString()
				);
			} else {
				confirmationMessage = PurchaseOrderConstants.BulkAction_Confirmation_Message_Generic(
					this.filteredSelectedItems.length.toString(),
					actionName.toString()
				);
			}

			const confirmationHeader = PurchaseOrderConstants.Confirmation_Header_Generic(actionName.toString());
			const confirmationIcon = this.actionIcons(actionName);

			this.util.clearMessages();
			this.util.confirmSaveCustomParams(confirmationMessage, confirmationHeader, confirmationIcon).then(result => {
				if (result) {
					this.startBulkAction(null);
					return true;
				}
			});
		}
	}

	//This is done to maintain UI checkbox select all checked
	onSelectionChange(items: any[]) {
		this.filteredSelectedItems = [];
		for (let i = items.length - 1; i >= 0; i--) {
			const item = items[i];
			if (this.allowBulkAction(item)) {
				this.filteredSelectedItems.push(item);
			}
		}
	}

	errorDialogClosed() {
		this.showMessage = false;
	}
}
