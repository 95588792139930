import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult, LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { ApiService } from 'src/app/core/services';
import { UserProfile, UserProfileList } from '../models';

@Injectable({
	providedIn: 'root',
})
export class UserProfileApiService {
	constructor(private apiService: ApiService) {}

	getPagedListForInternalUsers(searchData: LazyLoadArgs): Observable<PagedResponse<UserProfileList>> {
		return this.apiService.post('/UserProfile/GetPagedListForInternalUsers', searchData) as Observable<PagedResponse<UserProfileList>>;
	}

	getPagedListForExternalUsers(searchData: LazyLoadArgs): Observable<PagedResponse<UserProfileList>> {
		return this.apiService.post('/UserProfile/GetPagedListForExternalUsers', searchData) as Observable<PagedResponse<UserProfileList>>;
	}

	getUserProfile(userProfileId: string): Observable<UserProfile> {
		const httpParams = new HttpParams().append('userProfileId', userProfileId);
		return this.apiService.get<UserProfile>('/UserProfile/GetUserProfile', httpParams);
	}

	getUserProfileForCurrentUser(): Observable<UserProfile> {
		return this.apiService.get<UserProfile>('/UserProfile/GetUserProfileForCurrentUser');
	}

	inviteNewUser(user: UserProfile): Observable<ActionResult> {
		return this.apiService.post('/UserProfile/InviteNewUser', user) as Observable<ActionResult>;
	}

	sendInvitation(userId: string): Observable<ActionResult> {
		return this.apiService.post('/UserProfile/SendInvitation', userId) as Observable<ActionResult>;
	}

	saveUserProfile(user: UserProfile): Observable<ActionResult> {
		if (user.IsCurrentUser) return this.apiService.post('/UserProfile/SaveCurrentProfile', user) as Observable<ActionResult>;
		else return this.apiService.post('/UserProfile/SaveUserProfile', user) as Observable<ActionResult>;
	}

	disableUser(userId: string): Observable<ActionResult> {
		return this.apiService.post('/UserProfile/DisableUser', userId) as Observable<ActionResult>;
	}

	activateUser(userId: string): Observable<ActionResult> {
		return this.apiService.post('/UserProfile/ActivateUser', userId) as Observable<ActionResult>;
	}
}
