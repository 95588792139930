import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppMenuConstants } from 'src/app/layout/sidebar/Constants/app.menu.constants';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { AppContextService, LoaderService, UtilService } from '.';
import { NavigationConstants } from '../constants/navigation.constants';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor(private loaderService: LoaderService, private router: Router, private util: UtilService, private app: AppContextService) {
		//
	}

	navigateToCurrentUserProfile() {
		this.router.navigate(['/profile']);
	}

	navigateToCurrentSubscriptions() {
		this.router.navigate(['/subscription']);
	}

	navigateToAccessDenied() {
		this.router.navigate(['/access-denied']);
	}

	// #region -- Records --

	private isPurchaseOrderRecordType(recordTypeCode: string): boolean {
		return (
			recordTypeCode == Constants.RecordType.PurchaseOrder ||
			recordTypeCode == Constants.RecordType.Defect ||
			recordTypeCode == Constants.RecordType.CreditPurchaseOrder ||
			recordTypeCode == Constants.RecordType.RecoveryPurchaseOrder
		);
	}

	navigateToRecord(recordTypeCode: string, recordId: string, parentRecordId: string = null, isBackToParent = false): boolean {
		if (this.isPurchaseOrderRecordType(recordTypeCode)) {
			recordTypeCode = Constants.RecordType.WorkOrder;
			recordId = parentRecordId;
		}

		const module = NavigationConstants.RecordModules.find(x => x.recordTypeCode == recordTypeCode);
		if (module == null) {
			return false;
		}

		if (!this.app.hasPermission(module.permission)) {
			this.util.clearMessages();
			this.util.showError(NavigationConstants.MESSAGE_MISSINGPERMISSION);
			return false;
		}

		try {
			// TO-DO Find alternative for same page reload
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		} catch (err) {
			console.log(err);
		}

		this.loaderService.block();
		if (isBackToParent) {
			this.router.navigate([`/${module.path}/`, recordId, isBackToParent]);
		} else {
			this.router.navigate([`/${module.path}/`, recordId]);
		}
		return true;
	}

	getRecordIcon(recordTypeCode: string): string {
		return NavigationConstants.RecordModules.find(x => x.recordTypeCode == recordTypeCode)?.icon;
	}

	// #endregion

	// #region -- References --

	navigateToReference(referenceTypeCode: string, referenceId: string): boolean {
		const module = NavigationConstants.ReferenceModules.find(x => x.referenceTypeCode == referenceTypeCode);
		if (module == null) {
			return false;
		}

		if (!this.app.hasPermission(module.permission)) {
			this.util.clearMessages();
			this.util.showError(NavigationConstants.MESSAGE_MISSINGPERMISSION);
			return false;
		}

		try {
			// TO-DO Find alternative for same page reload
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		} catch (err) {
			console.log(err);
		}

		this.loaderService.block();
		this.router.navigate([`/${module.path}/`, referenceId]);
		return true;
	}

	getReferenceIcon(referenceTypeCode: string): string {
		return NavigationConstants.ReferenceModules.find(x => x.referenceTypeCode == referenceTypeCode)?.icon;
	}

	// #endregion

	// #region -- Menu Links --

	getMenuLinkNavigation(linkName: string) {
		switch (linkName) {
			case AppMenuConstants.MenuLinks.DashBoard:
				return '/dashboard';
			case AppMenuConstants.MenuLinks.ServiceRequests:
				return '/service-request';
			case AppMenuConstants.MenuLinks.Scopes:
				return '/scope';
			case AppMenuConstants.MenuLinks.Quotes:
				return '/quote';
			case AppMenuConstants.MenuLinks.WorkOrders:
				return '/work-order';
			case AppMenuConstants.MenuLinks.PurchaseOrders:
				return '/purchase-order';
			case AppMenuConstants.MenuLinks.Receivables:
				return '/invoice/receivable';
			case AppMenuConstants.MenuLinks.Payables:
				return '/invoice/payable';
			case AppMenuConstants.MenuLinks.Assets:
				return '/asset';
			case AppMenuConstants.MenuLinks.Properties:
				return '/property';
			case AppMenuConstants.MenuLinks.Reports:
				return '/report';
			case AppMenuConstants.MenuLinks.Calendar:
				return '/calendar';
			case AppMenuConstants.MenuLinks.Clients:
				return '/contact/client';
			case AppMenuConstants.MenuLinks.Contractors:
				return '/contact/contractor';
			case AppMenuConstants.MenuLinks.Employees:
				return '/contact/employee';
			case AppMenuConstants.MenuLinks.Suppliers:
				return '/contact/supplier';
			case AppMenuConstants.MenuLinks.RateGroups:
				return '/rate/rate-group';
			case AppMenuConstants.MenuLinks.Items:
				return '/rate/item';
			case AppMenuConstants.MenuLinks.Problems:
				return '/rate/problem';
			case AppMenuConstants.MenuLinks.Companies:
				return '/setting/company';
			case AppMenuConstants.MenuLinks.Areas:
				return '/setting/area';
			case AppMenuConstants.MenuLinks.Projects:
				return '/setting/project';
			case AppMenuConstants.MenuLinks.Fields:
				return '/setting/field';
			case AppMenuConstants.MenuLinks.IncomingEvents:
				return '/system-interface/incoming-event';
			case AppMenuConstants.MenuLinks.OutgoingEvents:
				return '/system-interface/outgoing-event';
			case AppMenuConstants.MenuLinks.IntegrationModules:
				return '/system-interface/integration-module';
			case AppMenuConstants.MenuLinks.SubscriberSettings:
				return '/account-admin/setting';
			case AppMenuConstants.MenuLinks.Users:
				return '/account-admin/user';
			case AppMenuConstants.MenuLinks.ExternalUsers:
				return '/account-admin/external-user';
			case AppMenuConstants.MenuLinks.UserRoles:
				return '/account-admin/user-role';
			case AppMenuConstants.MenuLinks.DevTools:
				return '/dev-tool';
			case AppMenuConstants.MenuLinks.OrderSchedules:
				return '/schedule/order-schedule';
			default:
				return '/dashboard';
		}
	}

	navigateToMenuLinks(linkName: string) {
		this.router.navigate([this.getMenuLinkNavigation(linkName)]);
	}

	navigateToCreateNewMenuLink(linkName: string) {
		this.router.navigate([this.getMenuLinkNavigation(linkName), 'new']);
	}

	navigateToInvoiceCreateNewMenuLink(linkName: string, isReceivable: boolean) {
		this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', isReceivable]);
	}

	navigateToDetailPage(linkName: string, recordId: string) {
		this.router.navigate([this.getMenuLinkNavigation(linkName), recordId]);
	}

	navigateToWorkActivityCreateNewMenuLinkUsingFlowTo(linkName: string, refType: string, flowToId: string) {
		switch (refType) {
			case Constants.ReferenceType.Asset:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'a', flowToId]);
				break;
			case Constants.ReferenceType.QuoteRequest:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'q', flowToId]);
				break;
			case Constants.ReferenceType.ServiceRequest:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'srq', flowToId]);
				break;
			case Constants.ReferenceType.Scope:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 's', flowToId]);
				break;
			case Constants.ReferenceType.WorkOrder:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'o', flowToId]);
				break;
			case Constants.ReferenceType.OrderSchedule:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'os', flowToId]);
				break;
			default:
				break;
		}
	}

	navigateFromWorkActivityToInvoiceCreateNewMenuLink(linkName: string, refType: string, workActivityId: string) {
		let isReceivable = true;
		if (linkName == AppMenuConstants.MenuLinks.Payables) {
			isReceivable = false;
		}
		switch (refType) {
			case Constants.ReferenceType.PurchaseOrder:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'p', isReceivable, workActivityId]);
				break;
			case Constants.ReferenceType.QuoteRequest:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'q', isReceivable, workActivityId]);
				break;
			case Constants.ReferenceType.ServiceRequest:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'srq', isReceivable, workActivityId]);
				break;
			case Constants.ReferenceType.Scope:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 's', isReceivable, workActivityId]);
				break;
			case Constants.ReferenceType.WorkOrder:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'o', isReceivable, workActivityId]);
				break;
			case Constants.ReferenceType.OrderSchedule:
				this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'os', isReceivable, workActivityId]);
				break;
			default:
				break;
		}
	}

	// #endregion

	//#region --Specific Navigation --

	navigateFromInvoiceToCreateNewCreditNote(linkName: string, invoiceId: string) {
		if (linkName == AppMenuConstants.MenuLinks.Receivables) {
			this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'i', true, invoiceId]);
		} else this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'i', false, invoiceId]);
	}

	navigateFromRateGroupToCreateNewItem(linkName: string, rateGroupId: string) {
		this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'rg', rateGroupId]);
	}

	//Request points to Problems
	navigateFromRateGroupToCreateNewRequest(linkName: string, rateGroupId: string) {
		this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'rg', rateGroupId]);
	}

	navigateToCalendarViewPlanFromRecord(linkName: string, referenceTypeCode: string, recordId: string) {
		this.router.navigate([this.getMenuLinkNavigation(linkName), referenceTypeCode, recordId]);
	}

	navigateFromItemToCreateNewRequest(linkName: string, itemId: string) {
		this.router.navigate([this.getMenuLinkNavigation(linkName), 'new', 'i', itemId]);
	}

	//#endregion
}
