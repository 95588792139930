import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { FormMode, FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { Contact } from 'src/app/shared/models/shared';
import { ContactService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, LoaderService } from 'src/app/core/services';
import { LookupService } from 'src/app/core/services';
import { CustomValidators } from 'src/app/core/utils';

@Component({
	selector: 'app-contact-details',
	templateUrl: './contact-details.component.html',
})
export class ContactDetailsComponent extends BaseComponent implements OnInit {
	@Input() item: Contact = new Contact();
	@Input() contactTypeLabel = 'Contact Type';
	@Input() contactTypes: LookupValue[] = [];
	@Input() languages: LookupValue[] = [];

	@Output() editFinished = new EventEmitter();
	@Output() editCancelled = new EventEmitter();

	formModes = FormMode;
	formControls = FrmCntl;
	initialValues: any;

	contactForm = this.fb.group({
		description: [''],
		contactType: [null, [Validators.required]],
		contactId: [''],
		isPrimary: [false],
		referenceTypeCode: [''],
		referenceId: [''],
		referenceName: [''],
		name: ['', [Validators.required]],
		phoneNumber: ['', [CustomValidators.phone]],
		mobileNumber: ['', [CustomValidators.phone]],
		emailAddress: ['', [CustomValidators.email]],
		language: [null],
		accessDetails: [''],
		dateActiveFrom: [''],
		dateActiveTo: [''],
		addedBy: [''],
		updatedBy: [''],
		dateAdded: [''],
		dateUpdated: [''],
		dateRemoved: [''],
	});

	constructor(
		appContext: AppContextService,
		private contactService: ContactService,
		private lookupService: LookupService,
		private fb: UntypedFormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private loaderService: LoaderService
	) {
		super(appContext);
	}

	ngOnInit(): void {
		this.util.addRecordValueIntoLookup(this.item.ContactTypeCode, this.item.ContactTypeName, this.contactTypes);
		this.util.addRecordValueIntoLookup(this.item.LanguageCode, this.item.LanguageName, this.languages);

		if (this.item.ContactId) {
			this.formMode = this.formModes.View;

			this.contactPatchValues();
			this.initialValues = this.contactForm.value;
		} else {
			this.formMode = this.formModes.Create;
			this.markGroupDirty(this.contactForm);
		}
	}

	contactPatchValues() {
		this.contactForm.patchValue({
			description: this.item.Description,
			contactType: this.contactTypes.find(x => x.CodeValue === this.item.ContactTypeCode),
			contactId: this.item.ContactId,
			isPrimary: this.item.IsPrimary,
			referenceTypeCode: this.item.ReferenceTypeCode,
			referenceId: this.item.ReferenceId,
			referenceName: this.item.ReferenceName,
			name: this.item.Name,
			phoneNumber: this.item.PhoneNumber,
			mobileNumber: this.item.MobileNumber,
			emailAddress: this.item.EmailAddress,
			accessDetails: this.item.AccessDetails,
			language: this.languages.find(x => x.CodeValue === this.item.LanguageCode),
			dateActiveFrom: this.item.DateActiveFrom,
			dateActiveTo: this.item.DateActiveTo,
			addedBy: this.item.AddedBy,
			updatedBy: this.item.UpdatedBy,
			dateAdded: this.item.DateAdded,
			dateUpdated: this.item.DateUpdated,
			dateRemoved: this.item.DateRemoved,
		});

		this.markGroupDirty(this.contactForm);
	}

	editItem() {
		this.initialValues = this.contactForm.value;
		this.formMode = this.formModes.Edit;
	}

	cancelItemEdit() {
		this.util.clearMessages();

		if (this.formMode === this.formModes.Create) {
			this.editCancelled.emit();
		} else {
			this.formMode = this.formModes.View;
			this.contactForm.reset(this.initialValues);
		}
	}

	backToList() {
		this.editFinished.emit();
	}

	deleteItem() {
		this.util.confirmDelete('Do you want to delete this contact?').then(result => {
			if (result) {
				this.util.clearMessages();
				this.loaderService.block();
				this.contactService.disableContact(this.item.ContactId).subscribe(result => {
					this.loaderService.unblock();
					if (result.IsSuccess) {
						this.editFinished.emit();
					}
				});
			}
		});
	}

	saveItem() {
		this.util.clearMessages();
		this.appContext.loaderService.block();

		if (this.contactForm.valid) {
			this.item.IsPrimary = this.contactForm.value.isPrimary;
			this.item.Description = this.contactForm.value.description;
			this.item.Name = this.contactForm.value.name;
			this.item.PhoneNumber = this.contactForm.value.phoneNumber;
			this.item.MobileNumber = this.contactForm.value.mobileNumber;
			this.item.EmailAddress = this.contactForm.value.emailAddress;
			this.item.AccessDetails = this.contactForm.value.accessDetails;
			this.item.DateActiveFrom = this.contactForm.value.dateActiveFrom;
			this.item.DateActiveTo = this.contactForm.value.dateActiveTo;

			if (this.contactForm.value.contactType) {
				this.item.ContactTypeCode = this.contactForm.value.contactType.CodeValue;
				this.item.ContactTypeName = this.contactForm.value.contactType.NameValue;
			}

			if (this.contactForm.value.language) {
				this.item.LanguageCode = this.contactForm.value.language.CodeValue;
				this.item.LanguageName = this.contactForm.value.language.NameValue;
			}

			this.contactService.saveContact(this.item).subscribe(result => {
				if (result.IsSuccess) {
					this.item = this.util.deepClone(result.Data);

					if (this.formMode === FormMode.Create) {
						this.contactForm.patchValue({
							contactId: result.Data.ContactId,
							addedBy: result.Data.AddedBy,
							dateAdded: result.Data.DateAdded,
						});
					} else if (this.formMode === FormMode.Edit) {
						this.contactForm.patchValue({
							updatedBy: result.Data.UpdatedBy,
							dateUpdated: result.Data.DateUpdated,
						});
					}

					this.formMode = this.formModes.View;
				}
				this.appContext.loaderService.unblock();
			});
		} else {
			this.appContext.loaderService.unblock();
			this.markGroupDirty(this.contactForm);
		}
	}

	showEdit(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonContactsUpdate);
	}

	showDelete(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonContactsRemove);
	}
}
