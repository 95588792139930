import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { RegionSettings } from 'src/app/shared/models/settings';
import { ScheduleOrderHistory } from 'src/app/shared/models/shared/schedule-order-history.model';
import { SearchService } from 'src/app/shared/services';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService, NavigationService, UtilService } from 'src/app/core/services';
import { LazyLoadArgs, PagedResponse } from '../../../models/api';

@Component({
	selector: 'app-related-work-orders',
	templateUrl: './related-work-orders.component.html',
	styleUrls: ['./related-work-orders.component.scss'],
})
export class RelatedWorkOrdersComponent extends DataTableBaseComponent<ScheduleOrderHistory> implements OnInit {
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;

	totalRecords = 0;
	showList = true;
	refreshRequired = false;
	timeZoneCode: string;
	isClientUser: boolean;
	region: RegionSettings;
	public util: UtilService;
	refName: string;

	constructor(
		appContext: AppContextService,
		globals: Globals,
		config: PrimeNGConfig,
		private searchService: SearchService,
		private navigationService: NavigationService,
		private changeDetector: ChangeDetectorRef
	) {
		super(globals, appContext, config);
	}

	ngOnInit(): void {
		this.region = this.appContext.getRegion();
	}

	lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<ScheduleOrderHistory>> {
		this.changeDetector.detectChanges();
		return this.searchService.getPagedScheduleWorkOrderHistoryListForOrderSchedule(event, this.referenceId);
	}

	public refreshData(): void {
		this.loadData();
	}

	canNavigateToDetails(dto: ScheduleOrderHistory) {
		return this.getNavigationRoute(dto) != null;
	}

	handleRowClick(dto: ScheduleOrderHistory) {
		this.navigationService.navigateToRecord(dto.RecordTypeCode, dto.RecordId);
	}

	showLink(): boolean {
		return !this.isClientUser;
	}

	getRowTypeName(dto: ScheduleOrderHistory) {
		return dto.RecordTypeCode ? this.getRecordTypeName(dto.RecordTypeCode) : null;
	}

	getNavigationRoute(dto: ScheduleOrderHistory) {
		if (dto.RecordTypeCode != null && dto.RecordId != null) {
			return true;
		}
		return null;
	}

	getRecordTypeName(recordTypeCode: string) {
		return Constants.RecordType.Values.filter(x => x.CodeValue == recordTypeCode)?.[0].NameValue;
	}
}
