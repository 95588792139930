import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult, LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { ExportOptions } from 'src/app/shared/models/ui';
import { ApiService } from 'src/app/core/services';
import { PurchaseOrderDetails, PurchaseOrderItem, PurchaseOrderList } from '../models';

@Injectable({
	providedIn: 'root',
})
export class PurchaseOrderService {
	constructor(private apiService: ApiService) {}

	getPagedList(searchData: LazyLoadArgs): Observable<PagedResponse<PurchaseOrderList>> {
		return this.apiService.post('/PurchaseOrder/GetPagedList', searchData) as Observable<PagedResponse<PurchaseOrderList>>;
	}

	getPagedListExcelFile(exportOptions: ExportOptions): Observable<ActionResult> {
		return this.apiService.post('/PurchaseOrder/GetPagedListExcelFile', exportOptions) as Observable<ActionResult>;
	}

	getPurchaseOrderDetailsForWorkOrder(workOrderId: string): Observable<PurchaseOrderDetails[]> {
		return this.apiService.get<PurchaseOrderDetails[]>(`/PurchaseOrder/GetPurchaseOrderDetailsForWorkOrder/${workOrderId}`);
	}

	savePurchaseOrderDetails(item: PurchaseOrderDetails) {
		return this.apiService.post<ActionResult>(`/PurchaseOrder/SavePurchaseOrderDetails`, item);
	}
	getPurchaseOrderItemsForPurchaseOrder(purchaseOrderId: string): Observable<PurchaseOrderItem[]> {
		return this.apiService.get<PurchaseOrderItem[]>(`/PurchaseOrder/GetPurchaseOrderItemsForPurchaseOrder/${purchaseOrderId}`);
	}

	validateNewInvoiceCreationForPurchaseOrder(purchaseOrderId: string, isReceiveable: boolean) {
		return this.apiService.get<ActionResult>(`/Invoice/ValidateNewInvoiceCreationForPurchaseOrder/${purchaseOrderId}/${isReceiveable}`);
	}

	cancelPurchaseOrder(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/CancelPurchaseOrder/${purchaseOrderId}/false`);
	}

	pausePurchaseOrder(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/PausePurchaseOrder/${purchaseOrderId}/false`);
	}

	markPurchaseOrderAsCreated(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/MarkPurchaseOrderAsCreated/${purchaseOrderId}/false`);
	}

	markPurchaseOrderAsAllocated(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/MarkPurchaseOrderAsAllocated/${purchaseOrderId}/false`);
	}

	markPurchaseOrderAsInProgress(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/MarkPurchaseOrderAsInProgress/${purchaseOrderId}/false`);
	}

	markPurchaseOrderAsDataEntryRequired(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/MarkPurchaseOrderAsDataEntryRequired/${purchaseOrderId}/false`);
	}

	markPurchaseOrderAsReviewRequired(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/MarkPurchaseOrderAsReviewRequired/${purchaseOrderId}/false`);
	}

	markPurchaseOrderAsReviewedApproved(purchaseOrderId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/PurchaseOrder/MarkPurchaseOrderAsReviewedApproved/${purchaseOrderId}/false`);
	}

	getPurchaseOrderDetails(purchaseOrderId: string): Observable<PurchaseOrderDetails> {
		return this.apiService.get<PurchaseOrderDetails>(`/PurchaseOrder/GetPurchaseOrderDetails/${purchaseOrderId}`);
	}
}
