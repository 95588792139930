import { COLUMNTYPES } from 'src/app/shared/constants/column.constants';
import { Column } from 'src/app/shared/models/shared/column';

export class PurchaseOrderConstants {
	//Action Names
	static readonly ActionName_Created: string = 'Created';
	static readonly ActionName_Allocated: string = 'Allocated';
	static readonly ActionName_InProgress: string = 'In Progress';
	static readonly ActionName_DataEntryRequired: string = 'Data Entry Required';
	static readonly ActionName_ReviewRequired: string = 'Review Required';
	static readonly ActionName_ReviewApproved: string = 'Reviewed & Approved';
	static readonly ActionName_Pause: string = 'Pause';
	static readonly ActionName_Cancel: string = 'Cancel';

	//Icon Class Names
	static readonly Confirmation_Title_Icon_Created: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_Allocated: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_InProgress: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_DataEntryRequired: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_ReviewRequired: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_ReviewAndApproved: string = 'fas fa-check';
	static readonly Confirmation_Title_Icon_Pause: string = 'pi pi-pause-circle';
	static readonly Confirmation_Title_Icon_Cancel: string = 'pi pi-ban';

	//BulkAction ActionPermissionNames
	static readonly ActionPermissionNames = [
		{ action: PurchaseOrderConstants.ActionName_Created, permission: 'AllowCreated' },
		{ action: PurchaseOrderConstants.ActionName_Allocated, permission: 'AllowAllocated' },
		{ action: PurchaseOrderConstants.ActionName_InProgress, permission: 'AllowInProgress' },
		{ action: PurchaseOrderConstants.ActionName_DataEntryRequired, permission: 'AllowDataEntryRequired' },
		{ action: PurchaseOrderConstants.ActionName_ReviewRequired, permission: 'AllowReviewRequired' },
		{ action: PurchaseOrderConstants.ActionName_ReviewApproved, permission: 'AllowReviewApproved' },
		{ action: PurchaseOrderConstants.ActionName_Pause, permission: 'AllowPaused' },
		{ action: PurchaseOrderConstants.ActionName_Cancel, permission: 'AllowRemove' },
	];

	//FilterActions
	static readonly FilterActionsList = [
		PurchaseOrderConstants.ActionName_Created,
		PurchaseOrderConstants.ActionName_Allocated,
		PurchaseOrderConstants.ActionName_InProgress,
		PurchaseOrderConstants.ActionName_DataEntryRequired,
		PurchaseOrderConstants.ActionName_ReviewRequired,
		PurchaseOrderConstants.ActionName_ReviewApproved,
		PurchaseOrderConstants.ActionName_Pause,
		PurchaseOrderConstants.ActionName_Cancel,
	];

	//BulkAction ActionIcons
	static readonly ActionIconsNames = [
		{ action: PurchaseOrderConstants.ActionName_Created, icon: PurchaseOrderConstants.Confirmation_Title_Icon_Created },
		{ action: PurchaseOrderConstants.ActionName_Allocated, icon: PurchaseOrderConstants.Confirmation_Title_Icon_Allocated },
		{ action: PurchaseOrderConstants.ActionName_InProgress, icon: PurchaseOrderConstants.Confirmation_Title_Icon_InProgress },
		{ action: PurchaseOrderConstants.ActionName_DataEntryRequired, icon: PurchaseOrderConstants.Confirmation_Title_Icon_DataEntryRequired },
		{ action: PurchaseOrderConstants.ActionName_ReviewRequired, icon: PurchaseOrderConstants.Confirmation_Title_Icon_ReviewRequired },
		{ action: PurchaseOrderConstants.ActionName_ReviewApproved, icon: PurchaseOrderConstants.Confirmation_Title_Icon_ReviewAndApproved },
		{ action: PurchaseOrderConstants.ActionName_Pause, icon: PurchaseOrderConstants.Confirmation_Title_Icon_Pause },
		{ action: PurchaseOrderConstants.ActionName_Cancel, icon: PurchaseOrderConstants.Confirmation_Title_Icon_Cancel },
	];

	//BulkAction
	static readonly BulkAction_Success_Message = (successCount: string) => `${successCount} record(s) updated successfully.`;
	static readonly BulkAction_Error_Message = (errorCount: string) =>
		`${errorCount} record(s) failed to action, please review the error message for each record and try again.`;
	static readonly BulkAction_Info_Message = (skippedCount: string) => `${skippedCount} record(s) were skipped due to cancelling the action.`;

	//Confirmation Messages
	static readonly Confirmation_Header_Generic = (actionName: string) => `Confirm ${actionName}`;
	static readonly BulkAction_Confirmation_Message_Generic = (recordsCount: string, actionName: string) =>
		`Do you want to mark these ${recordsCount} record(s) as ${actionName}?`;
	static readonly BulkAction_Confirmation_Message_Generic_PauseOrCancel = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} these ${recordsCount} record(s)?`;

	//Dialog Params
	static readonly Message_Title_Icon_Error: string = 'pi pi-times-circle';
	static readonly MessageDialog_Header_Error: string = 'ERROR';
	static readonly MessageDialog_Title_Error: string = 'An error has occured, please try again';

	static readonly BulkAction_Object_Name = 'Purchase Order';
	static readonly BulkAction_Object_PropertyName_Number = 'PurchaseOrderNumber';

	static readonly COLUMNS_PO: Column[] = [
		{ order: 1, header: 'PO #', field: 'PurchaseOrderNumber', width: 100 },
		{ order: 2, header: 'WO #', field: 'WorkOrderNumber', width: 100 },
		{ order: 3, header: 'Allocated To', field: 'BusinessEntityName' },
		{ order: 4, header: 'Asset Name', field: 'AssetReference', dataTestId: 'purchaseOrderAssetName' },
		{ order: 5, header: 'Address', field: 'Street' },
		{ order: 6, header: 'Suburb', field: 'Suburb' },
		{ order: 7, header: 'Status', field: 'StatusName' },
		{ order: 8, header: 'Type', field: 'PurchaseOrderTypeName' },
		{ order: 9, header: 'Priority', field: 'PurchaseOrderPriorityCode' },
		{ order: 10, header: 'Order Owner', field: 'OwnerName' },
		{
			order: 11,
			header: 'Due',
			field: 'TargetDate',
			type: COLUMNTYPES.DATETIME,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.DATE,
		},
		{
			order: 12,
			header: 'Completed',
			field: 'DateWorkCompleted',
			type: COLUMNTYPES.DATETIME,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.DATE,
		},
		{
			order: 13,
			header: 'Cost',
			field: 'CostAmountInclTax',
			type: COLUMNTYPES.NUMERIC,
			isConditional: true,
			hasHeaderSmallLabel: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.NUMERIC,
			isPrice: true,
		},
		{
			order: 14,
			header: 'Invoiced',
			field: 'InvoicedCostAmountInclTax',
			type: COLUMNTYPES.NUMERIC,
			isConditional: true,
			hasHeaderSmallLabel: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.NUMERIC,
			isPrice: true,
		},
	];
}
