<p-table
	#relatedScheduleHistoryTable
	*ngIf="showList"
	[value]="data"
	dataKey="data"
	[rowHover]="true"
	[rows]="50"
	[totalRecords]="totalRecords"
	[paginator]="true"
	[lazy]="true"
	responsiveLayout="scroll"
	[filterDelay]="0"
	[rowsPerPageOptions]="[10, 25, 50]"
	[showCurrentPageReport]="true"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[loading]="loading"
	[filterDelay]="0"
	(onLazyLoad)="loadData($event)">
	<ng-template pTemplate="header">
		<tr class="related-schedule-table-row">
			<th pSortableColumn="ReferenceName" data-testid="relatedScheduleHistoryReference">
				<div class="table-header-label">
					Reference<p-sortIcon field="ReferenceName"> </p-sortIcon>
					<p-columnFilter type="text" field="ReferenceName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="OrderScheduleNumber" data-testid="relatedScheduleHistoryScheduleNumber">
				<div class="table-header-label">
					Schedule #<p-sortIcon field="OrderScheduleNumber"> </p-sortIcon>
					<p-columnFilter type="text" field="OrderScheduleNumber" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="OrderScheduleName" data-testid="relatedScheduleHistoryScheduleName">
				<div class="table-header-label">
					Schedule Name<p-sortIcon field="OrderScheduleName"> </p-sortIcon>
					<p-columnFilter type="text" field="OrderScheduleName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>

			<th pSortableColumn="StatusName" data-testid="relatedScheduleHistoryStatus">
				<div class="table-header-label">
					Status<p-sortIcon field="StatusName"> </p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>

			<th pSortableColumn="ScheduledStartDate">
				<div class="table-header-label">
					Scheduled Start<p-sortIcon field="ScheduledStartDate"></p-sortIcon>
					<p-columnFilter type="date" field="ScheduledStartDate" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="TargetDate">
				<div class="table-header-label">
					Due<p-sortIcon field="TargetDate"></p-sortIcon>
					<p-columnFilter type="date" field="TargetDate" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateCompleted">
				<div class="table-header-label">
					Completed<p-sortIcon field="DateCompleted"></p-sortIcon>
					<p-columnFilter type="date" field="DateCompleted" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>

			<th *ngIf="viewPrices" pSortableColumn="SellAmountInclTax">
				<div class="table-header-label">
					Sell {{ region.CurrencySymbol }}
					<p-sortIcon field="SellAmountInclTax"></p-sortIcon>
					<p-columnFilter type="numeric" field="SellAmountInclTax" display="menu" class="p-ml-auto">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								(onBlur)="util.pTableCurrencyFilter(number.value, 'SellAmountInclTax', relatedScheduleHistoryTable)"
								[locale]="region.RegionCode"
								[minFractionDigits]="region.CurrencyPrecision"
								[maxFractionDigits]="region.CurrencyPrecision"
								autocomplete="off">
							</p-inputNumber>
						</ng-template>
					</p-columnFilter>
				</div>
				<div class="title-label-small">(incl. tax)</div>
			</th>
			<th *ngIf="showLink()" style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-scheduleHistoryDetail>
		<tr class="schedule-detail-row" data-testid="relatedScheduleRow">
			<td>
				<span *ngIf="scheduleHistoryDetail.ReferenceTypeCode" class="is-completed-style">
					<i class="fa fa-circle dot-{{ scheduleHistoryDetail?.ReferenceTypeCode.toLowerCase() }}"></i
				></span>
				{{ scheduleHistoryDetail.ReferenceName }}
			</td>
			<td>
				<span *ngIf="scheduleHistoryDetail.OrderScheduleNumber" class="is-completed-style"> <i class="fa fa-circle dot-schwo"></i></span>
				{{ scheduleHistoryDetail.OrderScheduleNumber }}
			</td>
			<td>{{ scheduleHistoryDetail.OrderScheduleName }}</td>
			<td>{{ scheduleHistoryDetail.StatusName }}</td>
			<td>
				<span [innerHTML]="scheduleHistoryDetail.ScheduledStartDate | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span [innerHTML]="scheduleHistoryDetail.TargetDate | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span [innerHTML]="scheduleHistoryDetail.DateCompleted | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td *ngIf="viewPrices" style="text-align: right">
				{{ scheduleHistoryDetail.SellAmountInclTax | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode }}
			</td>

			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(scheduleHistoryDetail)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For {{ scheduleHistoryDetail.ReferenceTypeCode ? getReferenceTypeName(scheduleHistoryDetail?.ReferenceTypeCode) : '' }}"
					tooltipPosition="bottom"
					(click)="handleRowClick(scheduleHistoryDetail)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(scheduleHistoryDetail)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
</p-table>
