import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, map, of } from 'rxjs';
import { AppMenuConstants } from 'src/app/layout/sidebar/Constants/app.menu.constants';
import { LookupService, NavigationService } from 'src/app/core/services';
import { UserProfileApiService } from '../../../shared/services';

@Injectable({
	providedIn: 'root',
})
export class UserProfileResolver {
	constructor(private navigationService: NavigationService, private userProfileApiService: UserProfileApiService, private lookupService: LookupService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const userId = route.paramMap.get('id');

		return forkJoin({
			user: userId == null ? this.userProfileApiService.getUserProfileForCurrentUser() : this.userProfileApiService.getUserProfile(userId),
			statuses: this.lookupService.getUserProfileStatuses(),
			userRoles: this.lookupService.getInternalUserRoles(),
			myProfile: of(route.data.myProfile),
		}).pipe(
			map(data => {
				if (data?.user) {
					return data;
				} else {
					this.navigationService.navigateToMenuLinks(AppMenuConstants.MenuLinks.Users);
					return null;
				}
			})
		);
	}
}
