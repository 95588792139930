export class BulkActionMessageConstants {
	//Confirmation Messages
	static readonly Confirmation_Header_Generic = (actionName: string) => `Confirm ${actionName}`;
	static readonly BulkAction_Confirmation_Message_Generic = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} ${recordsCount} record(s)?`;
	static readonly BulkAction_Confirmation_Message_ChangeParent = (recordsCount: string) =>
		`Do you want to change the parent property/asset of the selected ${recordsCount} record(s)?`;
	static readonly BulkAction_Confirmation_Message_Print = (recordsCount: string) =>
		`Do you want to print the QR Codes of the selected ${recordsCount} record(s)?`;

	static readonly BulkAction_Confirmation_Mark_Message_Generic = (recordsCount: string, actionName: string) =>
		`Do you want to mark these ${recordsCount} record(s) as ${actionName}?`;

	static readonly BulkAction_Confirmation_Message_Paid = (recordsCount: string, actionName: string) =>
		`Do you want to mark ${recordsCount} record(s) as ${actionName}?`;

	static readonly InvoiceApproveAction = (isCreditNote: boolean, amount: string, paymentDate: string) =>
		`Do you want to approve this ${isCreditNote ? 'credit note' : 'invoice'} for a total of ${amount} (incl. tax), due on ${paymentDate}?`;
	static readonly InvoiceCancelAction = (isCreditNote: boolean) => `Do you want to cancel this ${isCreditNote ? 'credit note' : 'invoice'}?`;
	static readonly InvoiceSendAction = (isCreditNote: boolean, emailCount: number) =>
		`Do you want to send this ${isCreditNote ? 'credit note' : 'invoice'} to the ${emailCount} selected e-mails?`;
	static readonly InvoiceSend_Error_Message = 'At least one e-mail must be provided.';

	static readonly BulkAction_Confirmation_Message_Pause = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} these ${recordsCount} record(s)?`;
	static readonly BulkAction_Confirmation_Message_Cancel_WithNotification = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} these ${recordsCount} record(s) and send all relevant notifications to the contractors?`;
	static readonly BulkAction_Confirmation_Message_SendChanges__WithNotification = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} for these ${recordsCount} record(s) and send all relevant notifications to the contractors?`;

	static readonly BulkAction_Confirmation_Message_Cancel_WithNotificationToContractorsAndClients = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} ${recordsCount} record(s) and send all relevant notifications to the contractors and clients?`;
	static readonly BulkAction_Confirmation_Message_SubmitRequest_WithNotificationToContractorsAndClients = (recordsCount: string) =>
		`Do you want to submit the request for these ${recordsCount} record(s) and send all relevant notifications to the contractors and clients?`;

	static readonly BulkAction_Confirmation_Message_SendInvitation = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} for  ${recordsCount} record(s)?`;

	//Summary Toast Mesages
	static readonly BulkAction_Success_Message = (successCount: string) => `${successCount} record(s) updated successfully.`;
	static readonly BulkAction_Error_Message = (errorCount: string) =>
		`${errorCount} record(s) failed to action, please review the error message for each record and try again.`;
	static readonly BulkAction_Info_Message = (skippedCount: string) => `${skippedCount} record(s) were skipped due to cancelling the action.`;

	//Messages
	static readonly BulkAction_Cancel_Message: string = 'Action cancelled';
	static readonly BulkAction_RecordsSelected_Message = (recordsCount: string) => `${recordsCount} records selected`;

	//Message Dialog Error
	static readonly MessageDialog_Header_Error: string = 'ERROR';
	static readonly MessageDialog_Title_Error: string = 'An error has occured, please try again';
}
