import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { InvoiceConstants } from 'src/app/features/invoice/constants';
import { InvoiceItem, InvoiceRelatedEntry } from 'src/app/features/invoice/models';
import { InvoiceApiService } from 'src/app/features/invoice/services';
import { ConstantExtensions } from 'src/app/shared/constants/constant-extensions.constant';
import { RegionSettings } from 'src/app/shared/models/settings';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService } from 'src/app/core/services';

@Component({
	selector: 'app-related-invoice-item-list',
	templateUrl: './related-invoice-item-list.component.html',
	styleUrls: ['./related-invoice-item-list.component.scss'],
})
export class RelatedInvoiceItemListComponent extends BaseComponent implements OnInit {
	@Input() invoice: InvoiceRelatedEntry;
	@Output() itemUpdated = new EventEmitter();
	@ViewChild('invoiceItemListTable') invoiceItemListTable: Table;
	items: InvoiceItem[] = [];
	region: RegionSettings;

	constructor(appContext: AppContextService, private invoiceService: InvoiceApiService) {
		super(appContext);
	}

	ngOnInit(): void {
		this.formMode = this.formModes.View;
		this.region = this.appContext.getRegion();
		if (this.invoice) {
			this.invoiceService.getInvoiceItemsForInvoice(this.invoice.InvoiceId).subscribe(items => {
				this.items = items;
			});
		}
	}

	isInvoiceSubTypeItems(): boolean {
		return ConstantExtensions.IsInvoiceSubTypeItems(this.invoice.InvoiceSubtypeCode);
	}

	isInvoiceSubTypeAmount(): boolean {
		return ConstantExtensions.IsInvoiceSubTypeItems(this.invoice.InvoiceSubtypeCode);
	}

	isStandardInvoice(): boolean {
		return ConstantExtensions.IsStandardInvoice(this.invoice.InvoiceTypeCode);
	}

	isReceivable(): boolean {
		return ConstantExtensions.IsReceivable(this.invoice.InvoiceTypeCode);
	}

	isPayable(): boolean {
		return ConstantExtensions.IsPayable(this.invoice.InvoiceTypeCode);
	}

	isCreditNote(): boolean {
		return ConstantExtensions.IsCreditNote(this.invoice.InvoiceTypeCode);
	}

	getInvoiceAmountColHeaderText(): string {
		if (this.isReceivable()) return InvoiceConstants.TableColHeaderText_Receivable;
		else if (this.isPayable()) return InvoiceConstants.TableColHeaderText_Payable;
		return '';
	}
}
