<p-table
	#relatedSchedulesTable
	*ngIf="showList"
	[value]="data"
	dataKey="data"
	[rowHover]="true"
	[rows]="50"
	[totalRecords]="totalRecords"
	[paginator]="true"
	[lazy]="true"
	responsiveLayout="scroll"
	[filterDelay]="0"
	[rowsPerPageOptions]="[10, 25, 50]"
	[showCurrentPageReport]="true"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[loading]="loading"
	[filterDelay]="0"
	(onLazyLoad)="loadData($event)">
	<ng-template pTemplate="header">
		<tr class="related-schedule-table-row">
			<th pSortableColumn="ReferenceName" data-testid="relatedScheduleReference">
				<div class="table-header-label">
					Reference<p-sortIcon field="ReferenceName"> </p-sortIcon>
					<p-columnFilter type="text" field="ReferenceName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Name" data-testid="relatedScheduleName">
				<div class="table-header-label">
					Name<p-sortIcon field="Name"> </p-sortIcon>
					<p-columnFilter type="text" field="Name" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="StatusName" data-testid="relatedScheduleStatus">
				<div class="table-header-label">
					Status<p-sortIcon field="StatusName"> </p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="WorkOrderPriorityCode" data-testid="relatedSchedulePriority">
				<div class="table-header-label">
					Priority<p-sortIcon field="WorkOrderPriorityCode"> </p-sortIcon>
					<p-columnFilter type="text" field="WorkOrderPriorityCode" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ServiceProjectName" data-testid="relatedScheduleProject">
				<div class="table-header-label">
					Project<p-sortIcon field="ServiceProjectName"> </p-sortIcon>
					<p-columnFilter type="text" field="ServiceProjectName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateFrom">
				<div class="table-header-label">
					From<p-sortIcon field="DateFrom"></p-sortIcon>
					<p-columnFilter type="date" field="DateFrom" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateTo">
				<div class="table-header-label">
					To<p-sortIcon field="DateTo"></p-sortIcon>
					<p-columnFilter type="date" field="DateTo" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="showLink()" style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-scheduleSearchDetail>
		<tr class="schedule-detail-row" data-testid="relatedScheduleRow">
			<td>
				<span *ngIf="scheduleSearchDetail.ReferenceTypeCode" class="is-completed-style">
					<i class="fa fa-circle dot-{{ scheduleSearchDetail.ReferenceTypeCode.toLowerCase() }}"></i
				></span>
				{{ scheduleSearchDetail.ReferenceName }}
			</td>
			<td>{{ scheduleSearchDetail.Name }}</td>
			<td>{{ scheduleSearchDetail.StatusName }}</td>
			<td>{{ scheduleSearchDetail.WorkOrderPriorityCode }}</td>
			<td>{{ scheduleSearchDetail.ServiceProjectName }}</td>
			<td>
				<span [innerHTML]="scheduleSearchDetail.DateFrom | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span [innerHTML]="scheduleSearchDetail.DateTo | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(scheduleSearchDetail)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For {{ scheduleSearchDetail.ReferenceTypeCode ? getReferenceTypeName(scheduleSearchDetail?.ReferenceTypeCode) : '' }}"
					tooltipPosition="bottom"
					(click)="handleRowClick(scheduleSearchDetail)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(scheduleSearchDetail)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
</p-table>
