export class BulkActionConfirmationIconConstants {
	//Icons
	static readonly Confirmation_Title_Icon_Active: string = 'pi pi-check';
	static readonly Confirmation_Title_Icon_Disable: string = 'pi pi-ban';
	static readonly Confirmation_Title_Icon_Hold: string = 'fas fa-pause-circle';
	static readonly Confirmation_Title_Icon_ChangeParent: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_Delete: string = 'pi pi-trash';
	static readonly Confirmation_Title_Icon_Print: string = 'pi pi-print';
	static readonly Confirmation_Title_Icon_MarkAsPaid: string = 'pi pi-money-bill';
	static readonly Confirmation_Title_Icon_Approve: string = 'pi pi-check';
	static readonly Confirmation_Title_Icon_Release: string = 'pi pi-check';
	static readonly Confirmation_Title_Icon_Cancel: string = 'pi pi-ban';
	static readonly Confirmation_Title_Icon_Void: string = 'pi pi-ban';
	static readonly Confirmation_Title_Icon_Send: string = 'pi pi-send';
	static readonly Confirmation_Title_Icon_Created: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_Allocated: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_InProgress: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_DataEntryRequired: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_ReviewRequired: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_ReviewAndApproved: string = 'pi pi-check';
	static readonly Confirmation_Title_Icon_Pause: string = 'pi pi-pause-circle';
	static readonly Confirmation_Title_Icon_Sent: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_Received: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_Unsuccessful: string = 'pi pi-times';
	static readonly Confirmation_Title_Icon_Activate: string = 'pi pi-check';
	static readonly Confirmation_Title_Icon_Retire: string = 'pi pi-ban';
	static readonly Confirmation_Title_Icon_Amend: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_Accept: string = 'pi pi-check';
	static readonly Confirmation_Title_Icon_Reject: string = 'pi pi-times';
	static readonly Confirmation_Title_Icon_Close: string = 'pi pi-times-circle';
	static readonly Confirmation_Title_Icon_SubmitRequest: string = 'pi pi-send';
	static readonly Message_Title_Icon_Error: string = 'pi pi-times-circle';
	static readonly Confirmation_Title_Icon_MarkAsDraft: string = 'pi pi-pencil';
	static readonly Confirmation_Title_Icon_Recreate: string = 'pi pi-reply';
	static readonly Confirmation_Title_Icon_Resend_Reprocess: string = 'pi pi-replay';
	static readonly Confirmation_Title_Icon_SendChanges: string = 'pi pi-send';
}
