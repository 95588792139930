import { DataRestrictions } from '.';

export class UserRestrictions {
	Restrictions: DataRestrictions[];

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
