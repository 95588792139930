import { PrimeIcons } from 'primeng/api';
import { Constants } from 'src/app/shared/constants/constants.constant';

export class NavigationConstants {
	static readonly Icon_Home: string = 'fas fa-home';
	static readonly Icon_Dashboard: string = PrimeIcons.CHART_BAR;
	static readonly Icon_ServiceRequest: string = 'fas fa-people-carry';
	static readonly Icon_Scope: string = 'fas fa-list-alt';
	static readonly Icon_QuoteRequest: string = 'fas fa-pencil-ruler';
	static readonly Icon_WorkOrder: string = 'fas fa-hammer';
	static readonly Icon_PurchaseOrder: string = 'fas fa-dolly-flatbed';

	static readonly Icon_Invoice: string = 'fas fa-money-check';
	static readonly Icon_ReceivableInvoice: string = 'fas fa-receipt';
	static readonly Icon_PayableInvoice: string = 'fas fa-credit-card';

	static readonly Icon_Asset: string = 'fab fa-autoprefixer';
	static readonly Icon_Property: string = 'fas fa-building';

	static readonly Icon_Report: string = PrimeIcons.CHART_LINE;
	static readonly Icon_Calendar: string = 'far fa-calendar';

	static readonly Icon_Contact: string = 'fas fa-address-book';
	static readonly Icon_Client: string = 'fas fa-hands-helping';
	static readonly Icon_Contractor: string = 'fas fa-hard-hat';
	static readonly Icon_Employee: string = 'fas fa-user-clock';
	static readonly Icon_Supplier: string = 'fas fa-store';

	static readonly Icon_Rates: string = 'fas fa-clipboard-list';
	static readonly Icon_RateGroup: string = 'fas fa-border-none';
	static readonly Icon_Item: string = 'fas fa-border-style';
	static readonly Icon_Problem: string = 'fas fa-exclamation-triangle';

	static readonly Icon_Settings: string = PrimeIcons.COG;
	static readonly Icon_Company: string = PrimeIcons.ID_CARD;
	static readonly Icon_ServiceArea: string = PrimeIcons.GLOBE;
	static readonly Icon_ServiceProject: string = 'fas fa-file-signature';
	static readonly Icon_Field: string = PrimeIcons.TAGS;

	static readonly Icon_SystemInterface: string = 'fas fa-microchip';
	static readonly Icon_IncomingEvent: string = 'fas fa-cloud-download-alt';
	static readonly Icon_OutgoingEvent: string = 'fas fa-cloud-upload-alt';
	static readonly Icon_InterfaceModule: string = 'fas fa-puzzle-piece';

	static readonly Icon_AccountAdmin: string = PrimeIcons.USERS;
	static readonly Icon_SubscriberSettings: string = 'fas fa-user-cog';
	static readonly Icon_User: string = PrimeIcons.USER;
	static readonly Icon_ExternalUser: string = 'fas fa-user-shield';
	static readonly Icon_UserRole: string = PrimeIcons.ID_CARD;
	static readonly Icon_UserTeam: string = 'fas fa-users';

	static readonly Icon_DevTools: string = 'fas fa-bug';

	static readonly Icon_Schedules: string = 'fas fa-recycle';
	static readonly Icon_OrderSchedules: string = 'fas fa-recycle';

	static readonly MESSAGE_MISSINGPERMISSION: string = 'You do not have access to this page or resource.';

	static readonly RecordModules = [
		{
			recordTypeCode: Constants.RecordType.WorkOrder,
			path: '/order/work-order/',
			permission: Constants.UserPermission.WorkOrderView,
			icon: this.Icon_WorkOrder,
		},
		{
			recordTypeCode: Constants.RecordType.PurchaseOrder,
			path: '/order/purchase-order/',
			permission: Constants.UserPermission.PurchaseOrderView,
			icon: this.Icon_PurchaseOrder,
		},
		{
			recordTypeCode: Constants.RecordType.CreditPurchaseOrder,
			path: '/order/purchase-order/',
			permission: Constants.UserPermission.PurchaseOrderView,
			icon: this.Icon_PurchaseOrder,
		},
		{
			recordTypeCode: Constants.RecordType.Defect,
			path: '/order/purchase-order/',
			permission: Constants.UserPermission.PurchaseOrderView,
			icon: this.Icon_PurchaseOrder,
		},
		{
			recordTypeCode: Constants.RecordType.RecoveryPurchaseOrder,
			path: '/order/purchase-order/',
			permission: Constants.UserPermission.PurchaseOrderView,
			icon: this.Icon_PurchaseOrder,
		},
		{
			recordTypeCode: Constants.RecordType.QuoteRequest,
			path: '/quote/',
			permission: Constants.UserPermission.QuoteView,
			icon: this.Icon_QuoteRequest,
		},
		{
			recordTypeCode: Constants.RecordType.Scope,
			path: '/scope/',
			permission: Constants.UserPermission.ScopeView,
			icon: this.Icon_Scope,
		},
		{
			recordTypeCode: Constants.RecordType.ServiceRequest,
			path: '/service-request/',
			permission: Constants.UserPermission.ServiceRequestView,
			icon: this.Icon_ServiceRequest,
		},
		{
			recordTypeCode: Constants.RecordType.Asset,
			path: '/asset/',
			permission: Constants.UserPermission.AssetView,
			icon: this.Icon_Asset,
		},
		{
			recordTypeCode: Constants.RecordType.Property,
			path: '/property/',
			permission: Constants.UserPermission.AssetView,
			icon: this.Icon_Property,
		},
		{
			recordTypeCode: Constants.RecordType.Client,
			path: '/contact/client/',
			permission: Constants.UserPermission.ClientView,
			icon: this.Icon_Client,
		},
		{
			recordTypeCode: Constants.RecordType.Contractor,
			path: '/contact/contractor/',
			permission: Constants.UserPermission.BusinessEntityView,
			icon: this.Icon_Contractor,
		},
		{
			recordTypeCode: Constants.RecordType.Supplier,
			path: '/contact/supplier/',
			permission: Constants.UserPermission.BusinessEntityView,
			icon: this.Icon_Supplier,
		},
		{
			recordTypeCode: Constants.RecordType.Employee,
			path: '/contact/employee/',
			permission: Constants.UserPermission.BusinessEntityView,
			icon: this.Icon_Employee,
		},
		{
			recordTypeCode: Constants.RecordType.RateGroup,
			path: '/rate/rate-group/',
			permission: Constants.UserPermission.SorUpdate,
			icon: this.Icon_RateGroup,
		},
		{
			recordTypeCode: Constants.RecordType.Sor,
			path: '/rate/item/',
			permission: Constants.UserPermission.SorUpdate,
			icon: this.Icon_Item,
		},
		{
			recordTypeCode: Constants.RecordType.Problem,
			path: '/rate/problem/',
			permission: Constants.UserPermission.SorUpdate,
			icon: this.Icon_Problem,
		},
		{
			recordTypeCode: Constants.RecordType.ReceivableInvoice,
			path: '/invoice/receivable/',
			permission: Constants.UserPermission.InvoiceView,
			icon: this.Icon_ReceivableInvoice,
		},
		{
			recordTypeCode: Constants.RecordType.PayableInvoice,
			path: '/invoice/payable/',
			permission: Constants.UserPermission.InvoiceView,
			icon: this.Icon_PayableInvoice,
		},
		{
			recordTypeCode: Constants.RecordType.OrderSchedule,
			path: '/schedule/order-schedule/',
			permission: Constants.UserPermission.OrderScheduleView,
			icon: this.Icon_OrderSchedules,
		},
	];

	static readonly ReferenceModules = [
		{
			referenceTypeCode: Constants.ReferenceType.WorkOrder,
			path: '/order/work-order/',
			permission: Constants.UserPermission.WorkOrderView,
			icon: this.Icon_WorkOrder,
		},
		{
			referenceTypeCode: Constants.ReferenceType.PurchaseOrder,
			path: '/order/purchase-order/',
			permission: Constants.UserPermission.PurchaseOrderView,
			icon: this.Icon_PurchaseOrder,
		},
		{
			referenceTypeCode: Constants.ReferenceType.QuoteRequest,
			path: '/quote/',
			permission: Constants.UserPermission.QuoteView,
			icon: this.Icon_QuoteRequest,
		},
		{
			referenceTypeCode: Constants.ReferenceType.Scope,
			path: '/scope/',
			permission: Constants.UserPermission.ScopeView,
			icon: this.Icon_Scope,
		},
		{
			referenceTypeCode: Constants.ReferenceType.ServiceRequest,
			path: '/service-request/',
			permission: Constants.UserPermission.ServiceRequestView,
			icon: this.Icon_ServiceRequest,
		},
		{
			referenceTypeCode: Constants.ReferenceType.Asset,
			path: '/asset/',
			permission: Constants.UserPermission.AssetView,
			icon: this.Icon_Asset,
		},
		{
			referenceTypeCode: Constants.ReferenceType.Client,
			path: '/contact/client/',
			permission: Constants.UserPermission.ClientView,
			icon: this.Icon_Client,
		},
		{
			referenceTypeCode: Constants.ReferenceType.RateGroup,
			path: '/rate/rate-group/',
			permission: Constants.UserPermission.SorUpdate,
			icon: this.Icon_RateGroup,
		},
		{
			referenceTypeCode: Constants.ReferenceType.Sor,
			path: '/rate/item/',
			permission: Constants.UserPermission.SorUpdate,
			icon: this.Icon_Item,
		},
		{
			referenceTypeCode: Constants.ReferenceType.Problem,
			path: '/rate/problem/',
			permission: Constants.UserPermission.SorUpdate,
			icon: this.Icon_Problem,
		},
		{
			referenceTypeCode: Constants.ReferenceType.Company,
			path: '/setting/company/',
			permission: Constants.UserPermission.CompanyView,
			icon: this.Icon_Company,
		},
		{
			referenceTypeCode: Constants.ReferenceType.ServiceArea,
			path: '/setting/area/',
			permission: Constants.UserPermission.AreaView,
			icon: this.Icon_ServiceArea,
		},
		{
			referenceTypeCode: Constants.ReferenceType.ServiceProject,
			path: '/setting/project/',
			permission: Constants.UserPermission.ProjectView,
			icon: this.Icon_ServiceProject,
		},
		{
			referenceTypeCode: Constants.ReferenceType.InterfaceImportEvent,
			path: '/system-interface/incoming-event/',
			permission: Constants.UserPermission.InterfaceView,
			icon: this.Icon_IncomingEvent,
		},
		{
			referenceTypeCode: Constants.ReferenceType.InterfaceExportEvent,
			path: '/system-interface/outgoing-event/',
			permission: Constants.UserPermission.InterfaceView,
			icon: this.Icon_OutgoingEvent,
		},
		{
			referenceTypeCode: Constants.ReferenceType.IntegrationModule,
			path: '/system-interface/integration-module/',
			permission: Constants.UserPermission.InterfaceView,
			icon: this.Icon_InterfaceModule,
		},
		{
			referenceTypeCode: Constants.ReferenceType.DomainTypeValue,
			path: '/settings/field/',
			permission: Constants.UserPermission.DomainValueView,
			icon: this.Icon_Field,
		},
		{
			referenceTypeCode: Constants.ReferenceType.Problem,
			path: '/account-admin/user-role/',
			permission: Constants.UserPermission.UserRoleView,
			icon: this.Icon_UserRole,
		},
		{
			referenceTypeCode: Constants.ReferenceType.OrderSchedule,
			path: '/schedule/order-schedule/',
			permission: Constants.UserPermission.OrderScheduleView,
			icon: this.Icon_OrderSchedules,
		},

		// not handled
		// BusinessEntity: 'BUSINESSENTITY',
		// FinancialEntity: 'FINANCIALENTITY',
		// Invoice: 'INVOICE',
		// UserProfile: 'USER',
	];
}
