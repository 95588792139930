import { UserRestrictions } from '.';
import { RegionSettings } from '../../shared/models/settings/region-setting.model';
import { WorkflowSettings } from '../../shared/models/settings/workflow-settings.model';

export class UserClientSecurity {
	UserProfileId: string;
	DisplayName: string;
	UserRoleId: string;
	SubscriberId: string;
	SubscriberName: string;
	Workflow: WorkflowSettings;
	SubscriptionAccessTypeCode: string | null;
	ExternalUserRelationTypeCode: string;
	UserPermissionCodes: string[];
	Region: RegionSettings;
	UserRestrictions: UserRestrictions;

	IsTestSubscription: boolean | null;
	IsDemoSubscription: boolean | null;
	IsDefaultSubscription: boolean | null;

	UserSessionId: string; // populated by local

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
