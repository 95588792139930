import { GeolocationDto } from './geolocation.model';

export class AttachmentFile {
	AttachmentFileId: string;
	AttachmentId: string;
	AttachmentTypeName: string;
	AttachmentTitle: string;

	AttachmentFileName: string;
	AttachmentContainerName: string;
	AttachmentClientPath: string;

	GeolocationDto: GeolocationDto;
	TimeZoneCode: string;
	TimeZoneName: string;
	FileData: any;
	FileLength: number;

	DateAdded: Date;
	AddedBy: string;

	//for ui
	LocalFileLocation: string;
	ImagePreview: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
