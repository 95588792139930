import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DevToolOptions } from 'src/app/features/dev-tool/models';
import { ActionResult } from 'src/app/shared/models/api';
import { ApiService } from 'src/app/core/services';
import { UserClientSecurity } from '../models';
import { ApiRequestArgs } from '../models/api-request-args.model';

@Injectable({
	providedIn: 'root',
})
export class SecurityApiService {
	constructor(private apiService: ApiService) {
		//
	}

	getCurrentUser(subscriberId: string = null): Observable<UserClientSecurity> {
		return this.apiService.getArgs<UserClientSecurity>(`/Security/GetCurrentUser`, new ApiRequestArgs({ SubscriberId: subscriberId }));
	}

	setDefaultSubscriberForCurrentUser(subscriberId: string): Observable<ActionResult> {
		return this.apiService.getArgs<ActionResult>(`/Security/SetDefaultSubscriberForCurrentUser/${subscriberId}`);
	}

	setCurrentUserPermissionRestrictions(currentPermissionCodes: string[]): Observable<ActionResult> {
		return this.apiService.post<ActionResult>(`/Security/SetCurrentUserPermissionRestrictions`, currentPermissionCodes);
	}

	setCurrentUserPermissionRestrictionsToUserRole(userRoleId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Security/SetCurrentUserPermissionRestrictionsToUserRole/${userRoleId}`);
	}

	setCurrentUserRestrictionRestrictionsToUserTeams(userTeamIds: string[]): Observable<ActionResult> {
		return this.apiService.post<ActionResult>(`/Security/SetCurrentUserRestrictionRestrictionsToUserTeams`, userTeamIds);
	}

	getDevToolsOptions(userSessionId: string): Observable<DevToolOptions> {
		return this.apiService.get<DevToolOptions>(`/Security/GetDevToolsOptions/${userSessionId}`);
	}

	setDevToolsOptions(options: DevToolOptions): Observable<ActionResult> {
		return this.apiService.post<ActionResult>(`/Security/SetDevToolsOptions`, options);
	}
}
