import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkActionMessageConstants } from 'src/app/shared/constants/bulk-action-message.constants';
import { BulkActionNamesConstants } from 'src/app/shared/constants/bulk-action-names.constants';
import { ActionResult } from 'src/app/shared/models/api';
import { BulkActionBaseService } from 'src/app/shared/services/bulk-action.base.service';
import { AppContextService, DynamicDialogService, TooltipMessageService } from 'src/app/core/services';
import { PurchaseOrderConstants } from '../constants';
import { PurchaseOrderDetails } from '../models';
import { PurchaseOrderService } from './purchase-order.service';

@Injectable({
	providedIn: 'root',
})
export class PurchaseOrderBulkActionService extends BulkActionBaseService {
	constructor(
		public appContext: AppContextService,
		private purchaseOrderService: PurchaseOrderService,
		tooltipMessageService: TooltipMessageService,
		dialogService: DynamicDialogService
	) {
		super(appContext, tooltipMessageService, dialogService);
	}

	setBulkActionBaseValues(moduleName?: string) {
		this.currentObjectPrimaryKeyName = 'PurchaseOrderId';
		this.currentObjectTooltipComponentName = 'PURCHASE_ORDER';
		this.filterActions = [];

		this.addOptionsToFilterActions(PurchaseOrderConstants.FilterActionsList);
	}

	processApiRequest(actionName: string, sel: any, args: any): Observable<ActionResult<any>> {
		switch (actionName) {
			case BulkActionNamesConstants.ActionName_Created:
				return this.purchaseOrderService.markPurchaseOrderAsCreated(sel.PurchaseOrderId);
			case BulkActionNamesConstants.ActionName_Allocated:
				return this.purchaseOrderService.markPurchaseOrderAsAllocated(sel.PurchaseOrderId);
			case BulkActionNamesConstants.ActionName_InProgress:
				return this.purchaseOrderService.markPurchaseOrderAsInProgress(sel.PurchaseOrderId);
			case BulkActionNamesConstants.ActionName_DataEntryRequired:
				return this.purchaseOrderService.markPurchaseOrderAsDataEntryRequired(sel.PurchaseOrderId);
			case BulkActionNamesConstants.ActionName_ReviewRequired:
				return this.purchaseOrderService.markPurchaseOrderAsReviewRequired(sel.PurchaseOrderId);
			case BulkActionNamesConstants.ActionName_ReviewApproved:
				return this.purchaseOrderService.markPurchaseOrderAsReviewedApproved(sel.PurchaseOrderId);
			case BulkActionNamesConstants.ActionName_Pause:
				return this.purchaseOrderService.pausePurchaseOrder(sel.PurchaseOrderId);
			case BulkActionNamesConstants.ActionName_Cancel:
				return this.purchaseOrderService.cancelPurchaseOrder(sel.PurchaseOrderId);
			default:
				return null;
		}
	}

	refreshUpdatedValues(resp: ActionResult<any>) {
		const updatedDetails: PurchaseOrderDetails = resp.Data;
		const filteredDetails: PurchaseOrderDetails = this.filteredSelectedItems.find(x => x.WorkOrderId == updatedDetails.WorkOrderId);
		const selDetails: PurchaseOrderDetails = this.selectedItems.find(x => x.WorkOrderId == updatedDetails.WorkOrderId);
		if (filteredDetails && selDetails) {
			filteredDetails.StatusCode = updatedDetails.StatusCode;
			filteredDetails.StatusName = updatedDetails.StatusName;
			filteredDetails.ObjectAccess = updatedDetails.ObjectAccess;

			selDetails.StatusCode = updatedDetails.StatusCode;
			selDetails.StatusName = updatedDetails.StatusName;
			selDetails.ObjectAccess = updatedDetails.ObjectAccess;
		}
		return;
	}

	showErrorDialog(item: PurchaseOrderDetails) {
		const selItem = this.bulkActionResults.find(x => x.Details.WorkOrderId == item.WorkOrderId);
		if (!selItem) return;

		this.messageObject = selItem;

		this.getErrorDialog(PurchaseOrderConstants.BulkAction_Object_Name, PurchaseOrderConstants.BulkAction_Object_PropertyName_Number);
	}

	actionIcons(actionName): string {
		return PurchaseOrderConstants.ActionIconsNames.find(ai => ai.action == actionName)?.icon || '';
	}

	handleApplyClick(event: any) {
		if (this.isBulkActionView) {
			const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;
			let confirmationMessage;

			if (actionName == BulkActionNamesConstants.ActionName_Cancel) {
				confirmationMessage = BulkActionMessageConstants.BulkAction_Confirmation_Message_Generic(
					this.filteredSelectedItems.length.toString(),
					actionName.toString()
				);
			} else if (actionName == BulkActionNamesConstants.ActionName_Pause) {
				confirmationMessage = BulkActionMessageConstants.BulkAction_Confirmation_Message_Generic(
					this.filteredSelectedItems.length.toString(),
					actionName.toString()
				);
			} else {
				confirmationMessage = BulkActionMessageConstants.BulkAction_Confirmation_Mark_Message_Generic(
					this.filteredSelectedItems.length.toString(),
					this.getactionNameForMark(actionName.toString())
				);
			}

			const confirmationHeader = BulkActionMessageConstants.Confirmation_Header_Generic(actionName.toString());
			const confirmationIcon = this.actionIcons(actionName);

			this.util.clearMessages();
			this.util.confirmSaveCustomParams(confirmationMessage, confirmationHeader, confirmationIcon).then(result => {
				if (result) {
					this.startBulkAction(null);
					return true;
				}
			});
		}
	}

	//This is done to maintain UI checkbox select all checked
	onSelectionChange(items: any[]) {
		this.filteredSelectedItems = [];
		for (let i = items.length - 1; i >= 0; i--) {
			const item = items[i];
			if (this.allowBulkAction(item)) {
				this.filteredSelectedItems.push(item);
			}
		}
	}

	errorDialogClosed() {
		this.showMessage = false;
	}
}
