export class FlowHistory {
	RecordId: string;

	FlowAction: string;
	ReferenceTypeName: string;
	LinkedObjectName: string;
	DateAdded: string;
	TargetDate: Date;
	DateCompleted: Date;
	ObjectNumber: number;
	StatusName: string;
	TimeZoneCode: string;
	TimeZoneName: string;
	IsOpen: boolean;
	InvoiceTypeCode: string;
	RecordTypeCode: string;
}
