import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TargetDateCalculationDetail } from 'src/app/shared/models/details';
import { ApiService, UtilService } from 'src/app/core/services';
import { DateCalculationResult } from '../models/shared/date-calculation-result.model';

@Injectable({
	providedIn: 'root',
})
export class TargetDateService {
	constructor(private apiService: ApiService, private util: UtilService) {}

	calculateDate(calc: TargetDateCalculationDetail, timezoneCode: string): Observable<Date> {
		const httpParams: HttpParams = new HttpParams().append('timezoneCode', timezoneCode);

		const apiCall = this.apiService.post('/TargetDate/CalculateDate', calc, httpParams) as Observable<Date>;
		return apiCall.pipe(
			map(result => {
				const localTime = this.util.fixTimeZones(result, timezoneCode, true);
				return localTime;
			})
		);
	}

	calculateDatesForPurchaseOrder(workOrderId: string, orderPriorityCode: string): Observable<DateCalculationResult> {
		return this.apiService.get<DateCalculationResult>(`/TargetDate/CalculateDatesForPurchaseOrder/${workOrderId}/${orderPriorityCode}`);
	}
}
