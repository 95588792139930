import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SharedConstants } from 'src/app/shared/constants/shared.constants';
import { FormMode, FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { Note } from 'src/app/shared/models/shared';
import { NoteService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, LoaderService, LookupService, TooltipMessageService } from 'src/app/core/services';

@Component({
	selector: 'app-note-detail',
	templateUrl: './note-detail.component.html',
})
export class NoteDetailComponent extends BaseComponent implements OnInit {
	@Input() item: Note = new Note();
	@Input() showReferenceColumn = false;
	@Input() formMode: FormMode;
	@Output() editFinished = new EventEmitter();
	@Output() editCancelled = new EventEmitter();
	@Output() detailsClosed = new EventEmitter();
	formModes = FormMode;
	formControls = FrmCntl;
	openedInEdit = false;
	noteCategories: LookupValue[] = [];
	noteAlertTypes: LookupValue[] = [];
	referenceId: string;
	referenceTypeCode: string;
	itemBackup: Note;
	noteDetailForm = this.fb.group({
		referenceName: [],
		noteAlertType: [null],
		noteCategory: [null, Validators.required],
		comments: [null, Validators.required],
		addedBy: [],
		updatedBy: [],
		dateUpdated: [],
		dateRemoved: [],
		dateAdded: [],
	});

	constructor(
		appContext: AppContextService,
		private noteService: NoteService,
		private lookupService: LookupService,
		private fb: UntypedFormBuilder,
		private loaderService: LoaderService,
		private tooltipMessageService: TooltipMessageService
	) {
		super(appContext);
	}

	ngOnInit(): void {
		if (this.formMode == FormMode.Create) {
			this.referenceId = this.item.ReferenceId;
			this.referenceTypeCode = this.item.ReferenceTypeCode;
		}
		if (this.item) {
			this.itemBackup = this.util.deepClone(this.item);
			this.refreshFormGroup();
			this.initLookups();
		} else {
			this.referenceId = this.item.ReferenceId;
			this.referenceTypeCode = this.item.ReferenceTypeCode;
		}
		if (this.formMode == FormMode.Edit || this.formMode == FormMode.Create) {
			this.openedInEdit = true;
			this.markGroupDirty(this.noteDetailForm);
		}
	}

	initLookups() {
		this.lookupService.getNoteCategories(this.item.ReferenceTypeCode).subscribe(noteCategories => {
			this.noteCategories = noteCategories;
		});
		this.lookupService.getNoteAlertTypes().subscribe(noteAlertTypes => {
			this.noteAlertTypes = noteAlertTypes;
		});
		this.util.addRecordValueIntoLookup(this.item.NoteCategoryCode, this.item.NoteCategoryName, this.noteCategories);
		this.util.addRecordValueIntoLookup(this.item.NoteAlertTypeCode, this.item.NoteAlertTypeName, this.noteAlertTypes);
	}

	refreshFormGroup() {
		const noteCategory = new LookupValue();
		noteCategory.CodeValue = this.item.NoteCategoryCode;
		noteCategory.NameValue = this.item.NoteCategoryName;
		const noteAlertType = new LookupValue();
		noteAlertType.CodeValue = this.item.NoteAlertTypeCode;
		noteAlertType.NameValue = this.item.NoteAlertTypeName;
		this.noteDetailForm.patchValue({
			referenceName: this.item.ReferenceName,
			noteCategory: noteCategory,
			noteAlertType: noteAlertType,
			comments: this.item.Comments,
			addedBy: this.item.AddedBy,
			updatedBy: this.item.UpdatedBy,
			dateUpdated: this.item.DateUpdated,
			dateRemoved: this.item.DateRemoved,
			dateAdded: this.item.DateAdded,
		});
	}

	editItem() {
		this.itemBackup = this.util.deepClone(this.item);
		this.noteDetailForm.reset();
		this.refreshFormGroup();
		this.formMode = FormMode.Edit;
	}

	cancelItemEdit() {
		this.util.clearMessages();
		const hasBackup = this.itemBackup != null;
		this.item = hasBackup ? this.util.deepClone(this.itemBackup) : null;
		this.itemBackup = null;
		this.noteDetailForm.reset();
		if (hasBackup) this.refreshFormGroup();
		this.formMode = this.formModes.View;

		if (this.openedInEdit) this.editCancelled.emit();

		this.openedInEdit = false;
	}

	backToList() {
		if (this.formMode != this.formModes.View) {
			this.cancelItemEdit();
			this.detailsClosed.emit();
		} else {
			this.detailsClosed.emit();
		}
	}

	deleteItem() {
		this.util.confirmDelete('Do you want to delete this note?').then(result => {
			if (result) {
				this.util.clearMessages();
				this.loaderService.block();
				this.noteService.disableNote(this.item.NoteId).subscribe(result => {
					this.loaderService.unblock();
					if (result.IsSuccess) {
						this.item = this.util.deepClone(result.Data);
						this.noteDetailForm.reset();
						this.refreshFormGroup();
						this.editFinished.emit(result.Data);
						this.backToList();
					}
				});
			}
		});
	}

	saveItem() {
		this.util.clearMessages();
		if (this.formMode === FormMode.Create) {
			this.item.ReferenceId = this.referenceId;
			this.item.ReferenceTypeCode = this.referenceTypeCode;
			this.item.DateAdded = new Date();
		}
		if (this.noteDetailForm.valid) {
			this.item.NoteAlertTypeName = this.noteDetailForm.value.noteAlertType?.NameValue;
			this.item.NoteAlertTypeCode = this.noteDetailForm.value.noteAlertType?.CodeValue;

			this.item.NoteCategoryCode = this.noteDetailForm.value.noteCategory?.CodeValue;
			this.item.NoteCategoryName = this.noteDetailForm.value.noteCategory?.NameValue;

			this.item.Comments = this.noteDetailForm.value.comments;

			this.loaderService.block();
			this.noteService.saveNote(this.item).subscribe(res => {
				this.loaderService.unblock();
				if (res.IsSuccess) {
					this.item = this.util.deepClone(res.Data);
					this.itemBackup = null;
					this.noteDetailForm.reset();
					this.refreshFormGroup();
					this.formMode = this.formModes.View;
					this.openedInEdit = false;
					this.editFinished.emit(res.Data);
				}
			});
		} else {
			this.markGroupDirty(this.noteDetailForm);
		}
	}

	showEdit(): boolean {
		return this.item?.ObjectAccess?.AllowEdit?.Hide != true;
	}

	allowEdit(): boolean {
		return this.item?.ObjectAccess?.AllowEdit?.IsAllowed;
	}

	getEditTooltip(): string {
		return (
			this.tooltipMessageService.getTooltipMessage('S_NOTE', 'AllowEdit', this.item?.ObjectAccess?.AllowEdit, this.item) ?? SharedConstants.Button_Edit_Note
		);
	}

	showDelete(): boolean {
		return this.item?.ObjectAccess?.AllowRemove?.Hide != true;
	}

	allowDelete(): boolean {
		return this.item?.ObjectAccess?.AllowRemove?.IsAllowed;
	}

	getDeleteTooltip(): string {
		return (
			this.tooltipMessageService.getTooltipMessage('S_NOTE', 'AllowRemove', this.item?.ObjectAccess?.AllowRemove, this.item) ??
			SharedConstants.Button_Delete_Note
		);
	}
}
