import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActionResult, ActionResultMessageType } from 'src/app/shared/models/api';
import { ApiFile, AttachmentFile } from 'src/app/shared/models/shared';
import { ApiRequestArgs } from '../models/api-request-args.model';
import { isActionResult } from '../utils/type-guards';
import { AppContextService } from './app-context.service';
import { NavigationService } from './navigation.service';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private state: RouterStateSnapshot;
	constructor(private appContext: AppContextService, private router: Router, private navigationService: NavigationService) {
		this.state = this.router.routerState.snapshot;
	}

	private setHeaders(args: ApiRequestArgs = new ApiRequestArgs()): HttpHeaders {
		let headersConfig: any = {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': [true],
			Accept: args.FormData ? '*/*' : 'application/json',
			'Cache-Control': 'no-cache',
			timeout: args.FormData ? '300000' : '180000',
		};

		if (!args.SkipContentType) {
			headersConfig['Content-Type'] = args.FormData ? 'application/x-www-form-urlencoded; charset=UTF-8' : 'application/json';
		} else {
			//const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
			headersConfig['enctype'] = 'multipart/form-data';
			headersConfig['Accept'] = '*/*';
		}

		if (args.CustomHeaders) {
			headersConfig = Object.assign(headersConfig, args.CustomHeaders);
		}

		const sessionId = this.appContext.user.UserSessionId;
		if (sessionId != null && sessionId != '') headersConfig['x-spyderflow-user-sessionid'] = sessionId;

		args.SubscriberId ??= this.appContext.user.SubscriberId;
		if (args.SubscriberId) headersConfig['x-spyderflow-subscriberid'] = args.SubscriberId;

		return new HttpHeaders(headersConfig);
	}

	get<T>(path: string, params: HttpParams = new HttpParams(), showResult = true): Observable<T> {
		return this.getArgs(path, new ApiRequestArgs({ Params: params, ShowResult: showResult }));
	}

	getArgs<T>(path: string, args: ApiRequestArgs = new ApiRequestArgs()): Observable<T> {
		try {
			return this.appContext.httpClient
				.get<T>(`${this.appContext.config.apiUrl}${path}`, { headers: this.setHeaders(args), params: args.Params })
				.pipe(catchError((error: HttpErrorResponse) => this.handleError(error)))
				.pipe(switchMap(resp => this.prepareResponse(resp, args.AlwaysShowResult)));
		} catch (ex) {
			console.log('HttpGet Path', path, ex);
		}
	}

	post<T>(path: string, body: any = {}, params: HttpParams = new HttpParams(), addHeaders: any = null, formData = false, showResult = true): Observable<T> {
		return this.postArgs(path, body, new ApiRequestArgs({ Params: params, CustomHeaders: addHeaders, FormData: formData, ShowResult: showResult }));
	}

	postArgs<T>(path: string, body: any = {}, args: ApiRequestArgs = new ApiRequestArgs()): Observable<T> {
		try {
			let clone = this.appContext.util.deepClone(body);
			this.appContext.util.fixDates(clone, null, false);
			clone = this.appContext.util.stringifySubJsonFields(clone);
			return this.appContext.httpClient
				.post<T>(`${this.appContext.config.apiUrl}${path}`, clone, {
					headers: this.setHeaders(args),
					params: args.Params,
				})
				.pipe(catchError((error: HttpErrorResponse) => this.handleError(error)))
				.pipe(switchMap(resp => this.prepareResponse(resp, args.AlwaysShowResult)));
		} catch (ex) {
			console.log('HttpPost Path', path, ex);
		}
	}

	postFileWithProgress(path: string, file: File, fileDto?: AttachmentFile): Observable<HttpEvent<any>> {
		const defaultHeaders = this.setHeaders(new ApiRequestArgs({ SkipContentType: true }));
		const formData: FormData = new FormData();
		formData.append('fileKey', file, file.name);
		formData.append('fileDto', JSON.stringify(fileDto));
		if (fileDto) {
			formData.append('fileId', fileDto.AttachmentFileId);
		}
		return this.appContext.httpClient
			.post<ActionResult>(`${this.appContext.config.apiUrl}${path}`, formData, { headers: defaultHeaders, reportProgress: true, observe: 'events' })
			.pipe(
				catchError((error: HttpErrorResponse) => {
					if (error.status === 401) {
						this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
					}

					if (error.status === 403) {
						this.navigationService.navigateToAccessDenied();
					}
					if (error.error instanceof Error) {
						console.error('An error occurred:', error.error.message);
					} else {
						console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
					}
					return throwError(error);
				})
			);
	}

	postFile(path: string, file: File, fileDto?: AttachmentFile | ApiFile): Observable<ActionResult> {
		const defaultHeaders = this.setHeaders(new ApiRequestArgs({ SkipContentType: true }));
		const formData: FormData = new FormData();
		formData.append('fileKey', file, file.name);
		formData.append('fileDto', JSON.stringify(fileDto));
		if (fileDto) {
			let fileId: string;
			if ('AttachmentFileId' in fileDto) fileId = fileDto.AttachmentFileId;
			else if ('NewFileId' in fileDto) fileId = fileDto.NewFileId;
			formData.append('fileId', fileId);
		}
		return this.appContext.httpClient
			.post<ActionResult>(`${this.appContext.config.apiUrl}${path}`, formData, { headers: defaultHeaders })
			.pipe(
				catchError((error: HttpErrorResponse) => {
					if (error.status === 401) {
						this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
					}

					if (error.status === 403) {
						this.navigationService.navigateToAccessDenied();
					}
					if (error.error instanceof Error) {
						console.error('An error occurred:', error.error.message);
					} else {
						console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
					}
					return throwError(error);
				})
			)
			.pipe(
				map((resp: ActionResult) => {
					this.appContext.util.fixDates(resp, null, true);
					return resp;
				})
			);
	}

	getFile(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.appContext.httpClient.get(`${this.appContext.config.apiUrl}${path}`, { headers: this.setHeaders(), responseType: 'blob', params: params }).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
				}
				if (error.error instanceof Error) {
					console.error('An error occurred:', error.error.message);
				} else {
					console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
				}
				return throwError(error);
			})
		);
	}

	getFileByPost(path: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<any> {
		return this.appContext.httpClient
			.post(`${this.appContext.config.apiUrl}${path}`, body, { headers: this.setHeaders(), responseType: 'blob', params: params })
			.pipe(
				catchError((error: HttpErrorResponse) => {
					if (error.status === 401) {
						this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
					}
					if (error.error instanceof Error) {
						console.error('An error occurred:', error.error.message);
					} else {
						console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
					}
					return throwError(error);
				})
			);
	}

	private handleError(error: HttpErrorResponse) {
		console.log('api error', JSON.stringify(error));
		if (error.status === 401) {
			this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
		}
		if (error.status === 403) {
			this.navigationService.navigateToAccessDenied();
		}

		// error is handled by RequestInterceptor for displaying to user
		return of(<ActionResult>{
			IsSuccess: false,
			Messages: [],
		});
	}

	private prepareResponse<T>(resp: ActionResult | T, alwaysShowResult: boolean) {
		if (resp === null) return of(resp as T);

		if (isActionResult(resp)) {
			if (resp.Messages.some(x => x.MessageType === ActionResultMessageType.UnauthorizedAccess)) {
				this.navigationService.navigateToAccessDenied();
			} else {
				if (resp.Data) {
					this.appContext.util.fixDates(resp.Data, null, true);
				}

				if (alwaysShowResult || !resp.IsSuccess) {
					this.appContext.util.showActionResult(resp);
				}
			}
		} else {
			this.appContext.util.fixDates(resp, null, true);
		}

		return of(resp as T);
	}
}
