import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogParams } from 'src/app/core/models';
import { MultiselectListItem } from '../../models/forms';

@Component({
	selector: 'app-multiselect-list-dialog',
	templateUrl: './multiselect-list-dialog.component.html',
	styleUrls: ['./multiselect-list-dialog.component.scss'],
})
export class MultiselectListDialogComponent implements OnInit {
	private params: DynamicDialogParams;
	title: string;
	icon: string;

	listItems: MultiselectListItem[];
	selectedListItems: MultiselectListItem[];
	acceptButtonLabel: string;
	acceptButtonIcon: string;
	acceptButtonTooltip: string;
	rejectButtonLabel: string;
	rejectButtonIcon: string;
	rejectButtonTooltip: string;
	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}
	ngOnInit(): void {
		this.params = this.config.data as DynamicDialogParams;
		if (this.params.data != null) {
			this.title = this.params.data.title;
			this.icon = this.params.data.icon;
			this.listItems = this.params.data.listItems;
			this.selectedListItems = this.params.data.selectedListItems;
			this.acceptButtonIcon = this.params.data.acceptButtonIcon;
			this.acceptButtonLabel = this.params.data.acceptButtonLabel;
			this.acceptButtonTooltip = this.params.data.acceptButtonTooltip;
			this.rejectButtonIcon = this.params.data.rejectButtonIcon;
			this.rejectButtonLabel = this.params.data.rejectButtonLabel;
			this.rejectButtonTooltip = this.params.data.rejectButtonTooltip;
		}
	}

	submitDialog() {
		this.ref.close(this.selectedListItems);
	}

	closeDialog() {
		this.selectedListItems = [];
		this.ref.close(null);
	}

	getHeader(): string {
		return this.params.header;
	}

	getTitle(): string {
		return this.title;
	}

	getIcon() {
		return this.icon;
	}
}
