import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { SearchFilter } from 'src/app/shared/models/search-filters';
import { GlobalSearch, GlobalSearchRequest } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';
import { ScheduleHistory } from '../models/shared/schedule-history.model';
import { ScheduleOrderHistory } from '../models/shared/schedule-order-history.model';
import { ScheduleSearch } from '../models/shared/schedule-search.model';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	constructor(private apiService: ApiService) {
		//
	}

	getSearchFilterFromCode(searchFilterTypeCode: string, argsJson: string): Observable<SearchFilter> {
		const httpParams: HttpParams = new HttpParams().append('searchFilterTypeCode', searchFilterTypeCode).append('argsJson', argsJson);

		return this.apiService.get<SearchFilter>(`/Search/GetSearchFilterFromCode`, httpParams);
	}

	getPagedGlobalList(searchData: LazyLoadArgs, assetNumber: string = null, clientId: string = null): Observable<PagedResponse<GlobalSearch>> {
		searchData.data = new GlobalSearchRequest();
		searchData.data.AssetNumber = assetNumber;
		searchData.data.ClientId = clientId;

		return this.apiService.post<PagedResponse<GlobalSearch>>('/Search/GetPagedGlobalList', searchData);
	}

	getPagedSchedulesListForClient(searchData: LazyLoadArgs, clientId: string = null): Observable<PagedResponse<ScheduleSearch>> {
		const httpParams: HttpParams = new HttpParams().append('clientId', clientId);
		return this.apiService.post<PagedResponse<ScheduleSearch>>('/Search/GetPagedSchedulesListForClient', searchData, httpParams);
	}

	getPagedSchedulesListForAsset(searchData: LazyLoadArgs, assetId: string = null): Observable<PagedResponse<ScheduleSearch>> {
		const httpParams: HttpParams = new HttpParams().append('assetId', assetId);
		return this.apiService.post<PagedResponse<ScheduleSearch>>('/Search/GetPagedSchedulesListForAsset', searchData, httpParams);
	}

	getPagedScheduleHistoryListForWorkOrder(searchData: LazyLoadArgs, workOrderId: string = null): Observable<PagedResponse<ScheduleHistory>> {
		const httpParams: HttpParams = new HttpParams().append('workOrderId', workOrderId);
		return this.apiService.post<PagedResponse<ScheduleHistory>>('/Search/GetPagedScheduleHistoryListForWorkOrder', searchData, httpParams);
	}

	getPagedScheduleHistoryListForAsset(searchData: LazyLoadArgs, assetId: string = null): Observable<PagedResponse<ScheduleHistory>> {
		const httpParams: HttpParams = new HttpParams().append('assetId', assetId);
		return this.apiService.post<PagedResponse<ScheduleHistory>>('/Search/GetPagedScheduleHistoryListForAsset', searchData, httpParams);
	}

	getPagedSchedulePurchaseOrderHistoryListForOrderSchedule(
		searchData: LazyLoadArgs,
		scheduleId: string = null
	): Observable<PagedResponse<ScheduleOrderHistory>> {
		const httpParams: HttpParams = new HttpParams().append('orderScheduleId', scheduleId);

		return this.apiService.post<PagedResponse<ScheduleOrderHistory>>(
			'/Search/GetPagedSchedulePurchaseOrderHistoryListForOrderSchedule',
			searchData,
			httpParams
		);
	}

	getPagedScheduleWorkOrderHistoryListForOrderSchedule(searchData: LazyLoadArgs, scheduleId: string = null): Observable<PagedResponse<ScheduleOrderHistory>> {
		searchData.data = new GlobalSearchRequest();
		const httpParams: HttpParams = new HttpParams().append('orderScheduleId', scheduleId);
		return this.apiService.post<PagedResponse<ScheduleOrderHistory>>('/Search/GetPagedScheduleWorkOrderHistoryListForOrderSchedule', searchData, httpParams);
	}
}
