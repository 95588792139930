import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceDetails } from 'src/app/features/invoice/models';
import { InvoiceApiService } from 'src/app/features/invoice/services';
import { BulkActionMessageConstants } from 'src/app/shared/constants/bulk-action-message.constants';
import { BulkActionNamesConstants } from 'src/app/shared/constants/bulk-action-names.constants';
import { ActionResult } from 'src/app/shared/models/api';
import { BulkActionBaseService } from 'src/app/shared/services/bulk-action.base.service';
import { AppContextService, DynamicDialogService, TooltipMessageService } from 'src/app/core/services';
import { PaidInvoiceConfirmationDialogComponent } from '../components/paid-invoice-confirmation-dialog/paid-invoice-confirmation-dialog.component';
import { InvoiceConstants } from '../constants';

@Injectable({
	providedIn: 'root',
})
export class InvoiceBulkActionService extends BulkActionBaseService {
	constructor(
		public appContext: AppContextService,
		private invoiceService: InvoiceApiService,
		tooltipMessageService: TooltipMessageService,
		dialogService: DynamicDialogService
	) {
		super(appContext, tooltipMessageService, dialogService);
	}

	setBulkActionBaseValues(moduleName?: string) {
		this.currentObjectPrimaryKeyName = 'InvoiceId';
		this.currentObjectTooltipComponentName = 'INVOICE';
		this.filterActions = [];
		const tempList = [];
		InvoiceConstants.FilterActionsList.forEach(option => {
			if (option == BulkActionNamesConstants.ActionName_Paid && !this.hasPaymentPermission()) return;

			tempList.push(option);
		});

		this.addOptionsToFilterActions(tempList);
	}

	processApiRequest(actionName: string, sel: any, args: any): Observable<ActionResult<any>> {
		switch (actionName) {
			case BulkActionNamesConstants.ActionName_Approve:
				return this.invoiceService.approveInvoice(sel.InvoiceId);
			case BulkActionNamesConstants.ActionName_Cancel:
				return this.invoiceService.cancelInvoice(sel.InvoiceId);
			case BulkActionNamesConstants.ActionName_Paid:
				return this.invoiceService.markInvoiceAsPaid(sel.InvoiceId, args?.datePaid, args?.referenceNumber, args?.comments);
			case BulkActionNamesConstants.ActionName_Hold:
				return this.invoiceService.holdInvoice(sel.InvoiceId);
			case BulkActionNamesConstants.ActionName_Release:
				return this.invoiceService.issueInvoice(sel.InvoiceId);
			case BulkActionNamesConstants.ActionName_Void:
				return this.invoiceService.voidInvoice(sel.InvoiceId);
			default:
				return null;
		}
	}

	refreshUpdatedValues(resp: ActionResult<any>) {
		const updatedInvoiceDet: InvoiceDetails = resp.Data;
		const filteredInvoice: InvoiceDetails = this.filteredSelectedItems.find(x => x.InvoiceId == updatedInvoiceDet.InvoiceId);
		const selInvoice: InvoiceDetails = this.selectedItems.find(x => x.InvoiceId == updatedInvoiceDet.InvoiceId);
		if (filteredInvoice && selInvoice) {
			filteredInvoice.StatusCode = updatedInvoiceDet.StatusCode;
			filteredInvoice.StatusName = updatedInvoiceDet.StatusName;
			filteredInvoice.ObjectAccess = updatedInvoiceDet.ObjectAccess;

			selInvoice.StatusCode = updatedInvoiceDet.StatusCode;
			selInvoice.StatusName = updatedInvoiceDet.StatusName;
			selInvoice.ObjectAccess = updatedInvoiceDet.ObjectAccess;
		}
		return;
	}

	hasPaymentPermission() {
		return this.appContext.user.Workflow?.Invoice?.AllowManualPayments;
	}

	showErrorDialog(item: InvoiceDetails) {
		const selItem = this.bulkActionResults.find(x => x.Details.InvoiceId == item.InvoiceId);
		if (!selItem) return;

		this.messageObject = selItem;

		this.getErrorDialog(InvoiceConstants.BulkAction_Object_Name, InvoiceConstants.BulkAction_Object_PropertyName_Number);
	}

	actionIcons(actionName): string {
		return InvoiceConstants.ActionIconsNames.find(ai => ai.action == actionName)?.icon || '';
	}

	handleApplyClick(event: any) {
		if (this.isBulkActionView) {
			const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;
			if (actionName == BulkActionNamesConstants.ActionName_Paid) {
				this.handleApplyPaidClick(actionName);
				return;
			}

			const confirmationHeader = BulkActionMessageConstants.Confirmation_Header_Generic(actionName.toString());
			const confirmationMessage = BulkActionMessageConstants.BulkAction_Confirmation_Message_Generic(
				this.filteredSelectedItems.length.toString(),
				actionName.toString()
			);
			const confirmationIcon = this.actionIcons(actionName);

			this.util.clearMessages();
			this.util.confirmSaveCustomParams(confirmationMessage, confirmationHeader, confirmationIcon).then(result => {
				if (result) {
					this.startBulkAction(null);
					return true;
				}
			});
		}
	}

	handleApplyPaidClick(actionName: string) {
		this.dialogService
			.open({
				component: PaidInvoiceConfirmationDialogComponent,
				header: BulkActionMessageConstants.Confirmation_Header_Generic(actionName),
				data: {
					title: BulkActionMessageConstants.BulkAction_Confirmation_Message_Paid(this.filteredSelectedItems.length.toString(), actionName.toLowerCase()),
					icon: this.actionIcons(actionName),
				},
				contentStyle: { overflow: 'visible' },
			})
			.subscribe(value => {
				if (value) {
					this.startBulkAction(value);
				}
			});
	}

	//This is done to maintain UI checkbox select all checked
	onSelectionChange(items: any[]) {
		this.filteredSelectedItems = [];
		for (let i = items.length - 1; i >= 0; i--) {
			const item = items[i];
			if (this.allowBulkAction(item)) {
				this.filteredSelectedItems.push(item);
			}
		}
	}

	errorDialogClosed() {
		this.showMessage = false;
	}
}
