import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild, effect } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { Toast } from 'primeng/toast';
import { GroupLookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { AppContextService } from 'src/app/core/services';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { MenuService } from '../sidebar/app.menu.service';

@Component({
	selector: 'app-main',
	templateUrl: './app.main.component.html',
	styleUrls: ['./app.main.component.scss'],
})
export class AppMainComponent implements OnInit, AfterViewInit {
	blocked = false;
	@ViewChild('content') contentRef: ElementRef;
	@ViewChild('toast') toastRef: Toast;
	showModalDialog: boolean;

	menuClick: boolean;
	menuButtonClick: boolean;
	topbarMenuButtonClick: boolean;
	topbarMenuClick: boolean;
	topbarMenuActive: boolean;
	activeTopbarItem: Element;
	sidebarActive: boolean;
	mobileMenuActive: boolean;
	menuHoverActive: boolean;
	resetMenu: boolean;
	configActive: boolean;
	configClick: boolean;
	region: RegionSettings;
	itemGroups: GroupLookupValue[];
	selectedItems: any[] = [];
	BasePageCSSClass: string;

	constructor(
		private menuService: MenuService,
		private messageService: MessageService,
		public app: AppComponent,
		public appContext: AppContextService,
		public globals: Globals,
		public loaderService: LoaderService,
		private ref: ChangeDetectorRef,
		private lookupService: LookupService,
		private renderer: Renderer2,
		private el: ElementRef
	) {
		effect(() => {
			const body = this.el.nativeElement.ownerDocument.body;
			body.className = Array.from(body.classList)
				.filter((className: string) => !className.startsWith('main-'))
				.join(' ');
			const basePageCSSClass = this.appContext.BasePageCSSClass();
			if (basePageCSSClass) {
				this.renderer.addClass(body, basePageCSSClass);
			}
		});
	}

	ngOnInit(): void {
		this.refreshRegion();
		this.loaderService.blokedChange.subscribe(value => (this.blocked = value));
		this.appContext.userChanged.subscribe(x => {
			this.refreshRegion();
			this.refreshViewByClientCompanies();
		});
	}

	ngAfterViewInit(): void {
		this.refreshViewByClientCompanies();
	}

	refreshRegion() {
		this.region = this.appContext.getRegion();
		let dateFormat = this.region.DateFormat.split('yyyy').join('yy');
		dateFormat = dateFormat.split('M').join('m');
		Calendar.prototype.getDateFormat = () => dateFormat;
	}

	refreshViewByClientCompanies() {
		if (this.appContext.user.SubscriberId) {
			this.lookupService.getClientsCompanies().subscribe(result => {
				const mappedResult = result.map(item => {
					return {
						label: item.GroupLookupTypeCode,
						value: item.GroupLookupTypeName,
						items: item.Items.map(subitem => {
							return {
								label: subitem.ExtendedNameValue,
								IsInactive: subitem.ExtendedNameValue == '<No Client>' ? null : subitem.IsInactive,
								value: item.GroupLookupTypeCode + ',' + (subitem.LookupValueId ?? ''),
							};
						}),
					};
				});

				this.itemGroups = JSON.parse(JSON.stringify(mappedResult));

				const dropdown = this.appContext.getSavedValue('ViewBy-Dropdown');
				if (dropdown !== null) {
					this.selectedItems = dropdown.split(';').filter(Boolean);
				}
			});
		} else {
			this.itemGroups = [];
			this.selectedItems = [];
		}

		this.ref.detectChanges();
	}

	get pageLevelBlock() {
		return this.blockedElemRef == this.contentRef;
	}

	get blockedElemRef() {
		return this.loaderService.blockedElem ? this.loaderService.blockedElem : this.contentRef;
	}

	onClearAll() {
		this.messageService.clear();
	}

	onMsgClose() {
		if (this.toastRef.messages.length === 0) this.messageService.clear();
	}

	onWrapperClick() {
		if (!this.menuClick && !this.menuButtonClick) {
			this.mobileMenuActive = false;
		}

		if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
			this.topbarMenuActive = false;
			this.activeTopbarItem = null;
		}

		if (!this.menuClick) {
			if (this.isHorizontal()) {
				this.menuService.reset();
			}

			this.menuHoverActive = false;
		}

		if (this.configActive && !this.configClick) {
			this.configActive = false;
		}

		this.configClick = false;
		this.menuClick = false;
		this.menuButtonClick = false;
		this.topbarMenuClick = false;
		this.topbarMenuButtonClick = false;
	}

	onMenuButtonClick(event: Event) {
		this.menuButtonClick = true;

		if (this.isMobile()) {
			this.mobileMenuActive = !this.mobileMenuActive;
		}

		event.preventDefault();
	}

	onTopbarMobileMenuButtonClick(event: Event) {
		this.topbarMenuButtonClick = true;
		this.topbarMenuActive = !this.topbarMenuActive;
		event.preventDefault();
	}

	onTopbarRootItemClick(event: Event, item: Element) {
		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null;
		} else {
			this.activeTopbarItem = item;
		}

		event.preventDefault();
	}

	onTopbarMenuClick(event: Event) {
		this.topbarMenuClick = true;
	}

	onSidebarClick(event: Event) {
		this.menuClick = true;
		this.resetMenu = false;
	}

	onConfigClick(event) {
		this.configClick = true;
	}

	onRippleChange(event) {
		this.app.ripple = event.checked;
	}

	onToggleMenuClick(event: Event) {
		this.app.layoutMode = this.app.layoutMode !== 'static' ? 'static' : 'overlay';
		event.preventDefault();
	}

	isMobile() {
		return window.innerWidth <= 1024;
	}

	isTablet() {
		const width = window.innerWidth;
		return width <= 1024 && width > 640;
	}

	isDesktop() {
		return window.innerWidth > 1024;
	}

	isHorizontal() {
		return this.app.layoutMode === 'horizontal';
	}

	isOverlay() {
		return this.app.layoutMode === 'overlay';
	}
}
