import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LookupValue } from 'src/app/shared/models/lookups';
import { ApiService } from './api.service';
import { AppContextService } from './app-context.service';

export class _LookupPartialService {
	constructor(protected apiService: ApiService, protected appContext: AppContextService) {}

	getAssetAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetAttachmentTypes', httpParams);
	}

	getAssetCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetCategories', httpParams);
	}

	getAssetDisposalMethods(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetDisposalMethods', httpParams);
	}

	getAssetEndofLifes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetEndofLifes', httpParams);
	}

	getAssetNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetNoteCategories', httpParams);
	}

	getAssetTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetTypes', httpParams);
	}

	getBillingAssetTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetBillingAssetTypes', httpParams);
	}

	getBusinessEntityAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetBusinessEntityAttachmentTypes', httpParams);
	}

	getBusinessEntityNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetBusinessEntityNoteCategories', httpParams);
	}

	getBusinessEntityRelationTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetBusinessEntityRelationTypes', httpParams);
	}

	getBusinessEntityStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetBusinessEntityStatuses', httpParams);
	}

	getCalendarEventTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetCalendarEventTypes', httpParams);
	}

	getClientAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetClientAttachmentTypes', httpParams);
	}

	getClientNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetClientNoteCategories', httpParams);
	}

	getClientStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetClientStatuses', httpParams);
	}

	getColours(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetColours', httpParams);
	}

	getCompanyStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetCompanyStatuses', httpParams);
	}

	getContactTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetContactTypes', httpParams);
	}

	getContractorEntityTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetContractorEntityTypes', httpParams);
	}

	getCountries(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetCountries', httpParams);
	}

	getDashboardObjectClassifications(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDashboardObjectClassifications', httpParams);
	}

	getDashboardRefreshPeriods(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDashboardRefreshPeriods', httpParams);
	}

	getDashboardWidgets(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDashboardWidgets', httpParams);
	}

	getDashboardWidgetSources(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDashboardWidgetSources', httpParams);
	}

	getDataTemplateQuestionTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDataTemplateQuestionTypes', httpParams);
	}

	getDataTemplateSystemTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDataTemplateSystemTypes', httpParams);
	}

	getDayofWeeks(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDayofWeeks', httpParams);
	}

	getDefectPurchaseOrderPriorities(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDefectPurchaseOrderPriorities', httpParams);
	}

	getDocumentActionTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDocumentActionTypes', httpParams);
	}

	getDocumentDestinationTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDocumentDestinationTypes', httpParams);
	}

	getDocumentOutputTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDocumentOutputTypes', httpParams);
	}

	getDocumentSourceTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDocumentSourceTypes', httpParams);
	}

	getDomainDetailTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDomainDetailTypes', httpParams);
	}

	getDomainSystemTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDomainSystemTypes', httpParams);
	}

	getDurationTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetDurationTypes', httpParams);
	}

	getEntitySizes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetEntitySizes', httpParams);
	}

	getEstimators(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetEstimators', httpParams);
	}

	getExternalFinanceTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetExternalFinanceTypes', httpParams);
	}

	getExternalNotificationTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetExternalNotificationTypes', httpParams);
	}

	getFieldVisibilities(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetFieldVisibilities', httpParams);
	}

	getFileStoreTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetFileStoreTypes', httpParams);
	}

	getFinancialEntityTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetFinancialEntityTypes', httpParams);
	}

	getFlagAlertTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetFlagAlertTypes', httpParams);
	}

	getGroupLookupTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetGroupLookupTypes', httpParams);
	}

	getHistoryFieldTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetHistoryFieldTypes', httpParams);
	}

	getInspectors(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInspectors', httpParams);
	}

	getInsuranceTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInsuranceTypes', httpParams);
	}

	getIntegrationEventFileTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetIntegrationEventFileTypes', httpParams);
	}

	getIntegrationEventStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetIntegrationEventStatuses', httpParams);
	}

	getIntegrationEventTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetIntegrationEventTypes', httpParams);
	}

	getIntegrationModuleStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetIntegrationModuleStatuses', httpParams);
	}

	getIntegrationModuleTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetIntegrationModuleTypes', httpParams);
	}

	getIntegrationQueueTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetIntegrationQueueTypes', httpParams);
	}

	getInvoiceAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceAttachmentTypes', httpParams);
	}

	getInvoiceCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceCategories', httpParams);
	}

	getInvoiceCreationRules(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceCreationRules', httpParams);
	}

	getInvoiceIssueTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceIssueTypes', httpParams);
	}

	getInvoiceNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceNoteCategories', httpParams);
	}

	getInvoiceStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceStatuses', httpParams);
	}

	getInvoiceSubtypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceSubtypes', httpParams);
	}

	getInvoiceTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetInvoiceTypes', httpParams);
	}

	getItemLocations(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetItemLocations', httpParams);
	}

	getItemReviewResults(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetItemReviewResults', httpParams);
	}

	getLanguages(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetLanguages', httpParams);
	}

	getLiabilityTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetLiabilityTypes', httpParams);
	}

	getLicenceCertificateTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetLicenceCertificateTypes', httpParams);
	}

	getMapMarkerStyles(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetMapMarkerStyles', httpParams);
	}

	getNoteAlertTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetNoteAlertTypes', httpParams);
	}

	getOrderItemReviewStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetOrderItemReviewStatuses', httpParams);
	}

	getOrderPriorities(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetOrderPriorities', httpParams);
	}

	getOrderScheduleAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetOrderScheduleAttachmentTypes', httpParams);
	}

	getOrderScheduleIssueTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetOrderScheduleIssueTypes', httpParams);
	}

	getOrderScheduleNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetOrderScheduleNoteCategories', httpParams);
	}

	getOrderScheduleStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetOrderScheduleStatuses', httpParams);
	}

	getOwners(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetOwners', httpParams);
	}

	getPaymentTerms(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetPaymentTerms', httpParams);
	}

	getPriceOverrideReasons(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetPriceOverrideReasons', httpParams);
	}

	getPriceTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetPriceTypes', httpParams);
	}

	getPurchaseOrderAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetPurchaseOrderAttachmentTypes', httpParams);
	}

	getPurchaseOrderNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetPurchaseOrderNoteCategories', httpParams);
	}

	getPurchaseOrderStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetPurchaseOrderStatuses', httpParams);
	}

	getPurchaseOrderTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetPurchaseOrderTypes', httpParams);
	}

	getQuoteRequestAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetQuoteRequestAttachmentTypes', httpParams);
	}

	getQuoteRequestNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetQuoteRequestNoteCategories', httpParams);
	}

	getQuoteRequestRecipientStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetQuoteRequestRecipientStatuses', httpParams);
	}

	getQuoteRequestStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetQuoteRequestStatuses', httpParams);
	}

	getRateGroupStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetRateGroupStatuses', httpParams);
	}

	getRecordTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetRecordTypes', httpParams);
	}

	getRecurrencePatterns(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetRecurrencePatterns', httpParams);
	}

	getReferenceTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetReferenceTypes', httpParams);
	}

	getRegions(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetRegions', httpParams);
	}

	getScopeAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetScopeAttachmentTypes', httpParams);
	}

	getScopeNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetScopeNoteCategories', httpParams);
	}

	getScopeStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetScopeStatuses', httpParams);
	}

	getSearchFilterTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetSearchFilterTypes', httpParams);
	}

	getServiceRequestAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetServiceRequestAttachmentTypes', httpParams);
	}

	getServiceRequestNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetServiceRequestNoteCategories', httpParams);
	}

	getServiceRequestStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetServiceRequestStatuses', httpParams);
	}

	getServiceRequestTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetServiceRequestTypes', httpParams);
	}

	getSubscriberDomainTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetSubscriberDomainTypes', httpParams);
	}

	getSubscriberStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetSubscriberStatuses', httpParams);
	}

	getSubscriptionAccessTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetSubscriptionAccessTypes', httpParams);
	}

	getSystemDomainTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetSystemDomainTypes', httpParams);
	}

	getSystemReports(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetSystemReports', httpParams);
	}

	getTaxCodes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetTaxCodes', httpParams);
	}

	getTimezones(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetTimezones', httpParams);
	}

	getTradeTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetTradeTypes', httpParams);
	}

	getUnitofMeasures(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetUnitofMeasures', httpParams);
	}

	getUserPermissions(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetUserPermissions', httpParams);
	}

	getUserProfileStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetUserProfileStatuses', httpParams);
	}

	getUserRelationTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetUserRelationTypes', httpParams);
	}

	getUserTeamStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetUserTeamStatuses', httpParams);
	}

	getVehicleAssetAxleCodes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetVehicleAssetAxleCodes', httpParams);
	}

	getVehicleAssetColours(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetVehicleAssetColours', httpParams);
	}

	getVehicleAssetFuelTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetVehicleAssetFuelTypes', httpParams);
	}

	getVehicleAssetMakes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetVehicleAssetMakes', httpParams);
	}

	getVehicleAssetShapes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetVehicleAssetShapes', httpParams);
	}

	getWeeklyRecurrenceOptions(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetWeeklyRecurrenceOptions', httpParams);
	}

	getWorkOrderAttachmentTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetWorkOrderAttachmentTypes', httpParams);
	}

	getWorkOrderNoteCategories(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetWorkOrderNoteCategories', httpParams);
	}

	getWorkOrderStatuses(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetWorkOrderStatuses', httpParams);
	}

	getWorkOrderTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetWorkOrderTypes', httpParams);
	}

	getWorkServiceTypes(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetWorkServiceTypes', httpParams);
	}
}
