<p-toolbar>
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="select-button-toolbar">
			<p-selectButton
				[options]="displayFilterOptions"
				(onChange)="changeFilterOption($event)"
				[(ngModel)]="selectedFilterOption"
				optionLabel="label"
				optionValue="value"
				data-testid="displayFilterOptionsSelectButton"></p-selectButton>
		</div>
	</ng-template>
</p-toolbar>
<app-related-flow
	#relatedFlowTable
	[referenceTypeCode]="referenceTypeCode"
	[referenceId]="referenceId"
	[includeCurrent]="includeCurrent"
	*ngIf="isSelectedSubTab('FLOW')">
</app-related-flow>
<app-related-schedules #relatedSchedulesTable [referenceTypeCode]="referenceTypeCode" [referenceId]="referenceId" *ngIf="isSelectedSubTab('SCHEDULES')">
</app-related-schedules>
<app-related-schedule-history
	#relatedScheduleHistoryTable
	[referenceTypeCode]="referenceTypeCode"
	[referenceId]="referenceId"
	*ngIf="isSelectedSubTab('SCHEDULE_HISTORY')"></app-related-schedule-history>
<app-related-work-orders #relatedWOTable [referenceTypeCode]="referenceTypeCode" [referenceId]="referenceId" *ngIf="isSelectedSubTab('WORK_ORDERS')">
</app-related-work-orders>
<app-related-purchase-orders #relatedPOTable [referenceTypeCode]="referenceTypeCode" [referenceId]="referenceId" *ngIf="isSelectedSubTab('PURCHASE_ORDERS')">
</app-related-purchase-orders>
<app-related-rategroup #relatedRateGroupTable [referenceTypeCode]="referenceTypeCode" [referenceId]="referenceId" *ngIf="isSelectedSubTab('RATE_GROUP')">
</app-related-rategroup>
<app-related-work-history
	#relatedWorkHistoryTable
	[assetNumber]="assetNumber"
	[referenceTypeCode]="referenceTypeCode"
	[referenceId]="referenceId"
	*ngIf="isSelectedSubTab('WORK_HISTORY')">
</app-related-work-history>
<app-related-access-denied *ngIf="isSelectedSubTab('NONE')"> </app-related-access-denied>
