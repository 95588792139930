import { COLUMNTYPES } from 'src/app/shared/constants/column.constants';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { Column } from 'src/app/shared/models/shared/column';

export class RateGroupConstants {
	// Detail
	static readonly Tab_detail_EditRateGroup: string = 'Edit Rate Group';
	static readonly Tab_detail_SaveRateGroup: string = 'Save Rate Group';

	//Items
	static readonly Tab_Items_AddItem: string = 'Add Item';
	static readonly Tab_Items_EditItem: string = 'Edit Item';
	static readonly Tab_Items_DeleteItem: string = 'Delete Item';

	//Bulk Action Names
	static readonly ActionName_Activate: string = 'Activate';
	static readonly ActionName_Disable: string = 'Disable';
	static readonly ActionName_Retire: string = 'Retire';

	//Icon Class Names
	static readonly Confirmation_Title_Icon_Activate: string = 'pi pi-check';
	static readonly Confirmation_Title_Icon_Disable: string = 'pi pi-ban';
	static readonly Confirmation_Title_Icon_Retire: string = 'pi pi-ban';

	//BulkAction ActionPermissionNames
	static readonly ActionPermissionNames = [
		{ action: RateGroupConstants.ActionName_Activate, permission: 'AllowEnable' },
		{ action: RateGroupConstants.ActionName_Disable, permission: 'AllowRemove' },
		{ action: RateGroupConstants.ActionName_Retire, permission: 'AllowRetire' },
	];

	//FilterActions
	static readonly FilterActionsList = [RateGroupConstants.ActionName_Activate, RateGroupConstants.ActionName_Disable, RateGroupConstants.ActionName_Retire];

	//BulkAction ActionIcons
	static readonly ActionIconsNames = [
		{ action: RateGroupConstants.ActionName_Activate, icon: RateGroupConstants.Confirmation_Title_Icon_Activate },
		{ action: RateGroupConstants.ActionName_Disable, icon: RateGroupConstants.Confirmation_Title_Icon_Disable },
		{ action: RateGroupConstants.ActionName_Retire, icon: RateGroupConstants.Confirmation_Title_Icon_Retire },
	];

	static readonly BulkAction_Object_Name = 'RateGroup';
	static readonly BulkAction_Object_PropertyName = 'Name';

	//Error Dialog Params
	static readonly Message_Title_Icon_Error: string = 'pi pi-times-circle';
	static readonly MessageDialog_Header_Error: string = 'ERROR';
	static readonly MessageDialog_Title_Error: string = 'An error has occured, please try again';

	//Confirmation Messages
	static readonly Confirmation_Header_Generic = (actionName: string) => `Confirm ${actionName}`;
	static readonly BulkAction_Confirmation_Message_Generic = (recordsCount: string, actionName: string) =>
		`Do you want to ${actionName.toLowerCase()} ${recordsCount} record(s)?`;
	static readonly BulkAction_Confirmation_Message_ChangeParent = (recordsCount: string) =>
		`Do you want to change the parent property/asset of the selected ${recordsCount} record(s)?`;
	static readonly BulkAction_Confirmation_Message_Print = (recordsCount: string) =>
		`Do you want to print the QR Codes of the selected ${recordsCount} record(s)?`;

	//Export List
	static readonly Export_List = [
		{ NameValue: 'Item', CodeValue: Constants.ReferenceType.Sor },
		{ NameValue: 'Item Cost Price', CodeValue: Constants.ReferenceType.SorCostPrice },
		{ NameValue: 'Request', CodeValue: Constants.ReferenceType.Problem },
	];

	//Record Type
	static readonly ListType_RateGroup: string = 'RateGroup';

	//Rate Group List Columns
	static readonly COLUMNS_RATEGROUPS: Column[] = [
		{ order: 1, header: 'Name', field: 'Name', minWidth: 120 },
		{ order: 2, header: 'Status', field: 'StatusName', minWidth: 120, width: 120, maxWidth: 120 },
		{ order: 3, header: 'Description', field: 'Description', minWidth: 100 },
		{
			order: 4,
			header: 'Is Default?',
			field: 'IsDefault',
			type: COLUMNTYPES.BOOLEAN,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.BOOLEAN,
			minWidth: 140,
			width: 140,
			maxWidth: 140,
		},
		{
			order: 5,
			header: 'List Position',
			field: 'OrderIndex',
			type: COLUMNTYPES.NUMERIC,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.NUMERIC,
			minWidth: 140,
			width: 140,
			maxWidth: 140,
		},
	];
}
