import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Listbox } from 'primeng/listbox';
import { AppContextService } from 'src/app/core/services';
import { COLUMNTYPES, ColumnConstants } from '../../constants/column.constants';
import { Column } from '../../models/shared/column';
import { DynamicColumnService } from '../../services/dynamic-column.service';

@Component({
	selector: 'app-dynamic-column-list',
	templateUrl: './dynamic-column-list.component.html',
	styleUrls: ['./dynamic-column-list.component.scss'],
})
export class DynamicColumnListComponent implements OnInit {
	@ViewChild('ms') ms: Listbox;
	@Input() dataTestIdComponentName = '';
	@Input() defaultColumns: Column[] = [];
	@Input() listType: string;
	selectedColumns: Column[] = [];
	previousSelectedColumns: Column[] = [];
	isSideBarVisible = false;
	columns: Column[];

	columnTypesEnum = COLUMNTYPES;

	appContext: AppContextService;

	constructor(appContext: AppContextService, private columnService: DynamicColumnService) {
		this.appContext = appContext;
	}

	ngOnInit() {
		this.columnService.columnsSelectionStorageKey = this.listType.toLowerCase() + 'ColumnsSelection';
		this.columnService.columnsOrderStorageKey = this.listType.toLowerCase() + 'ColumnsOrder';
		//this.columnService.columnsResizeStorageKey = this.listType.toLowerCase() + 'ColumnsResize';
		this.columnService.listStorageKey = this.listType.toLowerCase() + 'List';
		this.initialSettings();
	}

	onSelectionChange(event: any): void {
		if (event === null || !this.isColumnRemovedWithActiveFilters(event)) {
			this.previousSelectedColumns = [...this.selectedColumns];
			this.columnService.saveSelectedColumnsToStorage(this.selectedColumns);
			this.columnService.setSelectedColumns(this.selectedColumns);
		}
	}

	isColumnRemovedWithActiveFilters(event: any) {
		const newSelectedColumns: Column[] = event.value;
		const removedColumns = this.previousSelectedColumns.filter(col => !newSelectedColumns.some(x => x.field == col.field));
		const columnsWithActiveFilters = this.columnService.getColumnWithActiveFilters();

		if (removedColumns && removedColumns.length > 0 && columnsWithActiveFilters && columnsWithActiveFilters.length > 0) {
			const removedColumnsWithActiveFilters = columnsWithActiveFilters.filter(col => removedColumns.some(x => x.field == col.field));

			if (removedColumnsWithActiveFilters && removedColumnsWithActiveFilters.length > 0) {
				this.appContext.util.clearMessages();
				this.appContext.util.showError(ColumnConstants.DynamicColumm_ActiveFilter_Error);
				this.selectedColumns = [...this.previousSelectedColumns];
				return true;
			}
		}

		return false;
	}

	onResetClick() {
		this.columnService.resetLocalStorage();
		this.columns = [...this.defaultColumns];
		const visibleDefaultColumns = this.defaultColumns.filter(col => col.hideByDefault !== true);
		this.selectedColumns = [...visibleDefaultColumns];
		this.previousSelectedColumns = [...this.selectedColumns];
		this.onSelectionChange(null);
	}

	initialSettings() {
		this.columns = [...this.defaultColumns];
		this.selectedColumns = this.columnService.loadSelectedColumnsFromStorage(this.defaultColumns);
		this.previousSelectedColumns = [...this.selectedColumns];
		this.columnService.setSelectedColumns(this.selectedColumns);
		this.columnService.columns = [...this.columns];
	}

	getColumnsSortedByHeader() {
		if (this.columns) return this.columns.sort((a, b) => a.header.localeCompare(b.header));
	}
}
